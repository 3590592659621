/* eslint-disable */ // TN disabling for hotfix.
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { Button } from '@missionlabs/smartagent-app-components'

import useContact from 'hooks/redux/useContact'
import useMonitoring from 'hooks/redux/useMonitoring'
import useContactLogForm from 'hooks/useContactLogForm'

import RootState from 'store/state'
import { setShowNextStatus } from 'store/user/user.actions'

import { getRequiredFields, isAllRequiredFieldsFilled, isContactLogEmpty } from 'utils'

import './contact-log.scss'

interface Props extends ReturnType<typeof mapStateToProps> {
    setShowNextStatus: typeof setShowNextStatus
    onComplete?: () => void
}

const CompleteWrapUp: React.FC<Props> = (props) => {
    let contact = useContact()
    const contactLog = useContactLogForm()
    const { contacts } = props
    const { isMonitoringOrBarging } = useMonitoring()
    const [acwComplete, setAcwComplete] = useState<boolean>(false)

    if (!contact?.ID && contacts.length) {
        contact = contacts.filter((contact) => contact.channel === 'VOICE')[0]
    }

    const acwAttributes = contact?.acwAttributes

    useEffect(() => {
        const noContactLogOrEmpty = isContactLogEmpty(contactLog)
        // Do not force monitoring voice contacts to complete ACW
        if (noContactLogOrEmpty || isMonitoringOrBarging) {
            setAcwComplete(true)
            return
        }
        if (acwAttributes) {
            const requiredFields = getRequiredFields(contactLog, acwAttributes)
            isAllRequiredFieldsFilled(acwAttributes, requiredFields)
                ? setAcwComplete(true)
                : setAcwComplete(false)
        }
    }, [contact?.ID, JSON.stringify(acwAttributes)])

    const submitAcw = () => {
        props.onComplete?.()
    }

    return (
        <>
            <div role="alert" aria-live="assertive" className="only-visible-to-screen-readers">Task complete please enter your task notes and press finish wrap up to complete your task</div>
            <div className="acw-complete row between">
                <Button
                    type={'button'}
                    disabled={!acwComplete}
                    elastic
                    curved
                    styling="primary"
                    onClick={submitAcw}
                >
                    Finish wrap up
                </Button>
            </div>
        </>
    )
}

function mapStateToProps(state: RootState) {
    const { contacts } = state
    return {
        contacts,
    }
}

export default connect(mapStateToProps, { setShowNextStatus })(CompleteWrapUp)
