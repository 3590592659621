import { PhoneNumberFormat } from 'google-libphonenumber'
import { IQueue, SmartAgentUser } from 'store/settings/settings.state'
import { formatPhoneNumber, parseStringDate, timeFromDate, wordToUpperCamelCase } from 'utils'
import { IInteraction } from 'views/Metrics/HistoricMetrics/types'

import { SADropdownOption } from '@missionlabs/smartagent-app-components/dist/Dropdown'

import {
    chatChannels,
    messagingChannels,
    socialChannels,
    taskChannels,
    voiceChannels,
} from './contactSearch.constants'
import {
    ChannelColumnGroup,
    ChatContactType,
    ContactChannel,
    ContactInitiationMethod,
    EmailContactType,
    IContactSearchForm,
    SocialContactType,
} from './contactSearch.state'

const todayDate = new Date()
const yesterdayDate = new Date(todayDate)
yesterdayDate.setDate(yesterdayDate.getDate() - 1)

export const createDefaultContactSearchForm = (): IContactSearchForm => ({
    contactID: '',
    agent: undefined,
    queue: undefined,
    initiationMethod: undefined,
    externalID: '',
    phoneNumber: '',
    startDate: yesterdayDate.getTime(),
    startTime: timeFromDate(yesterdayDate),
    endDate: todayDate.getTime(),
    endTime: timeFromDate(todayDate),
    callDurationStartTime: '',
    callDurationEndTime: '',
    holdDurationStartTime: '',
    holdDurationEndTime: '',
    channel: {
        label: wordToUpperCamelCase(ContactChannel.ALL),
        data: ContactChannel.ALL,
        group: ChannelColumnGroup.ALL,
    },
    socialHandle: '',
    uniqueSocialId: '',
    chatEmail: '',
    chatOrderNumber: '',
    email: '',
    emailSearchType: { label: '', data: '' },
    bargedOnly: false,
})

export const getContactSearchQueryString = (
    form: IContactSearchForm,
    hasRestrictQueueAccessFeature: boolean,
) => {
    return new URLSearchParams(
        Object.fromEntries(
            Object.entries({
                phonenumberE164: formatPhoneNumber(form.phoneNumber, PhoneNumberFormat.E164),
                fromDate: form.startDate
                    ? parseStringDate(form.startDate, form.startTime)
                    : undefined,
                toDate: form.endDate
                    ? parseStringDate(form.endDate, form.endTime, true)
                    : undefined,
                initiationMethod: form.initiationMethod?.data,
                channel: form.channel?.data,
                agentID: form.agent?.ID,
                queueID: form.queue?.ID,
                contactID: form.contactID,
                externalID: form.externalID,
                socialHandle: form.socialHandle,
                uniqueSocialId: form.uniqueSocialId,
                chatEmail: form.chatEmail ? form.chatEmail : undefined,
                chatOrderNumber: form.chatOrderNumber,
                keyword: form.keyword ? form.keyword : undefined,
                taskEmail: form.email ? form.email : undefined,
                emailSearchType: form.emailSearchType.data,
                attributeSearchName: form.bargedOnly ? 'sa-barged' : undefined,
                attributeSearchValue: form.bargedOnly ? 'yes' : undefined,
                filterByAgentTags: hasRestrictQueueAccessFeature ? 'true' : undefined,
            }).filter(([, v]) => !!v),
        ) as Record<string, string>,
    ).toString()
}

const blankOption = { data: undefined, label: '' }

export const optionsFactory: {
    agent: (agents: SmartAgentUser[]) => SADropdownOption<SmartAgentUser | undefined>[]
    queue: (queues: IQueue[]) => SADropdownOption<IQueue | undefined>[]
    initiation: () => SADropdownOption<string | undefined>[]
    channel: () => SADropdownOption<string | undefined>[]
    socialSearchType: () => SADropdownOption<string | undefined>[]
    chatSearchType: () => SADropdownOption<string | undefined>[]
    emailSearchType: () => SADropdownOption<string>[]
} = {
    agent: (agents) => [
        blankOption,
        ...agents
            .filter((agent) => !!agent.firstName)
            .map((agent) => ({
                label: `${agent.firstName} ${agent.lastName}`,
                data: agent,
            }))
            .sort((a1, a2) => (a1.label.toLowerCase() > a2.label.toLowerCase() ? 1 : -1)),
    ],
    queue: (queues) => [
        blankOption,
        ...queues
            .map((q) => ({ label: q.Name, data: q }))
            .sort((q1, q2) => (q1.label.toLowerCase() > q2.label.toLowerCase() ? 1 : -1)),
    ],
    initiation: () => [
        blankOption,
        ...Object.keys(ContactInitiationMethod).map((key) => ({
            label: key,
            data: key,
        })),
    ],
    channel: () => [
        ...Object.keys(ContactChannel).map((key) => {
            const formattedKey = wordToUpperCamelCase(key)
            return {
                label: formattedKey,
                data: key,
            }
        }),
    ],
    socialSearchType: () => [
        blankOption,
        ...Object.keys(SocialContactType).map((key) => ({
            label: key,
            data: key,
        })),
    ],
    chatSearchType: () => [
        blankOption,
        ...Object.keys(ChatContactType).map((key) => ({
            label: key,
            data: key,
        })),
    ],
    emailSearchType: () => [
        ...Object.keys(EmailContactType).map((key) => ({
            label: key,
            data: EmailContactType[key as keyof typeof EmailContactType],
        })),
    ],
}

const calculateCallDuration = (interaction: IInteraction): number =>
    interaction.disconnectTimestamp && interaction.connectedToAgentTimestamp
        ? (interaction.disconnectTimestamp - interaction.connectedToAgentTimestamp) / 1000
        : interaction.agentInteractionDuration ?? 0

const filterByDuration = (
    interactions: IInteraction[],
    durationStart: number | undefined,
    durationEnd: number | undefined,
    calculateDuration: (interaction: IInteraction) => number,
): IInteraction[] => {
    return interactions.filter((interaction) => {
        const duration = calculateDuration(interaction)
        return (
            (durationStart === undefined || duration >= durationStart) &&
            (durationEnd === undefined || duration <= durationEnd)
        )
    })
}

export const filterInteractionsByCallAndHoldDuration = (
    interactions: IInteraction[],
    {
        callDurationStartTime,
        callDurationEndTime,
        holdDurationStartTime,
        holdDurationEndTime,
    }: IContactSearchForm,
): IInteraction[] => {
    const callDurationStartTimeParsed = parseTimeToSeconds(callDurationStartTime)
    const callDurationEndTimeParsed = parseTimeToSeconds(callDurationEndTime)
    const holdDurationStartTimeParsed = parseTimeToSeconds(holdDurationStartTime)
    const holdDurationEndTimeParsed = parseTimeToSeconds(holdDurationEndTime)

    let filteredInteractions = filterByDuration(
        interactions,
        callDurationStartTimeParsed,
        callDurationEndTimeParsed,
        calculateCallDuration,
    )

    if (holdDurationStartTimeParsed !== undefined && !isNaN(holdDurationStartTimeParsed)) {
        filteredInteractions = filteredInteractions.filter(
            (interaction) => (interaction.holdDuration ?? 0) >= holdDurationStartTimeParsed,
        )
    }

    if (holdDurationEndTimeParsed !== undefined && !isNaN(holdDurationEndTimeParsed)) {
        filteredInteractions = filteredInteractions.filter(
            (interaction) => (interaction.holdDuration ?? 0) <= holdDurationEndTimeParsed,
        )
    }

    return filteredInteractions
}

const parseTimeToSeconds = (time: string) => {
    // Converts time in hh:mm:ss or hh:mm format to seconds
    if (!time) return undefined

    const timeSplit = time.split(':')
    if (timeSplit.length === 3)
        return (
            parseInt(timeSplit[0], 10) * 60 * 60 +
            parseInt(timeSplit[1], 10) * 60 +
            parseInt(timeSplit[2], 10)
        )

    if (timeSplit.length === 2)
        return parseInt(timeSplit[0], 10) * 60 * 60 + parseInt(timeSplit[1], 10) * 60

    return undefined
}

export const getChannelGroup = (channel: string): ChannelColumnGroup => {
    if (socialChannels.includes(channel)) return ChannelColumnGroup.SOCIAL
    if (voiceChannels.includes(channel)) return ChannelColumnGroup.VOICE
    if (chatChannels.includes(channel)) return ChannelColumnGroup.CHAT
    if (taskChannels.includes(channel)) return ChannelColumnGroup.TASK
    if (messagingChannels.includes(channel)) return ChannelColumnGroup.MESSAGING

    return ChannelColumnGroup.ALL
}
