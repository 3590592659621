import { differenceInSeconds } from 'date-fns'
import useAppConfig from 'hooks/redux/useAppConfig'
import useChatConnections from 'hooks/redux/useChatConnections'
import useContactLogForm from 'hooks/useContactLogForm'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { completeChatACW } from 'store/chat/chat.actions'
import useSettings from './useSettings'

export default function useACWTimeLeft() {
    const contactLog = useContactLogForm()
    const { settings } = useSettings()
    const { chatAfterCallWorkInitialTimeoutSeconds } = useAppConfig()
    const acwInitialTimeLeft =
        settings?.appConfigurations?.chat?.afterCallWorkTimeoutSeconds ??
        contactLog?.form?.wrapTimeSeconds ??
        chatAfterCallWorkInitialTimeoutSeconds ??
        60
    const [acwTimeLeft, setAcwTimeLeft] = useState<number | null>(null)
    const [selectedChatID, setSelectedChatID] = useState<null | string>(null)
    const dispatch = useDispatch()
    const chat = useChatConnections('selected')

    const updateAcwTimeLeft = () => {
        if (chat?.acwStartedAt && !chat?.acwExtendedAt) {
            //  calculate the seconds remaining for the after call work
            const timeLeftInSeconds =
                acwInitialTimeLeft - differenceInSeconds(Date.now(), chat.acwStartedAt)
            // if theres acw time remaining update the time left or end the chat if the timer has reached 0
            timeLeftInSeconds >= 0
                ? setAcwTimeLeft(timeLeftInSeconds)
                : dispatch(completeChatACW(chat!.id))
        }
    }

    useEffect(() => {
        //  if the selected chat has changed clear the acw time left and change the selectedChat
        if (chat?.id && chat?.id !== selectedChatID) {
            if (acwTimeLeft) setAcwTimeLeft(null)
            setSelectedChatID(chat.id)
        }
    }, [chat])

    useEffect(() => {
        //   recalculate the timer if the new selected chat is in after call work
        if (chat?.acw === true && !chat.acwExtendedAt) {
            updateAcwTimeLeft()
        } else {
            if (acwTimeLeft) setAcwTimeLeft(null)
        }
    }, [selectedChatID])

    useEffect(() => {
        if (chat?.id && chat?.status === 'ended' && chat.id === selectedChatID) {
            updateAcwTimeLeft()
        }
    }, [chat?.acwStartedAt])

    return acwTimeLeft
}
