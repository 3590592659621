import classNames from 'classnames'
import { Loader } from 'components'
import { useMemo, useState } from 'react'
import ItemsList from './ItemsList'
import SearchInput from './SearchInput'
import styles from './SearchList.module.scss'

export interface Option {
    label: string
    value: unknown
}

interface Props {
    itemsList: Option[]
    onItemSelected: (data: Option) => void
    selectedItem?: Option
    isLoading: boolean
    className?: string
    headerName: string
}

const SearchList = ({
    isLoading,
    itemsList,
    onItemSelected,
    selectedItem,
    className,
    headerName,
}: Props) => {
    const [searchValue, setSearchValue] = useState('')

    const itemsToRender = useMemo(() => {
        if (itemsList.length > 0) {
            if (searchValue) {
                const re = new RegExp(searchValue, 'i')

                return itemsList.reduce((acc, item) => {
                    if (re.test(item.label)) {
                        return [...acc, item]
                    }

                    return acc
                }, [] as Option[])
            }

            return itemsList
        }

        return []
    }, [itemsList, searchValue])

    const handleOnItemClick = (data: Option) => {
        onItemSelected(data)
    }

    const handleOnSearchChange = (data: string) => {
        setSearchValue(data)
    }

    return (
        <div className={classNames(styles.wrapper, className)}>
            <header className={styles.header}>
                <p>{headerName}</p>
            </header>

            <SearchInput onSearchChange={handleOnSearchChange} searchInputValue={searchValue} />

            {isLoading ? (
                <Loader className={styles.loader} />
            ) : (
                <ItemsList
                    items={itemsToRender}
                    onItemClick={handleOnItemClick}
                    selectedItem={selectedItem}
                />
            )}
        </div>
    )
}

export default SearchList
