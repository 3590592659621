import { useSelector } from 'react-redux'
import { IChat } from 'store/chat/chat.state'
import RootState from 'store/state'

function useChatConnections(status: 'incoming' | 'selected'): IChat | undefined
function useChatConnections(): IChat[]
function useChatConnections(status?: 'incoming' | 'selected') {
    const chats = useSelector<RootState, IChat | IChat[] | undefined>(({ chat, contact }) => {
        if (status === 'incoming')
            return chat?.connections.find((c) => c.status === connect.ContactStateType.CONNECTING)
        if (status === 'selected')
            return contact?.ID ? chat?.connections.find((c) => c.id === contact.ID) : undefined

        return chat.connections
    })

    return chats
}

export default useChatConnections
