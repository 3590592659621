import type { ThunkDispatch } from '@reduxjs/toolkit'
import { getSingleUser } from 'services/api/api.agent'
import { getLiveContact } from 'services/api/api.contact'
import { buildControlsMapping, createConnection, updateConnection } from 'store/callV2/callV2.utils'

import * as CallV2Actions from './callV2.reducer'

import type { CallConnection, Handlers } from 'store/callV2/callV2.state'
import type RootState from 'store/state'

export const {
    conferenceConnections,
    leaveCall,
    resetCallState,
    setMonitorStatus,
    toggleConnections,
    updateMonitorStatus,
} = CallV2Actions

const generateHandlers = (
    dispatch: ThunkDispatch<RootState, undefined, CallV2Actions.CallV2Action>,
): Handlers => ({
    onTransfer: (endpoint: connect.Endpoint) =>
        dispatch(
            CallV2Actions.addConnection({
                // Pass number as well as endpoint to keep in line with the original add connection logic.
                // This will be changed in the future once we bin the original call logic!
                number: endpoint.phoneNumber,
                endpoint,
            }),
        ),
    onMute: (id: string) => dispatch(CallV2Actions.muteConnection(id)),
    onHold: (id: string) => dispatch(CallV2Actions.holdConnection(id)),
    onEnd: (id: string) => dispatch(CallV2Actions.endConnection(id)),
    onKeypadButton: (id: string, digit: string) =>
        dispatch(CallV2Actions.sendDTMF({ connectionId: id, digit })),
})

export function updateConnections(connections: connect.VoiceConnection[]) {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, CallV2Actions.CallV2Action>,
        getState: () => RootState,
    ) => {
        const { callV2 } = getState()
        const handlers = generateHandlers(dispatch)
        const existingConnections = callV2.connections
        const newControlsMapping = buildControlsMapping(connections, handlers)

        try {
            const callConnections = connections.map<CallConnection>((connection, index) => {
                const existingConnection = existingConnections.find(
                    ({ id }) => id === connection.connectionId,
                )

                const newConnection = createConnection(connection, newControlsMapping[index])

                return existingConnection
                    ? updateConnection(existingConnection, newConnection)
                    : newConnection
            })

            dispatch(CallV2Actions.setConnections(callConnections))
        } catch (error) {
            console.error('Error when attempting to update the connections: ', error)
        }
    }
}

export const initialiseConnections = (contact: connect.Contact) => {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, CallV2Actions.CallV2Action>,
        getState: () => RootState,
    ) => {
        const { app, auth } = getState()

        const companyID = app.ID
        const instanceID = app.instance?.ID
        const token = auth.token

        try {
            if (!instanceID) throw new Error('Instance ID not found')
            if (!token) throw new Error('Token not found')

            const originalContactID = contact.getOriginalContactId()
            const currentContactID = contact.getContactId();

            const originalContact = await getLiveContact(originalContactID)
            const currentContact = await getLiveContact(currentContactID)
            const originalAgent = await getSingleUser(
                companyID,
                instanceID,
                token,
                originalContact.AgentInfo?.Id ?? currentContact.AgentInfo?.Id,
            )

            const handlers = generateHandlers(dispatch)

            dispatch(
                CallV2Actions.initialiseConnections({
                    contact,
                    originalContact,
                    originalAgent,
                    handlers,
                }),
            )
        } catch (error) {
            console.error('Error when attempting to initialise the connections: ', error)
        }
    }
}
