import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import AnnouncementsState, { Announcement, UserAnnouncement } from './announcement.state'

const initialState: AnnouncementsState = {
    announcementsOpen: false,
    userAnnouncements: undefined,
    unreadUserAnnouncements: undefined,
}

export const announcementSlice = createSlice({
    name: 'announcement',
    initialState,
    reducers: {
        getUserAnnouncements(state, action: PayloadAction<UserAnnouncement[]>) {
            return {
                ...state,
                userAnnouncements: action.payload,
            }
        },
        getUnreadUserAnnouncements(state, action: PayloadAction<UserAnnouncement[]>) {
            return {
                ...state,
                unreadUserAnnouncements: action.payload,
            }
        },
        getAnnouncements(state, action: PayloadAction<Announcement[]>) {
            return {
                ...state,
                announcements: action.payload,
                announcementsLoading: false,
            }
        },
        getAnnouncementsError(state) {
            return {
                ...state,
                announcementsLoading: false,
                announcementsError: true,
            }
        },
        getAnnouncementsLoading(state) {
            return {
                ...state,
                announcementsLoading: true,
            }
        },
        updateAnnouncementLoading(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                updateAnnouncementsLoading: action.payload,
            }
        },
        updateAnnouncement(state, action: PayloadAction<Announcement>) {
            return {
                ...state,
                announcements: state.announcements?.map((ann) =>
                    ann.ID === action.payload.ID
                        ? action.payload
                        : ann,
                ),
            }
        },
        updateUserAnnouncement(state, action: PayloadAction<UserAnnouncement>) {
            return {
                ...state,
                userAnnouncements: state.userAnnouncements?.map((ua) =>
                    ua.ID === action.payload.ID
                        ? {
                              ...ua,
                              ...action.payload,
                          }
                        : ua,
                ),
                unreadUserAnnouncements: state.unreadUserAnnouncements?.filter((ua) =>
                    ua.ID === action.payload.ID ? !action.payload.read : true,
                ),
            }
        },
        updateUserAnnouncementLoading(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                updateUserAnnouncementsLoading: action.payload,
            }
        },
        deleteAnnouncement(state, action: PayloadAction<string>) {
            return {
                ...state,
                announcements: state.announcements?.filter((ann) => ann.ID !== action.payload),
            }
        },
    },
})

export const {
    getUserAnnouncements,
    getUnreadUserAnnouncements,
    getAnnouncements,
    getAnnouncementsError,
    getAnnouncementsLoading,
    updateAnnouncementLoading,
    updateAnnouncement,
    updateUserAnnouncement,
    updateUserAnnouncementLoading,
    deleteAnnouncement,
} = announcementSlice.actions

export type AnnouncementAction = ReturnType<
    (typeof announcementSlice.actions)[keyof typeof announcementSlice.actions]
>

export default announcementSlice.reducer
