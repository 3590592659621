import { LoadingStates } from 'store/state'

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import AppState, { FormState, InstanceSettings, SelectInstanceAction } from './app.state'

const defaultState: AppState = {
    error: false,
    message: '',
    logoURL: '',
    name: '',
    plugins: [],
    loading: true,
    ID: '',
    appConfig: {
        afterCallWorkInitialTimeoutSeconds: 20,
        chatAfterCallWorkInitialTimeoutSeconds: 60,
        defaultNumberDPAPassesRequiredForPass: 2,
    },
    callbacksEnabled: false,
    instances: [],
    instance: undefined,
    identityManagement: undefined,
    forms: {},
    keywordsError: false,
    keywordsSuccess: false,
    keywordsLoading: false,
    features: [],
    callProviderInitStartTime: undefined
}

export const appSlice = createSlice({
    name: 'app',
    initialState: defaultState,
    reducers: {
        initAppLoading(state) {
            state.loading = true
        },
        initAppSuccess(state, action: PayloadAction<AppState>) {
            return { ...action.payload, forms: {}, error: false, loading: false }
        },
        appError(state, action: PayloadAction<string | undefined>) {
            state.message = action.payload || ''
            state.error = true
        },
        setCompanyGroups(state, action: PayloadAction<any[]>) {
            state.companyGroups = action.payload
        },
        selectInstance(state, action: PayloadAction<SelectInstanceAction>) {
            state.appConfig = action.payload.appConfig
            state.instance = action.payload.instance
        },
        clearInstance(state) {
            state.instance = undefined
        },
        pluginInstalled(state, action: PayloadAction<{ name: string; iframe: string }>) {
            state.plugins = state.plugins.map((p) => {
                if (p.name === action.payload.name) {
                    p.iframe = action.payload.iframe
                }
                return p
            })
        },
        showPlugin(state, action: PayloadAction<string>) {
            state.plugins = state.plugins.map((p) => {
                if (p.name === action.payload) {
                    p.hidden = false
                }
                return p
            })
        },
        hidePlugin(state, action: PayloadAction<string>) {
            state.plugins = state.plugins.map((p) => {
                if (p.name === action.payload) {
                    p.hidden = true
                }
                return p
            })
        },
        resetPlugin(state, action: PayloadAction<string>) {
            state.plugins = state.plugins.map((p) => {
                if (p.name === action.payload) {
                    delete p.hidden
                    delete p.titleTag
                }
                return p
            })
        },
        updateTitleTag(state, action: PayloadAction<{ name: string; tag: string }>) {
            state.plugins = state.plugins.map((p) => {
                if (p.name === action.payload.name) {
                    p.titleTag = action.payload.tag
                }
                return p
            })
        },
        reportIssue(_state, _action: PayloadAction<string>) {},
        toggleIframe() {},
        ccpLogout() {},
        updateKeywordsError(state, action: PayloadAction<boolean>) {
            state.keywordsError = action.payload
        },
        updateKeywordsLoading(state, action: PayloadAction<boolean>) {
            state.keywordsError = action.payload
        },
        updateKeywordsSuccess(state, action: PayloadAction<boolean>) {
            state.keywordsSuccess = action.payload
        },
        loadingForm(state, action: PayloadAction<string>) {
            state.forms[action.payload] = {
                state: LoadingStates.LOADING,
            }
        },
        formNotFound(state, action: PayloadAction<string>) {
            state.forms[action.payload] = {
                state: LoadingStates.NOT_FOUND,
            }
        },
        formLoaded(state, action: PayloadAction<{ name: string; form: FormState['form'] }>) {
            state.forms[action.payload.name] = {
                state: LoadingStates.LOADED,
                form: action.payload.form,
            }
        },
        formError(state, action: PayloadAction<string>) {
            delete state.forms[action.payload]
        },
        updateInstanceSettings(state, action: PayloadAction<InstanceSettings>) {
            if (!state.instance) return
            state.instance.settings = action.payload
        },
        setCallProviderInitStartTime(state, action: PayloadAction<number>) {
            state.callProviderInitStartTime = action.payload
        }
    },
})

export const {
    appError,
    ccpLogout,
    clearInstance,
    formError,
    formLoaded,
    formNotFound,
    hidePlugin,
    initAppLoading,
    initAppSuccess,
    loadingForm,
    pluginInstalled,
    reportIssue,
    resetPlugin,
    selectInstance,
    setCompanyGroups,
    showPlugin,
    toggleIframe,
    updateInstanceSettings,
    updateKeywordsError,
    updateKeywordsLoading,
    updateKeywordsSuccess,
    updateTitleTag,
    setCallProviderInitStartTime
} = appSlice.actions

export type AppAction = ReturnType<(typeof appSlice.actions)[keyof typeof appSlice.actions]>

export default appSlice.reducer
