import type { Middleware, MiddlewareAPI } from '@reduxjs/toolkit'
import PhoneLib from 'google-libphonenumber'
import { updateContactAttributes as APIUpdateContactAttributes } from 'services/api/api.contact'
import * as AppReducer from 'store/app/app.reducer'
import * as callActions from 'store/call/call.actions'
import * as CallReducer from 'store/call/call.reducer'
import { addToCallLog } from 'store/callLog/callLog.actions'
import { clearContact } from 'store/contact/contact.actions'
import { updateContactAttributes } from 'store/contact/contact.reducer'
import ContactState from 'store/contact/contact.state'
import { addError, addSoftphoneError, setRedirect } from 'store/global/global.actions'
import RootState from 'store/state'
import * as userActions from 'store/user/user.actions'
import * as UserReducer from 'store/user/user.reducer'
import UserState from 'store/user/user.state'
import { formatPhoneNumber, isAnyAction } from 'utils'

let app: HTMLElement | null

//@ts-ignore
const log = (...args) => {
    // console.log(...args); //uncomment for debug output.
}

const kumodiMW: Middleware<{}, RootState> = (store) => (next) => (action) => {
    if (!isAnyAction(action)) return

    if (action.type === AppReducer.initAppSuccess.type) {
        log('kumodi-middleware: saw app init success')
        app = document.getElementById('app')
    } else {
        if (store.getState().app.callProvider !== 'kumodi') return next(action)
    }

    switch (action.type) {
        case AppReducer.selectInstance.type:
            log('kumodi-middleware: selected instance')
            try {
                //eslint-disable-next-line
                let _ = kumodi
            } catch (e) {
                console.warn(
                    `No global kumodi object on window, did you forget to add the script tag?`,
                )
                throw new Error(
                    "Could not find 'kumodi' as a global. A script tag is missing or has an error. Someone at missionlabs needs to fix this issue.",
                )
            }
            if (app && kumodi) {
                kumodi
                    .init(app, action.payload.instance.ccpURL)
                    .catch((e) => {
                        console.warn('There was an error initialising the kumodi sdk', e)
                        throw e
                    })
                    .then(() => {
                        listen(store)
                        return next(action)
                    })
            } else {
                log("kumodi-middleware: app div wasn't set yet, not inititialising yet")
                return next(action)
            }
            return
        case UserReducer.login.type: {
            return next(action)
        }
        case UserReducer.authExternalUserRequest.type:
            log(
                'kumodi-middleware: - got external credentials to log in to kumodi:',
                action.payload,
            )
            kumodi.requestAuthoriseUser(action.payload.credentials)
            return next(action)
        case AppReducer.toggleIframe.type:
            kumodi.requestToggleIframe()
            //intentionally no next(action), we consume this event.
            return
        case CallReducer.acceptCall.type:
            log('accepting call')
            kumodi.requestContactAccept()
            return next(action)
        case CallReducer.rejectCall.type:
            kumodi.requestContactReject()
            return next(action)
        case CallReducer.makeCall.type: {
            const { number } = action.payload
            const numberToDial = formatPhoneNumber(number, PhoneLib.PhoneNumberFormat.E164)
            kumodi.requestContactMake(numberToDial).catch((e) => {
                store.dispatch(
                    addError('Oops, there was an error while trying to connect the call'),
                )
            })
            return next(action)
        }
        case CallReducer.addConnection.type: {
            store.dispatch(addError('Bridging calls is not supported for this type of call'))
            return next(action)
        }
        case CallReducer.transferCall.type:
            store.dispatch(addError('Transferring calls is not supported for this type of call.'))
            return next(action)
        case CallReducer.hold.type: {
            kumodi.requestContactHold()
            return next(action)
        }
        case CallReducer.resume.type: {
            kumodi.requestContactUnhold()
            return next(action)
        }
        case CallReducer.endCall.type: {
            kumodi.requestContactEnd()
            return store.dispatch(callActions.callEnded()) //no next action, this is how ccp middleware does it.
        }
        case CallReducer.callEnded.type: {
            return next(action)
        }
        case CallReducer.sendDTMF.type: {
            store.dispatch(addError('Sending DTMF is not supported for this type of call.'))
            return next(action)
        }
        case UserReducer.userSetState.type:
            kumodi.requestAgentSetStatus(action.payload.state.name as any) //type is KStatus, basically just a string so we wave through the status name.
            return next(action)
        case UserReducer.userAvailable.type:
            //TODO set our status to avail?
            return next(action)
        case UserReducer.userNotAvailable.type:
            //TODO set our status to not avail?
            return next(action)
        case UserReducer.getQuickConnects.type:
            kumodi.requestGetPhoneBook().then((phoneBook: kumodi.KContact[]) => {
                log('kumodi-middleware: - saw phoneBook (quickconnects) loaded')
                const oneContactPerContactNumber: connect.Endpoint[] = phoneBook.flatMap(
                    (contact) => {
                        return contact.phoneNumbers.map((phoneNumber) => {
                            return {
                                endpointARN: contact.ID + '/' + phoneNumber.numberE164, //should be "arn... /transfer-destination/{ID}"
                                endpointId: contact.ID + '/' + phoneNumber.numberE164, //should be "arn... /transfer-destination/{ID}"
                                type: connect.EndpointType.PHONE_NUMBER,
                                name: `${contact.fullName} ${phoneNumber.label ? '(' + phoneNumber.label + ')' : ''}`,
                                phoneNumber: '+' + phoneNumber.numberE164,
                                agentLogin: '',
                                queue: '',
                            }
                        })
                    },
                )
                action.payload = oneContactPerContactNumber
                return next(action)
            })
            return
        case CallReducer.leaveCall.type:
            // maybe just end call here?
            store.dispatch(addError('Leaving calls is currently unsupported'))
            return next(action)
        case CallReducer.endConnection.type: {
            kumodi.requestContactReject()
            return next(action)
        }
        case CallReducer.toggleConnections.type:
            store.dispatch(addError('Switching between calls is currently unsupported.'))
            return next(action)
        case UserReducer.refresh.type:
            log('Received a user refresh in kumodi middleware, but doing nothing.')
            return next(action)
        case CallReducer.conferenceConnections.type:
            store.dispatch(addError('Conferencing calls is currently unsupported.'))
            return next(action)
        case UserReducer.logout.type:
            kumodi.requestAgentLogout()
            return next(action)
        case CallReducer.mute.type:
            kumodi.requestContactMute()
            return next(action)
        case CallReducer.unmute.type:
            kumodi.requestContactUnmute()
            return next(action)
        case updateContactAttributes.type: {
            next(action)
            const { contact, app, auth } = store.getState()
            if (!contact || !contact.ID || !action.payload) return

            //Below code identical to the ccp middleware code - can we refactor
            const contactAttributes = ['sa-externalID', 'sa-agent-hungup', 'sa-dialled-number']
            const updateFields = [
                'sa-acw-',
                ...contactAttributes,
                ...(app.appConfig.contactAttributes || []),
            ]
            const attributes: { [key: string]: string } = Object.keys(
                action.payload.attributes,
            ).reduce((attributes: { [key: string]: string }, key: string) => {
                //Check for matches - can either start sa- or not eg ['externalID'] matches sa-externalID and externalID
                if (
                    !!updateFields.find(
                        (field) => key.indexOf(field) === 0 || key.indexOf(field) === 3,
                    )
                ) {
                    attributes[key] = action.payload.attributes[key].toString()
                }
                return attributes
            }, {})
            if (!Object.keys(attributes).length) return
            log('Updating attributes:', attributes)
            return APIUpdateContactAttributes(
                app.ID,
                app.instance!.ID,
                contact.originalContactID || action.payload.ID,
                auth.token!,
                attributes,
            ).catch()
        }
        default:
            return next(action)
    }
}

const listen = (store: MiddlewareAPI<any, RootState>) => {
    log('kumodi-middleware: called listen')

    //CALLS

    const onContactReceived = (contact: kumodi.KCall) => {
        const state = store.getState()
        const contactState: ContactState = {
            customerEndpointAddress:
                contact.direction === kumodi.KCallDirection.OUTBOUND
                    ? contact.dialledNumberE164
                    : contact.originatingNumberE164,
            attributes: {},
            ID: contact.ID,
            created: new Date().getTime(),
            updated: new Date().getTime(),
            answered: false,
            initiationMethod:
                contact.direction === kumodi.KCallDirection.OUTBOUND ? 'OUTBOUND' : 'INBOUND',
            instanceID: state.app.instance!.ID,
            companyID: state.app.ID,
            initiationTimestamp: new Date().getTime(),
            systemEndpointAddress: 'unknown',
            channel: 'VOICE',
            subType: contact.subType,
        }
        if (contact.direction === kumodi.KCallDirection.INBOUND) {
            store.dispatch(
                callActions.incomingCall(contact.ID, contact.originatingNumberE164, contactState),
            )
        } else {
            store.dispatch(
                callActions.outboundCall(contact.ID, contact.dialledNumberE164, contactState),
            )
        }
    }

    const onContactAccepted = (contact: kumodi.KCall) => {
        log('kumodi-middleware: Dispatching action call connecting...')
        store.dispatch(callActions.connectionConnecting(contact.ID))
    }

    const onContactConnected = (contact: kumodi.KCall) => {
        log('kumodi-middleware: Dispatching action call connected...')
        store.dispatch(callActions.callStarted())
        store.dispatch(callActions.connectionStarted(contact.ID))
    }

    const onContactEnded = (contact: kumodi.KCall) => {
        //  eslint-disable-next-line
        if (true) {
            // callWasAnswered <-- TODO how do we determine if the call connected or not? for now all calls get added to history
            if (!contact.ID) {
                log(
                    'kumodi-middleware: A bug with outbound calls in kumodi means this call has no ID, we cannot add to contact log at the moment',
                )
            } else {
                const { app, call } = store.getState()
                const instanceID = app.instance!.ID
                const companyID = app.ID

                const currentCallInConnectFormat: ContactState = {
                    customerEndpointAddress:
                        contact.direction.toLowerCase() === 'inbound'
                            ? contact.originatingNumberE164
                            : contact.dialledNumberE164,
                    attributes: { 'sa-call-provider': 'kumodi' },
                    ID: contact.ID,
                    created: Date.now(),
                    updated: Date.now(),
                    answered: true,
                    initiationMethod:
                        contact.direction.toLowerCase() === 'inbound' ? 'INBOUND' : 'OUTBOUND',
                    instanceID,
                    companyID,
                    initiationTimestamp: call?.start ? new Date(call.start).getTime() : Date.now(),
                    systemEndpointAddress:
                        contact.direction.toLowerCase() === 'outbound'
                            ? contact.originatingNumberE164
                            : contact.dialledNumberE164,
                    channel: 'VOICE',
                    queueID: 'Unknown',
                    queueName: 'Uknown', //<-- these will get sorted out once the server version of the CTR comes down.
                }
                store.dispatch(addToCallLog(currentCallInConnectFormat))
            }
        } else {
            //the call was a missed call or failed to connect, don't log it.
            store.dispatch(clearContact())
        }
        if (!store.getState().call) return //If the app didn't know it was even in a call, nothing to do.
        store.dispatch(callActions.callEnded())
    }

    //AGENT

    const onAgentLoginSuccess = (authInfo: kumodi.KAuthSuccess) => {
        const { agent } = authInfo
        const AvailableState = {
            type: connect.AgentStateType.ROUTABLE,
            name: 'Available',
        } as connect.AgentStateDefinition
        const OfflineState = {
            type: connect.AgentStateType.ROUTABLE,
            name: 'Offline',
        } as connect.AgentStateDefinition
        //Build up a 'connect style' agent state which is what the app is expecting.

        const instance = store.getState().app.instance
        if (!instance?.accountID)
            throw new Error(
                'No accountID on selected instance, kumodi mw requires this to be set to build queue ARNs. Check company record for this company/instance',
            )

        const arnRoot = `arn:aws:connect:eu-west-2:${instance.accountID}:instance/${agent.clientID}`
        const myCallsQueue = {
            name: 'My calls',
            queueARN: `${arnRoot}/queue/${agent.userID}`, //Every user is given a queue for their own calls where queueID === userID
            queueId: `${arnRoot}/queue/${agent.userID}`, //From checking connect queues, looks like ID and arn are the same even though this looks weird.
        }
        const teamQueues = agent.teams.map((team) => {
            return {
                name: team.name,
                queueARN: `${arnRoot}/queue/${team.ID}`,
                queueId: `${arnRoot}/queue/${team.ID}`,
            }
        })
        const saAgent: UserState = {
            timeRange: 'midnight',
            agentID: agent.ID,
            name: agent.displayName || 'Unknown User',
            status: AvailableState,
            states: [OfflineState, AvailableState],
            username: agent.username!,
            softphoneEnabled: true,
            routingProfile: {
                ID: 'default',
                name: 'no-routing-profile',
            },
            queues: [myCallsQueue, ...teamQueues],
            afterCallStatus: undefined,
            inStateSince: new Date(Date.now() - 10),
        } as unknown as UserState
        log('kumodi-middleware: - onAgentLoginSuccess calling user loaded with what we got back')
        store.dispatch(
            userActions.userLoaded({
                ...saAgent,
            }),
        )
    }

    const onAgentStatusChange = (status: kumodi.KStatus) => {
        log('kumodi-middleware: - onAgentStatusChange, to:', status)
        const user = store.getState().user
        const previousStatusName = user?.status.name
        if (status === 'AfterCallWork') {
            log('kumodi-middleware: - dispatching action to change status to AfterCallWork')
            store.dispatch(userActions.afterCallWork())
            //Navigate to dialler on after call work
            store.dispatch(setRedirect('/'))
        } else if (status === 'Available') {
            if (previousStatusName === 'AfterCallWork') {
                store.dispatch(userActions.afterCallWorkEnd())
            }
            store.dispatch(userActions.setAvailable())
        }
        store.dispatch(
            userActions.userLoaded({
                status: {
                    agentStateARN: '',
                    name: status,
                    type:
                        status === 'Available'
                            ? connect.AgentStateType.ROUTABLE
                            : connect.AgentStateType.NOT_ROUTABLE,
                },
            }),
        )
    }

    const onAgentLoginError = (error: kumodi.KError) => {
        log('kumodi-middleware: - onAgentLoginError')
        store.dispatch(userActions.authError(`Error logging into kumodi platform`))
        store.dispatch(userActions.logout())
    }

    const onAgentError = (error: kumodi.KError) => {
        log('kumodi-middleware: Agent transferred into an error state')
    }

    const onAgentSoftphoneError = (error: kumodi.KError) => {
        log('Softphone error')
        if (!(store.getState() as RootState).global.softphoneError) {
            store.dispatch(addSoftphoneError(connect.SoftphoneErrorTypes.OTHER))
        }
    }

    kumodi.addListeners({
        onContactReceived,
        onContactAccepted,
        onContactConnected,
        onContactEnded,
        onAgentLoginSuccess,
        onAgentLoginError,
        onAgentStatusChange,
        onAgentError,
        onAgentSoftphoneError,
    })
}

export default kumodiMW
