import { Box } from '@missionlabs/smartagent-app-components'
import useInterval from 'hooks/useInterval'
import useSmartAgentAPI from 'hooks/useSmartAgentAPI'
import Search from 'images/search.svg'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { BeatLoader } from 'react-spinners'
import { getTranscription } from 'services/api/api.contact'
import RootState from 'store/state'
import { IContactContext, withContactConsumerContext } from 'widgets/contexts/contact'
import Transcript from './Transcript'
import { H } from 'react-accessible-headings'

export interface ITranscript {
    messages: ITranscriptMessage[]
}

export enum TranscriptChannel {
    AGENT = 0,
    CUSTOMER = 1,
}

export enum TranscriptSentiment {
    POSITIVE = 'POSITIVE',
    NEGATIVE = 'NEGATIVE',
    NEUTRAL = 'NEUTRAL',
    MIXED = 'MIXED',
}

export interface ITranscriptMessage {
    startTime?: string
    content: string
    channel: TranscriptChannel
    sentiment: TranscriptSentiment
}
interface ISentimentScoreTotals {
    POSITIVE: number
    NEGATIVE: number
    NEUTRAL: number
    MIXED: number
}

interface Props {
    contactID?: string
    initiationTimestamp?: number
    context: IContactContext
    widgetTitle?: string
    disconnectTimestamp?: number
}

const getSentimentTotals = (transcriptArray: ITranscriptMessage[], dispatchFn: any) => {
    if (!transcriptArray) {
        return
    }

    const sentimentScoreTotals: ISentimentScoreTotals = {
        POSITIVE: 0,
        NEGATIVE: 0,
        NEUTRAL: 0,
        MIXED: 0,
    }

    transcriptArray.forEach((message: ITranscriptMessage) => {
        // only use customer responses from the transcript which are in channel 1
        if (message.channel === 1) {
            const sentiment = message.sentiment
            sentimentScoreTotals[sentiment] = sentimentScoreTotals[sentiment] + 1 || 1
        }
    })

    //  get total of all sentiments combined. Needed to caluclate the percentages
    const sumOfAllSentiments: number = Object.values<any>(sentimentScoreTotals).reduce(
        (accumulator: number, currentValue: number) => accumulator + currentValue,
        0,
    )

    const convertToPercent = (num: number) => {
        return Math.floor((num / sumOfAllSentiments) * 100)
    }

    // calculate final percentages. MIXED is ignored to match the values shown in the standard connect charts
    const customerSentimentPercentages = {
        positive: convertToPercent(sentimentScoreTotals.POSITIVE),
        negative: convertToPercent(sentimentScoreTotals.NEGATIVE),
        neutral: convertToPercent(sentimentScoreTotals.NEUTRAL),
    }

    dispatchFn(customerSentimentPercentages)
}

const CallTranscription: React.FC<Props> = (props) => {
    const contactID = useSelector((state: RootState) => props.contactID || state.contact?.ID || '')
    const initiationTimestamp = useSelector(
        (state: RootState) => props.initiationTimestamp || state.contact?.initiationTimestamp,
    )
    const disconnectTimestamp = useSelector(
        (state: RootState) => props.disconnectTimestamp || state.contact?.disconnectTimestamp,
    )

    const [error, loading, response, request] = useSmartAgentAPI<ITranscript, RootState>(
        async (state) =>
            getTranscription(
                state.app.ID,
                state.app.instance!.ID,
                state.auth.token!,
                contactID,
                initiationTimestamp,
                disconnectTimestamp,
            ),
    )

    const accepted = response?.status === 202

    useEffect(() => {
        if (response?.status === 200 && response?.data) {
            // calculate the customer sentiment from the transcript and pass the dispatch function to update the context
            props.context.setSentimentPercentages &&
                getSentimentTotals(response.data.messages, props.context.setSentimentPercentages)
        }
    }, [response, props.context.setSentimentPercentages])

    useEffect(() => {
        request()
        // eslint-disable-next-line
    }, [contactID])

    useInterval(() => {
        if (response?.status === 200 || loading) return
        if (response?.status === 202) return request()
    }, 15_000)

    const headerTitle = props.widgetTitle ? props.widgetTitle : 'Transcription'

    return (
        <Box
            aria-busy={loading || accepted}
            collapse
            alt
            hidden={!!error}
            boxLabel="{headerTitle}"
            header={<H>{headerTitle}</H>}
            className="sa-transcript-box"
        >
            {loading ? (
                <div className="sa-transcript-no-data">
                    <BeatLoader color="#000" />
                </div>
            ) : !!error ? (
                <div className="sa-transcript-no-data">
                    <img src={Search} alt="Search" width="24px" height="24px" />
                    <p>We were unable to transcribe this conversation</p>
                </div>
            ) : accepted ? (
                <div className="sa-transcript-no-data">
                    <BeatLoader color="#000" />
                    <p>We are currently transcribing this conversation</p>
                </div>
            ) : response?.data ? (
                <Transcript {...response.data} />
            ) : null}
        </Box>
    )
}

export default withContactConsumerContext(CallTranscription)
