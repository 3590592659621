import {
    Button,
    CopyClipboard,
    TimeElapsed,
    TriggeredPopup,
} from '@missionlabs/smartagent-app-components'
import { CountryCode } from 'libphonenumber-js'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ClipLoader } from 'react-spinners'

import * as callActions from 'store/call/call.actions'
import { selectCallConnections, selectOnHoldConnections } from 'store/call/call.selectors'
import { CallConnection } from 'store/call/call.state'
import { updateContactAttributes } from 'store/contact/contact.actions'
import { monitorAgent } from 'store/metrics/metrics.actions'
import RootState from 'store/state'

import useMetrics from 'hooks/redux/useMetrics'
import useMonitoring from 'hooks/redux/useMonitoring'
import useGetDirectoryContact from 'hooks/useGetDirectoryContact'
import useHasFeature, { AppFeatures } from 'hooks/useHasFeature'
import useParkCall from './useParkCall'

import { getBodyWidth, getConnectionDisplayNumber } from 'utils'

import Keypad from 'components/Keypad'
import copyIcon from 'images/icon-copy-outline-white.svg'
import iconParking from 'images/icon-parking-outline.svg'
import { selectContactSubType } from 'store/contact/contact.selectors'
import Decline from './buttons/Decline-Button.svg'
import KeypadBtn from './buttons/Keypad-Button.svg'
import Pause from './buttons/Pause-Button.svg'
import Resume from './buttons/Play-Button.svg'
import SoundOff from './buttons/SoundOff-Button.svg'
import SoundOn from './buttons/SoundOn-Button.svg'
import Switch from './buttons/Swap-Button.svg'
import Transfer from './buttons/Transfer-Button.svg'

export interface Props {
    connection: CallConnection
    name?: string
    noConnections: number
    onToggleDial: () => void
    conferenced: boolean
    contactID: string
    direction: string
    muted?: boolean
    hideMuteButton?: boolean
    originNumber?: string
    countryCode?: CountryCode
}

const Connection: React.FC<Props> = ({
    connection,
    name,
    noConnections,
    onToggleDial,
    conferenced,
    contactID,
    muted,
    hideMuteButton,
    originNumber,
    countryCode,
}) => {
    const dispatch = useDispatch()
    const hasFeature = useHasFeature()

    const { isMonitoring, isEnhancedMonitoringEnabled } = useMonitoring()
    const { monitoringAgent } = useMetrics()
    const { park, parking } = useParkCall()
    const [, directoryContactName] = useGetDirectoryContact(connection?.number)

    const contact = useSelector((state: RootState) => state.contact)

    const connections = useSelector(selectCallConnections)
    const onHoldConnections = useSelector(selectOnHoldConnections)
    const contactSubType = useSelector(selectContactSubType)

    // Temporary change until LCM is migrated to V2 of the Call State
    const isConnected = ['connected', 'hold'].includes(connection?.status.toLowerCase())
    const isActiveCall = connection?.activeConnection

    const isWebCall = contactSubType === 'connect:WebRTC'

    const onKeypadButton = (val: string) => {
        console.log('sending ' + val + '...')
        dispatch(callActions.sendDTMF(connection.id, val))
    }

    const onResume = () => {
        dispatch(callActions.resume(connection.id))
    }

    const onHold = () => {
        dispatch(callActions.hold(connection.id))
    }

    const onConference = () => {
        dispatch(callActions.conferenceConnections())
    }

    const onEnd = () => {
        if (isMonitoring) {
            dispatch(monitorAgent(false, monitoringAgent?.agentID))
            dispatch(callActions.leaveCall())

            // If this is last connection then the agent has hungup so set this against the contact
        } else if (noConnections === 1) {
            dispatch(updateContactAttributes(contactID, { 'sa-agent-hungup': 'yes' }))
        }

        dispatch(callActions.endConnection(connection.id))
    }

    const handleV1Hold = () => {
        connections?.length === 2 && onHoldConnections?.length === 1 ? onConference() : onResume()
    }

    const connectedNumber = originNumber ?? (contact?.customerEndpointAddress || connection?.number)

    const displayNumber = isMonitoring
        ? 'Monitoring'
        : getConnectionDisplayNumber(connectedNumber!, countryCode)

    return (
        <div className={'dialler-incall row between' + (isActiveCall ? ' active' : ' inactive')}>
            <div className="call-details row column">
                {displayNumber && (
                    <span className="remote-number">
                        <div className="number">{displayNumber}</div>
                        {hasFeature(AppFeatures.EXTERNAL_DIRECTORY) && (
                            <CopyClipboard copyStr={displayNumber} icon={copyIcon} size={16} />
                        )}
                    </span>
                )}
                {isWebCall && <span className='title'>Web call</span>}
                {(name && connection.initialConnection) ||
                connection?.name ||
                directoryContactName ? (
                    <span className="remote-name xsm-mar-vertical connection-name">
                        {name ?? connection?.name ?? directoryContactName}
                    </span>
                ) : null}

                {parking ? (
                    <p>Parking call...</p>
                ) : (
                    <TimeElapsed
                        ariaLive="off"
                        className="status-name"
                        displayOnZero={connection?.status}
                        date={connection?.start}
                    />
                )}
            </div>
            <div className="dialler-controls">
                {(parking || (isActiveCall && isConnected && !isMonitoring)) && (
                    <>
                        {parking ? (
                            <ClipLoader color="#fff" />
                        ) : (
                            hasFeature(AppFeatures.CALL_PARKING) && (
                                <Button
                                    onClick={() => park()}
                                    className="switch dialler-button"
                                    disabled={connection.hold}
                                >
                                    <img src={iconParking} alt="Park" />
                                </Button>
                            )
                        )}
                        {!conferenced && noConnections > 1 ? (
                            <Button
                                onClick={() => dispatch(callActions.toggleConnections())}
                                className="switch dialler-button"
                                disabled={connection.hold}
                            >
                                <img src={Switch} alt="Switch" title="Switch" />
                            </Button>
                        ) : null}

                        {noConnections === 1 && !conferenced && connection.initialConnection ? (
                            <Button onClick={onToggleDial} className="transfer dialler-button">
                                <img src={Transfer} alt="Transfer" title="Transfer" />
                            </Button>
                        ) : null}

                        {(connection.initialConnection || (isActiveCall && !conferenced)) &&
                            !hideMuteButton && (
                                <Button
                                    onClick={() =>
                                        muted
                                            ? dispatch(callActions.unmute())
                                            : dispatch(callActions.mute())
                                    }
                                >
                                    <img
                                        src={muted ? SoundOff : SoundOn}
                                        alt={muted ? 'Unmute' : 'Mute'}
                                        title={muted ? 'Unmute' : 'Mute'}
                                    />
                                </Button>
                            )}

                        {connection.hold ? (
                            <Button
                                onClick={handleV1Hold}
                                className="resume dialler-button"
                                disabled={!connection.activeConnection}
                            >
                                <img
                                    src={Resume}
                                    alt="Resume"
                                    title="Resume"
                                    width="18px"
                                    height="18px"
                                />
                            </Button>
                        ) : (
                            <Button onClick={onHold} className="hold dialler-button">
                                <img
                                    src={Pause}
                                    alt="Hold"
                                    title="Hold"
                                    width="30px"
                                    height="30px"
                                />
                            </Button>
                        )}

                        <TriggeredPopup
                            noShadow
                            className="dialler-popup"
                            fromRight={getBodyWidth() > 500}
                            closeOnClickOutside
                            trigger={
                                <Button
                                    className="call-keypad white-button"
                                    styling="blank"
                                    type="button"
                                >
                                    <img
                                        src={KeypadBtn}
                                        alt={connection.initialConnection ? 'Keypad 1' : 'Keypad 2'}
                                        title={
                                            connection.initialConnection ? 'Keypad 1' : 'Keypad 2'
                                        }
                                    />
                                </Button>
                            }
                        >
                            <Keypad recordPresses onKeypadButton={onKeypadButton} />
                        </TriggeredPopup>
                    </>
                )}
                {(!isMonitoring || (isMonitoring && !isEnhancedMonitoringEnabled)) && (
                    <Button onClick={onEnd}>
                        <img src={Decline} alt="end" width="40" height="40" />
                    </Button>
                )}
            </div>
        </div>
    )
}

export default Connection
