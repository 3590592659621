import './call-history.scss'

import useAppConfig from 'hooks/redux/useAppConfig'
import useCallLog from 'hooks/redux/useCallLog'
import useContact from 'hooks/redux/useContact'
import useRestructuredDirectoryContacts from 'hooks/useRestructuredDirectoryContacts'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCallLog } from 'store/callLog/callLog.actions'
import { ICallLog } from 'store/callLog/callLog.state'
import { getContact, setContact } from 'store/contact/contact.actions'
import { setNumberDialling } from 'store/global/global.actions'
import { getSettings } from 'store/settings/settings.actions'
import RootState from 'store/state'
import { formatPhoneNumber, formatStartOfIntNumbers } from 'utils'

import { H, Level } from 'react-accessible-headings'
import { Box } from '@missionlabs/smartagent-app-components'

import CallHistoryList from './CallHistoryList'

import type { Channel } from '@missionlabs/smartagent-lib-shared/build/form-templates/form-template.schema'
interface Props {
    channel: Channel
}

const CallLogView = ({ channel }: Props) => {
    const interval = useRef<NodeJS.Timeout | undefined>(undefined)

    const contact = useContact()
    const callLog = useCallLog()
    const appConfig = useAppConfig()
    const { settings, settingsLoading } = useSelector<RootState, RootState['settings']>(
        ({ settings }) => settings,
    )

    const dispatch = useDispatch()

    useEffect(() => {
        if (!settings && !settingsLoading) {
            dispatch(getSettings())
        }
    }, [settings, settingsLoading])

    useEffect(() => {
        if (!settings || settingsLoading) {
            return
        }
        dispatch(getCallLog())
        interval.current = setInterval(() => dispatch(getCallLog()), 1000 * 60)

        return () => {
            clearInterval(interval.current!)
        }
    }, [settingsLoading, settings])

    const [contacts] = useRestructuredDirectoryContacts()

    const onPollContact = () => {
        //Need to poll every 10 seconds to get data to replace the local copy
        if (!contact) return

        const call = callLog.find((c) => c.ID === contact.ID)

        if (!call || call.source === 'API') return

        dispatch(getContact(call.ID))

        setTimeout(() => {
            onPollContact()
        }, 1000 * 10) //10seconds
    }

    const handleOnPollContact = useCallback(onPollContact, [dispatch, contact, callLog])

    const onSelect = (loggedContact: ICallLog) => {
        // concat these together to handle selecting multiple items on missed call
        const id = loggedContact.ID

        if (contact?.ID === id) {
            dispatch(setContact(null))
            dispatch(setNumberDialling(''))
            return
        }
        if (loggedContact.source === 'LOCAL') {
            handleOnPollContact()
        }

        setTimeout(() => {
            dispatch(setContact(null))
            dispatch(setContact(loggedContact))
            dispatch(getContact(loggedContact?.ID))

            if (channel === 'VOICE') {
                dispatch(
                    setNumberDialling(
                        formatStartOfIntNumbers(
                            formatPhoneNumber(
                                loggedContact.attributes['sa-dialled-number'] ||
                                    loggedContact.customerEndpointAddress,
                            ),
                            internalNumberLength,
                        ),
                    ),
                )
            }
        }, 5)
    }

    const handleOnSelect = useCallback(onSelect, [dispatch, contact, handleOnPollContact])

    const { hideChatHistory, internalNumberLength } = appConfig

    let historyTitle

    switch (channel) {
        case 'VOICE':
            historyTitle = 'Call History'
            break
        case 'TASK':
            historyTitle = 'Contact History'
            break
        default:
            historyTitle = 'Chat History'
    }

    if (historyTitle === 'Chat History' && hideChatHistory) return null

    const filteredCallLogs = useMemo(
        () => callLog.filter((loggedContact) => loggedContact.channel === channel),
        [callLog],
    )

    return (
        <Box
            className="call-history-box"
            header={<H className="call-history-title">{historyTitle}</H>}
        >
            <Level>
                <CallHistoryList
                    contacts={contacts}
                    contact={contact}
                    callLog={filteredCallLogs}
                    onClick={handleOnSelect}
                />
            </Level>
        </Box>
    )
}

export default CallLogView
