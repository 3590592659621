import type { SocialChatJsonMessage } from '@missionlabs/smartagent-service-chat/dist/types/socialChatJsonMessage'
import { extension as getExtension } from 'mime-types'
import { IChatMessage, ISocialChatMessage } from 'store/chat/chat.state'
import { isSystemMessage } from 'store/chat/chat.utils'

export const sortMessagesWithOriginalTimestamp = (messages: ISocialChatMessage[]) => {
    messages.sort((a, b) => {
        if (
            !(a.content as SocialChatJsonMessage)?.originalTimestamp ||
            !(b.content as SocialChatJsonMessage)?.originalTimestamp
        ) {
            return 0
        }

        return (
            (a.content as SocialChatJsonMessage).originalTimestamp! -
            (b.content as SocialChatJsonMessage).originalTimestamp!
        )
    })
}

export const removeDuplicateParticipantJoinedMessages = (messages: ISocialChatMessage[]) => {
    const participantJoinedEvent = 'application/vnd.amazonaws.connect.event.participant.joined'
    return messages.reduce((messages: ISocialChatMessage[], currentMessage: ISocialChatMessage) => {
        if (
            !messages.find(
                (message) =>
                    message.AbsoluteTime === currentMessage.AbsoluteTime &&
                    message.ContentType === participantJoinedEvent,
            )
        ) {
            messages.push(currentMessage)
        }
        return messages
    }, [])
}

export const getFileLabelFromContentType = (contentType: string) => {
    const extension = getExtension(contentType)
    if (extension) {
        return `attachment.${extension}`
    } else if (contentType?.includes('/')) {
        // If mine-types library doesn't recognise type, make best effort guess
        return `attachment.${contentType?.split('/')?.[1]}`
    } else {
        return `attachment`
    }
}

export const getPreviousNonSystemMessage = (
    messages: ISocialChatMessage[],
    currentIndex: number,
): ISocialChatMessage | undefined => {
    if (currentIndex < 1 || currentIndex > messages.length - 1) return undefined
    const prevMessageIndex = currentIndex - 1
    const prevMessage = messages[prevMessageIndex]
    return isSystemMessage((prevMessage.content as SocialChatJsonMessage)?.type || prevMessage.Type)
        ? getPreviousNonSystemMessage(messages, prevMessageIndex)
        : prevMessage
}

export const doMessagesContainNonSystemMessage = (messages: ISocialChatMessage[]) => {
    return !!messages.find(
        (message) =>
            !isSystemMessage((message.content as SocialChatJsonMessage)?.type || message.Type),
    )
}

export const isInteractiveMessage = (message: ISocialChatMessage | IChatMessage) => {
    if(!message?.Content) return false
    const content = JSON.parse(message.Content);
    return Object.keys(JSON.parse(message.Content.replace('\n', ''))).find(key => 
        typeof content[key] === 'object' && 'type' in content[key]
      );
}
