import searchIcon from 'images/search.svg'

import './no-data-message.scss'

interface Props {
    text?: string
}

const NoDataMessage = (props: Props) => {
    return (
        <div className="row column auto">
            <img
                src={searchIcon}
                className="sm-mar-bottom"
                height="24"
                width="24"
                title="Search"
                alt=""
                aria-hidden
            />
            <p className="no-data-text">{props.text ?? 'There is no data available to view'}</p>
        </div>
    )
}

export default NoDataMessage
