import React, { useMemo } from 'react'

import { TimeElapsed } from '@missionlabs/smartagent-app-components'

import useCallV2 from 'hooks/redux/useCallV2'
import { useSelector } from 'react-redux'
import { selectContactSubType } from 'store/contact/contact.selectors'
import { getInitiationMethodIcon, getInitiationMethodText } from '../utils'
import styles from './call-summary.module.scss'

const CallSummary: React.FC = () => {
    const contactSubType = useSelector(selectContactSubType)

    const { initiationMethod, callStartTime } = useCallV2()

    const initiationMethodText = getInitiationMethodText(initiationMethod)

    const initiationMethodIcon = getInitiationMethodIcon(initiationMethod)

    const isWebCall = contactSubType === 'connect:WebRTC'

    const callStartDate = useMemo(() => {
        return new Date(callStartTime)
    }, [callStartTime])

    return (
        <div className={styles.container}>
            <div className={styles.details} data-testid="callSummary-initiationMethod">
                <img src={initiationMethodIcon} alt="" />
                {isWebCall ? (
                    <p className={styles.title}>Web call</p>
                ) : (
                    <p className={styles.title}>{initiationMethodText || 'Call Direction: Unknown'}</p>
                )}
            </div>
            <TimeElapsed date={callStartDate} />
        </div>
    )
}

export default CallSummary
