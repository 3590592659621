import { Button } from '@missionlabs/smartagent-app-components'
import classnames from 'classnames'
import useSystemMessages from 'hooks/redux/useSystemMessages'
import { ReactComponent as AnnouncementIcon } from 'images/icon-announcement.svg'
import { ReactComponent as LeftIcon } from 'images/icon-arrow-left.svg'
import { ReactComponent as RightIcon } from 'images/icon-arrow-right.svg'
import React, { useEffect, useState } from 'react'
import { setCSSVar, unsetCSSVar } from 'utils'
import EditorView from 'views/Common/EditorView'
import './index.scss'

interface Props {
    onSetClasses: (classes: string) => void
}

const SystemMessages: React.FC<Props> = ({ onSetClasses }) => {
    const systemMessages = useSystemMessages()

    const [selected, setSelected] = useState(0)
    const [expand, setExpand] = useState(false)

    const pfx = 'sa-system-messages' // CSS class prefix

    const onSelect = (i: number) => {
        setExpand(false)
        setSelected(i)
    }

    const msg = systemMessages[selected]

    useEffect(() => {
        if (!systemMessages.length) {
            onSetClasses('main-system-message-no-message')
            unsetCSSVar('--sa-header-system-messages-height')
        } else {
            if (expand) {
                unsetCSSVar('--sa-header-system-messages-height')
                onSetClasses('main-system-message-expanded')
            } else {
                setCSSVar('--sa-header-system-messages-height', '36px')
                onSetClasses('main-system-message')
            }
        }
    }, [systemMessages, expand, onSetClasses])

    if (!systemMessages.length) {
        return null
    }

    return (
        <div
            className={classnames([
                pfx,
                `${pfx}-${msg?.type}`,
                `${pfx}-${expand ? 'open' : 'closed'}`,
            ])}
        >
            <div className={`${pfx}-left`}>
                {/* Icon */}
                <AnnouncementIcon className={`${pfx}-left-icon`} height={15} width={20} />

                <div className={`${pfx}-left-content`}>
                    {/* Title */}
                    <p className={`${pfx}-left-content-title`}>{msg?.title}</p>
                    {/* Message */}
                    {expand &&
                        (msg?.rawMessage ? (
                            <EditorView rawHTML={msg?.rawMessage ?? ''} />
                        ) : (
                            <div>{!msg?.rawMessage && msg?.message}</div>
                        ))}
                </div>
            </div>

            <div className={`${pfx}-right`}>
                {/* < 1 of 12 > */}
                {systemMessages.length > 1 && (
                    <div className={`${pfx}-right-controller`}>
                        <Button
                            className={`${pfx}-right-prev`}
                            onClick={() => onSelect(selected - 1)}
                            disabled={selected === 0 || systemMessages.length === 1}
                        >
                            <LeftIcon height={12} width={12} />
                        </Button>

                        <div>{`${selected + 1} of ${systemMessages.length}`}</div>

                        <Button
                            className={`${pfx}-right-next`}
                            onClick={() => onSelect(selected + 1)}
                            disabled={
                                !!(
                                    systemMessages?.length &&
                                    selected === systemMessages?.length - 1
                                )
                            }
                        >
                            <RightIcon height={12} width={12} />
                        </Button>
                    </div>
                )}

                {/* Actions */}
                {expand || !msg?.message?.length ? (
                    <Button
                        className={`${pfx}-right-ok`}
                        small
                        onClick={(e) => {
                            msg?.onAction()
                            onSelect(selected === 0 ? 0 : selected - 1)
                        }}
                    >
                        {msg?.action}
                    </Button>
                ) : (
                    <Button className={`${pfx}-right-ok`} small onClick={() => setExpand(true)}>
                        View
                    </Button>
                )}
            </div>
        </div>
    )
}

export default SystemMessages
