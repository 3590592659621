import './contact-evaluation.scss'

import classNames from 'classnames'
import { Loader } from 'components'
import QAScoreTag from 'components/QAScoreTag'
import useSmartAgentAPI from 'hooks/useSmartAgentAPI'
import React, { useEffect, useState } from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import { getScore, getScoreExists } from 'services/api/api.qa'
import { setRedirect } from 'store/global/global.actions'
import { setCurrentScore, setShowNewQAScore } from 'store/qa/qa.actions'
import { IScoreDetails } from 'store/qa/qa.state'
import RootState from 'store/state'
import { QAStatus } from 'views/QA/ScoreView/FormBuilderQA/FormBuilderQA.types'

import { H } from 'react-accessible-headings'
import { Box } from '@missionlabs/smartagent-app-components'

import Evaluation from './Evaluation'

interface Props {
    contactID?: string
    hideLink?: boolean
}

export const ContactEvaluation: React.FC<Props> = (props) => {
    const [scoreID, setScoreID] = useState<string | undefined>(undefined)

    const contactID = useSelector<RootState, string>(
        (state) => state.contact?.ID ?? props.contactID ?? '',
    )

    const dispatch = useDispatch()

    const [error, loading, response, request] = useSmartAgentAPI<
        IScoreDetails | undefined,
        RootState
    >(async (state) => {
        const response = await getScoreExists(
            state.app.ID,
            state.auth.token!,
            props.contactID || contactID,
        )

        if (!response.data?.scoreID) {
            return {
                status: 404,
                data: undefined,
                headers: {},
                config: {},
                statusText: '',
            }
        } else {
            setScoreID(response.data.scoreID)
        }

        return getScore(state.app.ID, state.auth.token!, response.data.scoreID)
    })

    useEffect(() => {
        if (contactID) request()
        // eslint-disable-next-line
    }, [contactID])

    const goToScore = () =>
        batch(() => {
            if (response?.data) {
                dispatch(setCurrentScore(response.data))
                dispatch(setRedirect(`/qa/scores/${response.data.ID}`))
            } else {
                dispatch(setShowNewQAScore(true))
                dispatch(
                    setRedirect('/qa/scores', {
                        contactID,
                    }),
                )
            }
        })

    const renderHeader = (): React.ReactNode => {
        const score = response?.data

        return (
            <div className="row between middle">
                <H>Quality Assurance</H>

                {score && (
                    <QAScoreTag
                        percent={score.scorePercentage}
                        status={score.status}
                        failType={score.failType}
                    />
                )}
            </div>
        )
    }

    const isPass = () => response?.data?.status === QAStatus.PASS
    const isFail = () => response?.data?.status === QAStatus.FAIL

    const boxClasses = classNames({
        'sa-qa-status-widget-box': true,
        'sa-qa-status-widget-box-fail': isFail(),
        'sa-qa-status-widget-box-pass': isPass(),
    })

    return (
        <Box alt={!(isPass() || isFail())} collapse header={renderHeader()} className={boxClasses}>
            {loading ? (
                <Loader />
            ) : !!error && error.status !== 404 ? (
                <div className="">Unable to fetch evaluation data</div>
            ) : (
                <div className="sa-qa-status-widget-wrapper">
                    <Evaluation
                        scoreID={scoreID}
                        hideLink={props.hideLink}
                        goToScore={goToScore}
                        data={response?.data}
                    />
                </div>
            )}
        </Box>
    )
}
