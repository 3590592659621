import { jsxs, jsx } from "react/jsx-runtime";
import classNames from "classnames";
import { P as PulseLoader } from "./PulseLoader-ghZQ3xzD.mjs";
import { M as MessageUserType } from "./chat-message.types-V2YsTtfG.mjs";
import { getCSSVar } from "./utils.mjs";
const defaultTranslations = {
  typingAriaLabel: "typing"
};
const PlainTextChatMessage = ({
  ariaId,
  companyLogoUrl,
  senderType,
  translations = defaultTranslations
}) => {
  const colour = getCSSVar("global-dark-colour") || "#000";
  const messageBoxClasses = classNames({
    "sa-chat-message-text": true,
    "sa-chat-message-text__agent": senderType === MessageUserType.AGENT || senderType === MessageUserType.SYSTEM,
    "sa-chat-message-text__customer": senderType === MessageUserType.CUSTOMER
  });
  return /* @__PURE__ */ jsxs("div", { id: ariaId, className: "sa-chat-message-text-wrapper sa-chat-message-typing-indicator", "aria-label": translations.typingAriaLabel, children: [
    !!companyLogoUrl && /* @__PURE__ */ jsx(
      "img",
      {
        className: "sa-chat-message-text-wrapper-company-logo",
        "aria-hidden": "true",
        src: companyLogoUrl,
        alt: ""
      }
    ),
    /* @__PURE__ */ jsx("div", { className: "sa-chat-message-text-wrapper-inner", children: /* @__PURE__ */ jsx(
      "div",
      {
        className: messageBoxClasses,
        tabIndex: 0,
        children: /* @__PURE__ */ jsx("div", { children: /* @__PURE__ */ jsx(PulseLoader, { size: 10, color: colour }) })
      }
    ) })
  ] });
};
export {
  PlainTextChatMessage as default
};
