import type { ThunkDispatch } from '@reduxjs/toolkit'
import { IChatMessage } from 'store/chat/chat.state'
import RootState from 'store/state'

import { getQuickRepliesWithHierarchy } from '../../services/api/api.quick-replies'
import * as ChatActions from './chat.reducer'

export const {
    downloadConnectAttachmentFromTranscript,
    setAttachmentLoading,
    setDeliveredTimestamp,
    updateDPAResultChat,
    deleteAttachment,
    transferChat,
    updateAgentDPAResultChat,
    clearChatConnection,
    markChatMessageReceiptsAsRead,
    setCustomerInitiallyActive,
    sendAttachment,
    updateNote,
    acceptChat,
    declineChat,
    setAttachment,
    sendChatMessage,
    updateConnectionEditorContent,
    updateSocialEditorContent,
    completeChatACW,
    updateDPAChat,
    chatACW,
    extendChatACW,
    chatConnected,
    chatDisconnected,
    removeChatConnection,
    chatMissed,
    recoverChats,
    setUnreadChats,
    setSelectedChat,
    addChatConnection,
    endChat,
    chatContactIsTyping,
    chatSendTypingEvent,
    clearUnread,
    downloadAttachment,
    setReadTimestamp,
    setChatReplyText,
    setChatMessages,
    setLoadingTranscripts,
    setNextTranscriptToken,
} = ChatActions

export function getQuickReplies(channels?: string) {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, ChatActions.ChatAction>,
        getState: () => RootState,
    ) => {
        try {
            const quickRepliesWithChannels = getState().chat.quickReplies
            if (quickRepliesWithChannels && quickRepliesWithChannels[channels ?? 'ALL']) return

            dispatch(ChatActions.getQuickRepliesLoading())

            const allChannels = getState()
                .app.appConfig.channels?.map((channel) => channel.value)
                .join()
            const channelsParam = channels ?? allChannels
            const companyID = getState().app.ID
            const instanceID = getState().app.instance?.ID || ''

            const quickReplies = await getQuickRepliesWithHierarchy(
                companyID,
                instanceID,
                channelsParam,
            )

            dispatch(
                ChatActions.getQuickReplies({
                    [channels ?? 'ALL']: quickReplies.data,
                }),
            )
        } catch (error) {
            console.log('error getting quick replies')
        }
    }
}

export function receiveChatMessage(id: string, msg: IChatMessage) {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, ChatActions.ChatAction>,
        getState: () => RootState,
    ) => {
        let message: IChatMessage | IChatMessage[] = msg

        // check this chat is currently selected
        if (id !== getState().contact?.ID) {
            const chatConnection = getState().chat.connections.find((c) => c.id === id)

            !!chatConnection && dispatch(setUnreadChats({ id, unread: chatConnection?.unread }))
        }

        if (typeof msg.content !== 'string' && msg.content?.type === 'BATCH') {
            message = msg.content.messages.map((batchMessage) => ({
                ...msg,
                content: batchMessage,
            }))
        }

        dispatch(ChatActions.receiveChatMessage({ id, msg: message }))
    }
}
