import { jsxs, jsx } from "react/jsx-runtime";
import classNames from "classnames";
import { useState } from "react";
import "react-player";
import "react-spinners";
import Button from "./Button.mjs";
import "react-accessible-headings";
import "./index-BEw2Yate.mjs";
import "./Form.mjs";
import "./Input.mjs";
import "./Popup.mjs";
import "./ReactDatePicker.mjs";
import "./Tooltip.mjs";
import "./Tag.mjs";
import "./TriggeredPopup.mjs";
import "draft-js";
import "react-draft-wysiwyg";
import "./emoji-picker-l0sNRNKZ.mjs";
const copiedIcon = "data:image/svg+xml,%3csvg%20width='24'%20height='24'%20viewBox='0%200%2016%2016'%20fill='none'%20xmlns='http://www.w3.org/2000/svg'%3e%3cpath%20d='M8.00033%200.666504C12.0504%200.666504%2015.3337%203.94975%2015.3337%207.99984C15.3337%2012.0499%2012.0504%2015.3332%208.00033%2015.3332C3.95024%2015.3332%200.666992%2012.0499%200.666992%207.99984C0.666992%203.94975%203.95024%200.666504%208.00033%200.666504ZM10.4582%205.48337L6.87113%209.07046L5.54243%207.74177C5.34216%207.5415%205.01746%207.5415%204.81719%207.74177C4.61693%207.94204%204.61693%208.26674%204.81719%208.467L6.87113%2010.5209L11.1835%206.20861C11.3837%206.00834%2011.3837%205.68364%2011.1835%205.48337C10.9832%205.2831%2010.6585%205.2831%2010.4582%205.48337Z'%20fill='%2300856f'/%3e%3c/svg%3e";
const copyIcon = "data:image/svg+xml,%3csvg%20width='24'%20height='24'%20xmlns='http://www.w3.org/2000/svg'%3e%3cpath%20d='M20%202.25a1.75%201.75%200%20011.744%201.606L21.75%204v13a1.75%201.75%200%2001-1.606%201.744L20%2018.75h-4.25V20a1.75%201.75%200%2001-1.606%201.744L14%2021.75H4A1.75%201.75%200%20012.25%2020V7c0-.966.784-1.75%201.75-1.75h4.25V4a1.75%201.75%200%20011.606-1.744L10%202.25h10zm-9.468%204.5H4a.25.25%200%2000-.25.25v13c0%20.138.112.25.25.25h10a.25.25%200%2000.25-.25v-9.562h-2.967a.75.75%200%2001-.744-.648l-.006-.102-.001-2.938zm1.968%2010.5a.75.75%200%2001.102%201.493l-.102.007h-7a.75.75%200%2001-.102-1.493l.102-.007h7zM20%203.75H10a.25.25%200%2000-.243.193L9.75%204v1.25h2.62l.217.221.003.004.111.112%203.049%203.099v8.564H20a.25.25%200%2000.243-.193L20.25%2017V4a.25.25%200%2000-.193-.243L20%203.75zm-7.5%2010.5a.75.75%200%2001.102%201.493l-.102.007h-7a.75.75%200%2001-.102-1.493l.102-.007h7zm0-3a.75.75%200%2001.102%201.493l-.102.007h-7a.75.75%200%2001-.102-1.493l.102-.007h7zm-4.458-3a.75.75%200%2001.102%201.493l-.102.007H5.5a.75.75%200%2001-.102-1.493L5.5%208.25h2.542zm3.99-1.202v1.89h1.856l-1.856-1.89z'%20fill='%2369696E'%20fill-rule='nonzero'/%3e%3c/svg%3e";
function CopyClipboard(props) {
  const { copyStr, size, icon, showTextInButton, altDesign } = props;
  const iconToUse = icon ?? copyIcon;
  const [copyText, setCopyText] = useState("Copy");
  const [copyImg, setCopyImg] = useState(iconToUse);
  const onClick = () => {
    navigator.clipboard.writeText(copyStr);
    setCopyText("Copied");
    setCopyImg(copiedIcon);
    setTimeout(() => {
      setCopyText("Copy");
      setCopyImg(iconToUse);
    }, 3e3);
  };
  return /* @__PURE__ */ jsxs(
    Button,
    {
      type: "button",
      className: classNames({
        "sa-copy-clipboard": true,
        "sa-copy-clipboard-alt": !!altDesign
      }),
      onClick,
      title: `Copy text to clipboard: "${copyStr}"`,
      children: [
        /* @__PURE__ */ jsx("img", { className: "sa-copy-clipboard-icon", height: size, width: size, src: copyImg, alt: "" }),
        showTextInButton ? /* @__PURE__ */ jsx("span", { className: "sa-copy-clipboard-text", children: copyText }) : /* @__PURE__ */ jsx("div", { className: "sa-copy-clipboard-tooltip", children: copyText })
      ]
    }
  );
}
export {
  CopyClipboard as default
};
