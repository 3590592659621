import classNames from 'classnames'
import React from 'react'
import { ClipLoader } from 'react-spinners'

import { Box, Button, CopyClipboard } from '@missionlabs/smartagent-app-components'
import { H } from 'react-accessible-headings'

export const chromeCookieSettingsUrl = 'chrome://settings/cookies'
export const currentHostName = '[*.]smartagent.app'

export type Props = {
    onVerify: React.MouseEventHandler<HTMLButtonElement>
    verifying?: boolean
    error?: boolean
}

const ThirdPartyCookieInfo: React.FC<Props> = ({ onVerify, verifying = false, error = false }) => {
    const thirdPartyCookieHeadingId = '3pc-info-heading'

    const boxClass = classNames('sa-login-3pc-info', { 'sa-login-3pc-info--error': error })

    return (
        <Box alt className={boxClass} aria-labelledby={thirdPartyCookieHeadingId}>
            <div className="sa-login-3pc-info-header">
                <H id={thirdPartyCookieHeadingId}>Action Required</H>
                <p>
                    Due to an upcoming security upgrade in Chrome, you need to take the following
                    steps today to keep SmartAgent working.
                </p>
            </div>
            <div className="sa-login-3pc-info-body">
                <ol aria-label="Steps to enable third-party cookies">
                    <li>
                        Copy and paste the link below in a new tab in your browser:
                        <br aria-hidden />
                        <br aria-hidden />
                        <div className="sa-login-3pc-info-body-link-container">
                            <strong>{chromeCookieSettingsUrl}</strong>
                            <CopyClipboard
                                copyStr={chromeCookieSettingsUrl}
                                size={20}
                                showTextInButton
                                altDesign
                            />
                        </div>
                    </li>
                    <li>
                        Scroll down to the bottom section called: "Allowed to use third-party
                        cookies" and click the "Add" button
                    </li>
                    <li>
                        Copy and paste the url below and click the "Add" button:
                        <br aria-hidden />
                        <br aria-hidden />
                        <div className="sa-login-3pc-info-body-link-container">
                            <strong>{currentHostName}</strong>
                            <CopyClipboard
                                copyStr={currentHostName}
                                size={20}
                                showTextInButton
                                altDesign
                            />
                        </div>
                    </li>
                    <li>
                        Once you've done it, press the button below:
                        <br aria-hidden />
                        <br aria-hidden />
                        <Button
                            styling="primary"
                            className="sa-login-3pc-info-body-confirmation"
                            onClick={onVerify}
                        >
                            {verifying ? (
                                <div data-testid="completed-cookies-loading">
                                    <ClipLoader color="#fff" size={20} />
                                </div>
                            ) : (
                                "I've done this"
                            )}
                        </Button>
                    </li>
                </ol>
            </div>
            <p>Having issues? Contact your manager</p>
        </Box>
    )
}

export default ThirdPartyCookieInfo
