import InfoIcon from 'images/info-error.svg'
import { PureComponent } from 'react'
import { connect } from 'react-redux'
import { ClipLoader } from 'react-spinners'
import RootState from 'store/state'
import { authenticateUser, login } from 'store/user/user.actions'

import { Button, Form, Input } from '@missionlabs/smartagent-app-components'
import { H } from 'react-accessible-headings'

import LoginSplash from '../LoginSplash'

interface Props extends ReturnType<typeof mapStateToProps> {
    authenticateUser: (token: string, tokenInBody?: boolean) => void
    login: (creds?: { username: string; password: string }) => void
}

class LoginToken extends PureComponent<Props> {
    readonly state = {
        token: '',
    }

    componentDidMount() {
        if (!this.props.logout) setTimeout(this.props.login, 1000)
    }

    onTokenChange = (token: any) => {
        this.setState({ token })
    }

    onAuth = () => {
        this.props.authenticateUser(this.state.token, true)
    }

    onRetry = () => {
        // eslint-disable-next-line
        window.location.href = window.location.href
    }

    render() {
        const {
            authError,
            appError,
            authenticating,
            validatingToken,
            tokenError,
            tokenRequested,
            logout,
        } = this.props
        return (
            <>
                {appError ? (
                    <div className="row column" aria-live="polite">
                        <div className="sa-login-text sa-login-error">
                            There was a problem logging you in, please try again
                        </div>
                        <Button
                            className="sa-login-button"
                            onClick={this.onRetry}
                            round
                            large
                            styling="primary"
                        >
                            Retry
                        </Button>
                    </div>
                ) : tokenRequested ||
                  tokenError ||
                  authError ||
                  validatingToken ||
                  authenticating ? (
                    <>
                        <Form onSubmit={this.onAuth} className="sa-login-form">
                            {validatingToken && !this.state.token ? (
                                <div className="row middle center column">
                                    <p>Validating Token</p>
                                    <Button
                                        className="sa-login-button md-mar-top"
                                        disabled={!this.state.token}
                                        round
                                        large
                                        styling="primary"
                                    >
                                        <ClipLoader color="#fff" size={20} />
                                    </Button>
                                </div>
                            ) : (
                                <>
                                    <div className="row between middle">
                                        <H className="title">Enter Code</H>
                                        {(tokenError || authError) && (
                                            <div className="sa-login-error" aria-live="polite">
                                                <img alt="Error" src={InfoIcon} />
                                                <p>There was a problem with the token.</p>
                                            </div>
                                        )}
                                    </div>
                                    <div className="sa-login-text">
                                        <p>Your authorisation code has been sent to your email.</p>
                                    </div>
                                    <Input
                                        // eslint-disable-next-line jsx-a11y/no-autofocus
                                        autoFocus
                                        required
                                        label="Enter Code"
                                        value={this.state.token}
                                        onChange={this.onTokenChange}
                                        showError
                                    />

                                    <a className="request-code" href={window.location.href}>
                                        Not got your code? Click to request a new one
                                    </a>
                                    <Button
                                        className="sa-login-button md-mar-top"
                                        disabled={!this.state.token}
                                        round
                                        large
                                        styling="primary"
                                    >
                                        {validatingToken || authenticating ? (
                                            <ClipLoader color="#fff" size={20} />
                                        ) : (
                                            'Submit'
                                        )}
                                    </Button>
                                </>
                            )}
                        </Form>
                    </>
                ) : (
                    <LoginSplash onClick={this.props.login} loading={!logout} />
                )}
            </>
        )
    }
}

function mapStateToProps(state: RootState) {
    return {
        ...state.auth,
        appError: state.app.error,
    }
}

export default connect(mapStateToProps, { login, authenticateUser })(LoginToken)
