import type { Middleware } from '@reduxjs/toolkit'
import { initAppSuccess } from 'store/app/app.reducer'
import * as ChatReducer from 'store/chat/chat.reducer'
import { IChatMessage } from 'store/chat/chat.state'
import RootState from 'store/state'
import { isAnyAction, setCSSVar } from 'utils'

const bchatMiddleware: Middleware<{}, RootState> = (store) => (next) => async (action) => {
    if (!isAnyAction(action)) return

    switch (action.type) {
        case initAppSuccess.type: {
            // chat message variables
            setCSSVar('--sa-general-font-family', 'Roboto-Regular')
            setCSSVar('--sa-global-light-colour', '#3B83BF')
            setCSSVar('--sa-message-agent-background-colour', 'var(--core-darkblue)')
            setCSSVar('--sa-message-agent-text-colour', '#fff')
            setCSSVar('--sa-message-agent-timestamp-text-colour', '#fff')
            setCSSVar('--sa-message-customer-text-colour', '#000')
            setCSSVar('--sa-message-customer-timestamp-text-colour', '#000')
            setCSSVar('--sa-message-agent-border-width', '0')
            setCSSVar('--sa-message-customer-border-width', '0')

            return next(action)
        }

        case ChatReducer.addChatConnection.type: {
            try {
                const response = await window.bchat.getChatSession(
                    action.payload.initialContactId ?? action.payload.id,
                    'AGENT',
                )

                action.payload = {
                    ...action.payload,
                    ...response,
                }
            } catch (err) {
                console.error(
                    'error getting chat session from bchat, is the chat being started via smartagent customer chat app?',
                )
                return next(action)
            }
            return next(action)
        }

        case ChatReducer.receiveChatMessage.type: {
            try {
                const msg = action.payload.msg as IChatMessage
                if (msg.ParticipantRole !== 'CUSTOMER') return next(action)

                const response = await window.bchat.putChatMessage({
                    contactId:
                        store.getState().chat.connections.find((c) => c.id === action.payload.id)
                            ?.initialContactId ?? action.payload.id,
                    message: msg.content as string,
                })

                if (response?.translatedMessage) {
                    const originalMessage = msg.content
                    msg.content = response.translatedMessage
                    msg.OriginalMessage = originalMessage
                }
            } catch {
                return next(action)
            }

            return next(action)
        }
        default:
            return next(action)
    }
}

export default bchatMiddleware
