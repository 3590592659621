import React from 'react'
import './error.scss'
import { H } from 'react-accessible-headings'

interface Props {
    errorCode?: string;
}

const Error: React.FC<Props> = ({
    errorCode
}) => {

	return (
		<div className="sa-error">
			<div className="sa-error-main">
				<H>Oops!</H>
				<p>Unfortunately, there has been an error.</p>
                {errorCode && <p>Error code: {errorCode}</p>}
				<p>Please reload the app. If the problem persists
					contact the SmartAgent team.</p>
			</div>
		</div>
	);

};

export default Error;
