import { initiateLegacyMonitoring, recoverCall as recoverCallAction } from 'store/call/call.actions'
import { initialiseConnections } from 'store/callV2/callV2.actions'
import { recoverChats as recoverChatsAction } from 'store/chat/chat.actions'
import { setContact } from 'store/contact/contact.actions'
import { addContact } from 'store/contacts/contacts.actions'
import { recoverTasks as recoverTasksAction } from 'store/tasks/tasks.actions'

import { createConnection, getNonYouConnections } from 'store/call/call.utils'
import { getMonitorInfo } from 'store/callV2/callV2.utils'

import * as ccpUtils from './ccp.utils'

import { getChatTranscript } from './ccp.utils'
import type { MiddlewareStore } from './listeners/listeners.agent'

interface IGroupedContacts {
    [connect.ContactType.VOICE]: connect.Contact[]
    [connect.ContactType.CHAT]: connect.Contact[]
    [connect.ContactType.TASK]: connect.Contact[]
}

export const recoverContacts = (agent: connect.Agent, store: MiddlewareStore) => {
    if (!agent) return

    const contacts = agent.getContacts()

    if (!contacts.length) return

    // Set object which will be used to group contacts by type
    const groupedContactsObj: IGroupedContacts = {
        [connect.ContactType.VOICE]: [],
        [connect.ContactType.CHAT]: [],
        [connect.ContactType.TASK]: [],
    }

    // Loop through all contacts and group them by type
    // We don't want to recover queue callbacks
    const groupedContacts = contacts.reduce<IGroupedContacts>((accumulatedContacts, contact) => {
        const contactType = contact.getType()

        if (contactType === connect.ContactType.QUEUE_CALLBACK) return accumulatedContacts

        accumulatedContacts[contactType].push(contact)

        return accumulatedContacts
    }, groupedContactsObj)

    const {
        [connect.ContactType.VOICE]: voiceContacts,
        [connect.ContactType.CHAT]: chatContacts,
        [connect.ContactType.TASK]: taskContacts,
    } = groupedContacts

    // Extract contacts from each group and if they're present, recover them
    if (voiceContacts.length > 0) {
        recoverCall(voiceContacts[0], store, agent)
    }
    if (chatContacts.length > 0) {
        recoverChats(chatContacts, store, agent)
    }
    if (taskContacts.length > 0) {
        recoverTasks(taskContacts, store, agent)
    }
}

export const recoverCall = (
    contact: connect.Contact,
    store: MiddlewareStore,
    agent: connect.Agent,
) => {
    // don't attempt to recover calls that weren't previously connected
    if (contact.getState().type !== connect.ContactStateType.CONNECTED) return

    const agentConnection = contact.getAgentConnection() as connect.VoiceConnection
    const monitorInfo = getMonitorInfo(agentConnection)
    const { status } = monitorInfo
    const isEnhancedMonitoringEnabled = ccpUtils.getIsEnhancedMonitoringEnabled(store)

    if (isEnhancedMonitoringEnabled) store.dispatch(initialiseConnections(contact))

    if (status && !isEnhancedMonitoringEnabled) {
        store.dispatch(initiateLegacyMonitoring(contact))
    } else {
        const connections = contact.getConnections() as connect.VoiceConnection[]
        const nonYouConnections = getNonYouConnections(connections)

        const initialConnection = contact.getInitialConnection()
        const { phoneNumber } = initialConnection.getEndpoint()

        const isInbound = contact.isInbound()

        store.dispatch(
            recoverCallAction({
                outbound: !isInbound,
                direction: isInbound ? 'inbound' : 'outbound',
                connections: nonYouConnections.map((connection) =>
                    createConnection(connection, phoneNumber),
                ),
                number: phoneNumber,
                start: new Date(Date.now() - contact.getStatusDuration()),
                monitorInfo,
            }),
        )

        connections.forEach((connection) => {
            ccpUtils.monitorConnection(store, connection)
        })
    }

    const contactState = ccpUtils.getContactState(store.getState(), contact, agent)

    store.dispatch(setContact(contactState))
    store.dispatch(addContact(contactState))
}

const recoverChats = async (
    contacts: connect.Contact[],
    store: MiddlewareStore,
    agent: connect.Agent,
) => {
    const connections = contacts.map((contact) =>
        contact.getAgentConnection(),
    ) as connect.ChatConnection[]
    connections.forEach((connection) => {
        ccpUtils.monitorChatConnection(connection.getContactId(), store, connection)
    })

    const chats = await Promise.all(
        contacts.map(async (contact) => {
            const chat = await ccpUtils.makeChatFromContact(contact)
            const {messages, nextToken} = await getChatTranscript(contact, chat.messages);
            return { ...chat, messages: [...messages, ...chat.messages], nextTranscriptToken: nextToken }
        }),
    )
    store.dispatch(recoverChatsAction(chats))

    const { ERROR, MISSED, CONNECTING, REJECTED } = connect.ContactStateType

    contacts
        .filter(
            (contact) => ![ERROR, MISSED, CONNECTING, REJECTED].includes(contact.getStatus().type),
        )
        .forEach((contact) => {
            const contactState = ccpUtils.getContactState(store.getState(), contact, agent)
            store.dispatch(addContact(contactState))
        })
}

const recoverTasks = async (
    contacts: connect.Contact[],
    store: MiddlewareStore,
    agent: connect.Agent,
) => {
    const tasks = await Promise.all(
        contacts.map((task) => ({
            ...ccpUtils.makeTaskFromContact(task),
            includeCCInReply: ccpUtils.shouldIncludeCCInReplyWhenCreatingTask(store),
        })),
    )
    store.dispatch(recoverTasksAction(tasks))

    const { ERROR, MISSED, CONNECTING, REJECTED } = connect.ContactStateType

    contacts
        .filter(
            (contact) => ![ERROR, MISSED, CONNECTING, REJECTED].includes(contact.getStatus().type),
        )
        .forEach((contact) => {
            const contactState = ccpUtils.getContactState(store.getState(), contact, agent)
            store.dispatch(addContact(contactState))
        })
}
