import './dpa.scss'

import classnames from 'classnames'
import { PureComponent } from 'react'
import { connect } from 'react-redux'
import {
    updateAgentDPAResultChat,
    updateDPAChat,
    updateDPAResultChat,
} from 'store/chat/chat.actions'
import {
    updateAgentDPAResult,
    updateContactAttributes,
    updateDPA,
    updateDPAResult,
} from 'store/contact/contact.actions'
import { DPAResult, DPACheck as IDPACheck } from 'store/contact/contact.state'
import { getSettings } from 'store/settings/settings.actions'
import RootState from 'store/state'

import { Box } from '@missionlabs/smartagent-app-components'
import { CheckState } from '@missionlabs/smartagent-app-components/dist/MultiStateCheckbox'

import DPACheck from './DPACheck'

interface Props extends ReturnType<typeof mapStateToProps> {
    updateDPAResult: typeof updateDPAResult
    updateDPA: typeof updateDPA
    updateAgentDPAResult: typeof updateAgentDPAResult
    updateDPAResultChat: typeof updateDPAResultChat
    updateDPAChat: typeof updateDPAChat
    updateAgentDPAResultChat: typeof updateAgentDPAResultChat
    getSettings: () => void
    updateContactAttributes: typeof updateContactAttributes
}

interface State {
    checksCollapsed: boolean
    collapseIDandV: any
}

class DPA extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            collapseIDandV: {},
            checksCollapsed: false,
        }
    }
    onChange = (e: any, check: any): void => {
        const passed = e === CheckState.checked

        this.updateDPA({
            ...check,
            passed,
        })

        this.updatePassAttributes(check.passedName, passed)
    }
    componentDidMount() {
        if (this.passCount() >= this.passesRequired()) {
            this.updateDPAResult(DPAResult['bot-passed'])
        } else {
            this.updateDPAResult(DPAResult['failed'])
        }

        this.props.getSettings()
    }

    componentDidUpdate(prevProps: Props) {
        if (JSON.stringify(this.props.DPA) !== JSON.stringify(prevProps.DPA)) {
            const { DPA } = this.props
            if (DPA?.result !== DPAResult['agent-overall-passed']) {
                if (this.passCount() >= this.passesRequired())
                    this.updateDPAResult(DPAResult['agent-passed'])
                else this.updateDPAResult(DPAResult['failed'])
            }
        }
    }

    onAgentCheckChange = (e: any, check: any): void => {
        const passed = e === CheckState.checked

        this.setState({ checksCollapsed: !!passed })

        this.updateAgentDPAResult({
            ...check,
            passed,
        })

        this.updatePassAttributes(check.passedName, passed)
    }

    updateDPA = (check: IDPACheck) => {
        if (this.props.channel === 'CHAT') {
            return this.props.updateDPAChat({ id: this.props.contactID!, check })
        }

        this.props.updateDPA(check)
    }

    updateAgentDPAResult = (check: IDPACheck) => {
        if (this.props.channel === 'CHAT') {
            return this.props.updateAgentDPAResultChat({ id: this.props.contactID!, check })
        }

        this.props.updateAgentDPAResult(check)
    }

    updateDPAResult = (result: DPAResult) => {
        if (this.props.channel === 'CHAT') {
            return this.props.updateDPAResultChat({ id: this.props.contactID!, result })
        }

        this.props.updateDPAResult(result)
    }

    passesRequired() {
        const { DPA, passesRequired } = this.props
        return DPA?.passesRequired || passesRequired || DPA?.checks.length || 0
    }

    passCount() {
        const { DPA } = this.props
        return DPA?.checks.filter((c: any) => c.passed === true).length || 0
    }

    updatePassAttributes = (checkName: string, passed: boolean): void => {
        this.props.updateContactAttributes(
            this.props.contactID!,
            {
                [checkName]: passed ? 'yes' : 'no',
            },
            true,
        )
    }

    render() {
        const { DPA, DPAPlugin } = this.props
        if (!DPA || DPAPlugin === 'hide') return null

        return (
            <section>
                <Box
                    hidden={this.state.collapseIDandV[this.props.contactID!] || false}
                    onToggle={(open) => {
                        const key = this.props.contactID!
                        this.setState((prevState) => ({
                            collapseIDandV: { ...prevState.collapseIDandV, [key]: open },
                        }))
                    }}
                    collapse
                    className={classnames({
                        'sa-dpa': true,
                        green:
                            DPA.result === DPAResult['bot-passed'] ||
                            DPA.result === DPAResult['agent-passed'] ||
                            DPA.result === DPAResult['agent-overall-passed'],
                        red: DPA.result === DPAResult['failed'],
                    })}
                    header={
                        <div className="row between middle">
                            <p className="sa-dpa-title">ID&amp;V</p>

                            {DPA?.result !== DPAResult['agent-overall-passed'] && (
                                <div className="row no-stretch sa-dpa-counter">
                                    <span>{this.passCount() || 0}</span>
                                    <span>&nbsp;out of&nbsp;</span>
                                    <span>{this.passesRequired()}</span>
                                </div>
                            )}
                        </div>
                    }
                >
                    {DPA.checks.length > 0 &&
                        DPA.checks.map((check, i) => (
                            <DPACheck
                                key={check.label + i}
                                check={check}
                                onChange={this.onChange}
                                readOnly
                            />
                        ))}
                    {DPA.agentCheck && (
                        <DPACheck
                            isAgentCheck
                            key="agent-check"
                            check={DPA.agentCheck}
                            onChange={this.onAgentCheckChange}
                        />
                    )}
                </Box>
            </section>
        )
    }
}

function mapStateToProps(state: RootState) {
    let DPA
    const contactID = state.contact?.ID

    if (state.contact?.channel === 'CHAT') {
        DPA = state.chat.connections.find((c) => c.id === contactID)?.DPA
    } else {
        DPA = state.contact?.DPA
    }

    return {
        DPA,
        contactID,
        channel: state.contact?.channel,
        passesRequired: state.app.appConfig.defaultNumberDPAPassesRequiredForPass,
        DPAPlugin: state.contact?.plugins?.['DPA'],
    }
}

export default connect(mapStateToProps, {
    updateDPA,
    updateDPAResult,
    updateAgentDPAResult,
    updateDPAChat,
    updateDPAResultChat,
    updateAgentDPAResultChat,
    getSettings,
    updateContactAttributes,
})(DPA)
