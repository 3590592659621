import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Redirect as RouterRedirect } from 'react-router-dom'
import { clearRedirect } from 'store/global/global.actions'

interface Props {
    to: string
    state?: any
}

export const Redirect: React.FC<Props> = ({ to, state }) => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(clearRedirect())
    })

    return (
        <RouterRedirect
            path="/"
            push
            to={{
                pathname: to,
                search: window.location.search,
                state,
            }}
        />
    )
}
