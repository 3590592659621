import { AxiosError } from 'axios'
import * as AgentAPI from 'services/api/api.agent'
import { logout } from 'store/user/user.actions'

import { ThunkDispatch } from '@reduxjs/toolkit'

import RootState from '../state'
import * as AgentsActions from './agents.reducer'
import { AgentInfo } from './agents.state'

export interface SetAgentsLoadingAction {
    loading: boolean
}

export interface SetAgentsErrorAction {
    error: boolean
}

export interface GetAgentsAction {
    agents: Array<AgentInfo>
}

export function getAgents() {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, AgentsActions.AgentsAction>,
        getState: () => RootState,
    ) => {
        const { agentsError, agentsLoading, agentsFilter } = getState().agents

        if (agentsError) {
            dispatch(AgentsActions.setAgentsError(false))
        }
        if (!agentsLoading) {
            dispatch(AgentsActions.setAgentsLoading(true))
        }

        try {
            const companyID = getState().app.ID
            const instanceID = getState().app.instance!.ID
            const token = getState().auth.token!

            const data = await AgentAPI.getAgents(companyID, token, {
                ...agentsFilter,
                instance_id: instanceID,
            })

            dispatch(AgentsActions.setAgentsLoading(false))
            dispatch(AgentsActions.getAgents(data))
        } catch (err) {
            const e = err as AxiosError
            if (e.response?.status === 403) return dispatch(logout())

            dispatch(AgentsActions.setAgentsError(true))
        }
    }
}

export const {
    clearAgents,
    filterAgents: setAgentsFilter,
    showAgents,
    openPhonebook,
} = AgentsActions
