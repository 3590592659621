import useHasFeature, { AppFeatures, IConfigCheck, useGetFeatureConfig } from 'hooks/useHasFeature'
import useInterval from 'hooks/useInterval'
import React, { PropsWithChildren, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setInternetConnection } from 'store/global/global.actions'
import RootState from 'store/state'
import { refreshToken, userAuthenticationHasSet } from 'store/user/user.actions'
import { createWebsocketConnection } from 'store/websocket/websocket.actions'
import { createWebSocketCompanionConnection } from 'store/websocketCompanion/websocketCompanion.actions'
import { paramsToQueryString } from 'utils'

import { buildQueryStringParameters } from './utils'

const LoggedInWrapper: React.FC<PropsWithChildren> = ({ children }) => {
    const dispatch = useDispatch()

    const config: IConfigCheck = { websocketEnabled: true }
    const hasWebSocketFeature = useHasFeature(config)
    const hasFeature = useHasFeature()
    const refreshTime = AppFeatures.CUSTOM_REFRESH_TIMER
    const crmCompanionFeature = AppFeatures.CRM_COMPANION

    const authenticated = useSelector((state: RootState) => state.auth.authenticated)
    const disableOnLeaveWarning = useSelector(
        (state: RootState) => state.app.appConfig.disableOnLeaveWarning,
    )
    const agentStatusType = useSelector((state: RootState) => state.user?.status.type)
    const websocketURL = useSelector((state: RootState) => state.app.appConfig.websocketURL)
    const companyID = useSelector((state: RootState) => state.app.ID)
    const tokenID = useSelector((state: RootState) => state.auth.token)
    const websocket = useSelector((state: RootState) => state.websocket)
    const websocketCompanion = useSelector((state: RootState) => state.websocketCompanion)
    const { client } = websocketCompanion || {}

    const refreshConfig = useGetFeatureConfig()(refreshTime)

    useInterval(
        () => {
            dispatch(refreshToken())
        },
        refreshConfig?.refreshInterval ?? 1000 * 14 * 60,
    )

    const handleLeaveConfirmation = (e: BeforeUnloadEvent) => {
        // Cancel the event
        e.preventDefault()
        // Some browsers require returnValue to be set. Most browsers will display their own message and ignore the following one.
        e.returnValue = 'Are you sure you want to leave?'
    }

    const handleSetInternetConnection = () => {
        dispatch(setInternetConnection(navigator.onLine))
    }

    const handleWebsocketConnection = () => {
        if (!websocketURL || !tokenID) return

        const queryStringParameters = buildQueryStringParameters(
            hasWebSocketFeature,
            companyID,
            tokenID,
        )

        dispatch(
            createWebsocketConnection({
                url: websocketURL,
                queryStringParameters,
                reconnectOnDisconnect: true,
            }),
        )
    }

    const handleWebsocketCompanionConnection = () => {
        if (!websocketURL || !tokenID) return
        if (hasFeature(crmCompanionFeature)) {
            const companionQueryStringParameters = paramsToQueryString({
                service: 'smartAgent',
                companyID,
                tokenID,
            })
            dispatch(
                createWebSocketCompanionConnection({
                    url: websocketURL,
                    queryStringParameters: companionQueryStringParameters,
                    reconnectOnDisconnect: true,
                    reconnectionTimeoutTimeMs: 1000,
                    keepAliveConfig: {
                        shouldSendKeepAlive: true,
                        keepAliveIntervalTimeMs: 5000,
                    },
                }),
            )
        }
    }

    useEffect(() => {
        if (disableOnLeaveWarning) return
        window.addEventListener('beforeunload', handleLeaveConfirmation)
        window.addEventListener('online', handleSetInternetConnection)
        window.addEventListener('offline', handleSetInternetConnection)

        handleWebsocketConnection()
        handleWebsocketCompanionConnection()

        return () => {
            if (disableOnLeaveWarning) return
            window.removeEventListener('beforeunload', handleLeaveConfirmation)
            window.removeEventListener('online', handleSetInternetConnection)
            window.removeEventListener('offline', handleSetInternetConnection)
        }
    }, [])

    // Update WS connection with latest token ID when token refreshses
    useEffect(() => {
        if (!tokenID) return

        if (websocket) {
            websocket.send('refreshToken', { tokenID })
            const queryStringParameters = buildQueryStringParameters(
                hasWebSocketFeature,
                companyID,
                tokenID,
            )
            websocket.updateParameters({ queryStringParameters })
        }

        if (hasFeature(crmCompanionFeature) && client) {
            const companionQueryStringParameters = paramsToQueryString({
                service: 'smartAgent',
                companyID,
                tokenID,
            })
            client.updateParameters({ queryStringParameters: companionQueryStringParameters })
        }
    }, [tokenID])

    // This event is dispatched so the recover functions get called only when the user has authenticated
    // This is because the recoverCall function now requires a token as it makes a call to the backend
    // Preferably the Connect middleware would be updated so it starts running after the user has authenticated
    useEffect(() => {
        if (authenticated) {
            dispatch(userAuthenticationHasSet())
        }
    }, [authenticated])

    return <>{children}</>
}

export default LoggedInWrapper
