import { jsx, Fragment, jsxs } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import { P as PulseLoader } from "./PulseLoader-ghZQ3xzD.mjs";
const HoldingStatusMessage = ({
  ariaId,
  text,
  companyLogoUrl,
  holdingText,
  showTypingIndicator,
  messageTime,
  ttl
}) => {
  const [isExpired, setIsExpired] = useState(false);
  const checkIfMessageIsExpired = (interval) => {
    if (!isExpired) {
      const expiryTime = new Date(messageTime).getTime() + ttl * 1e3;
      if (expiryTime < Date.now()) {
        setIsExpired(true);
        clearInterval(interval);
      }
    }
  };
  useEffect(() => {
    if (ttl && messageTime) {
      const updateMessageExpiredInterval = setInterval(
        () => checkIfMessageIsExpired(updateMessageExpiredInterval),
        1e3
      );
      checkIfMessageIsExpired(updateMessageExpiredInterval);
      return () => clearInterval(updateMessageExpiredInterval);
    }
  }, []);
  return /* @__PURE__ */ jsx(Fragment, { children: !isExpired ? /* @__PURE__ */ jsx("div", { id: ariaId, className: "holding-status-message", children: /* @__PURE__ */ jsxs("div", { className: "sa-chat-message-text-wrapper sa-chat-message-text-wrapper-holding", children: [
    !!companyLogoUrl && /* @__PURE__ */ jsx(
      "img",
      {
        className: "sa-chat-message-text-wrapper-company-logo",
        "aria-hidden": "true",
        src: companyLogoUrl,
        alt: ""
      }
    ),
    /* @__PURE__ */ jsxs("div", { className: "sa-chat-message-text-wrapper-inner", children: [
      holdingText ? /* @__PURE__ */ jsx("span", { className: "sa-chat-message-text-wrapper-holding-text", children: holdingText }) : null,
      showTypingIndicator ? /* @__PURE__ */ jsx(PulseLoader, { size: 10 }) : text ? /* @__PURE__ */ jsx("div", { className: "sa-chat-message-text sa-chat-message-text__agent", children: /* @__PURE__ */ jsx("p", { children: text }) }) : null
    ] })
  ] }) }) : null });
};
export {
  HoldingStatusMessage as default
};
