import './chat-controller.scss'

import useChatConnections from 'hooks/redux/useChatConnections'
import useContact from 'hooks/redux/useContact'
import useSettings from 'hooks/redux/useSettings'
import useContactLogForm from 'hooks/useContactLogForm'
import DeclineChatIcon from 'images/icon-chat-decline.svg'
import React from 'react'
import { useDispatch } from 'react-redux'
import { completeChatACW, endChat, extendChatACW } from 'store/chat/chat.actions'
import { updateContactAttributes } from 'store/contact/contact.actions'
import Contact from 'views/Home/Contact'
import QuickDialler from 'views/QuickDialler'

import {
    Box,
    Button,
    Tag,
    TimeCountdown,
    TimeElapsed,
} from '@missionlabs/smartagent-app-components'

import useACWTimeLeft from '../../../hooks/redux/useACWTimeLeft'

interface Props {}

const ChatController: React.FC<Props> = () => {
    const contact = useContact()
    const dispatch = useDispatch()
    const acwTimeLeft = useACWTimeLeft()
    const contactLog = useContactLogForm()
    const chat = useChatConnections('selected')
    const { settings } = useSettings()
    const settingsChatAppConfig = settings?.appConfigurations?.chat

    const handleExtendACWClick = () => {
        if (chat?.id && !chat.acwExtendedAt) {
            dispatch(extendChatACW(chat.id))
        }
    }

    const onTimeChange = (t: number) => {
        if (t <= 0) {
            dispatch(completeChatACW(chat!.id))
        }
    }

    const onEndChat = () => {
        if (chat?.id) {
            dispatch(updateContactAttributes(chat?.id, { 'sa-agent-hungup': 'yes' }))
            dispatch(endChat(chat?.id))
        }
    }

    const renderChatType = () => {
        if (!contact?.attributes?.['sa-social-media-platform']) return <p>In Chat</p>
        const attributes = contact.attributes
        return (
            <p className="chat-type">
                {attributes['sa-social-media-platform'].toLowerCase()}
                {!!attributes?.['sa-sub-channel'] && ` - ${attributes?.['sa-sub-channel']}`}
            </p>
        )
    }

    // show 'Extend' by default, only hide if this optional value is set & is false
    const isExtendHidden =
        settingsChatAppConfig?.isAfterCallWorkLimited === false ||
        settingsChatAppConfig?.allowAfterCallWorkExtension === false ||
        contactLog?.form?.extendWrapTime === false

    return (
        <Box className="sa-chat-controller sm-pad">
            {chat?.status === 'ended' && chat.acw && (
                <div className="row between">
                    <div>
                        <span className="title">{chat.customerName}</span>
                        <p>Wrapping up...</p>
                    </div>
                    <div className="sa-chat-acw-extend">
                        {!isExtendHidden && (
                            <Button
                                disabled={!!chat?.acwExtendedAt}
                                onClick={() => handleExtendACWClick()}
                            >
                                Extend
                            </Button>
                        )}
                        {settingsChatAppConfig?.isAfterCallWorkLimited === false ||
                        chat.acwExtendedAt ? (
                            <Tag small title="wrap up" type={'grey'}>
                                <TimeElapsed
                                    date={
                                        new Date(
                                            chat.acwExtendedAt || chat.acwStartedAt || Date.now(),
                                        )
                                    }
                                />
                            </Tag>
                        ) : (
                            acwTimeLeft && (
                                <div className="div sa-chat-acw-extend_limited">
                                    <TimeCountdown
                                        onChange={onTimeChange}
                                        startTime={acwTimeLeft}
                                    />
                                </div>
                            )
                        )}
                    </div>
                </div>
            )}

            {chat?.status === 'connected' && (
                <div className="row between">
                    <div>
                        <span className="title">{chat.customerName}</span>
                        {renderChatType()}
                    </div>
                    <div className="sa-chat-connected-buttons">
                        <QuickDialler channel="CHAT" />
                        <Button styling="red" className="chat-decline-btn" onClick={onEndChat}>
                            <img src={DeclineChatIcon} alt="" />
                        </Button>
                    </div>
                </div>
            )}

            {chat?.status === 'connecting' && (
                <div className="row between">
                    <div>
                        <span className="title">{chat.customerName}</span>
                        <p>Incoming Chat</p>
                    </div>
                </div>
            )}

            {!!contact && <Contact contact={contact} />}
        </Box>
    )
}

export default ChatController
