import type { Middleware } from '@reduxjs/toolkit'
import { AppFeatures, AppSubFeatures } from 'store/app/app.features'
import * as CallActions from 'store/call/call.actions'
import * as CallReducer from 'store/call/call.reducer'
import CallState from 'store/call/call.state'
import { createNotification } from 'store/notification/notification.actions'
import { Notification } from 'store/notification/notification.state'
import RootState from 'store/state'
import { userAuthenticated } from 'store/user/user.reducer'
import {
    hideWebHIDPairButton,
    setConnectedHeadset,
    setIgnoreDeviceSignals,
    setWebHIDInCall,
    showWebHIDPairButton,
} from 'store/webHID/webHID.actions'
import { IHIDHeadset, isAnyAction, listConnectedWebHIDHeadsets, canUserUseFeature} from 'utils'

import {
    CallControlFactory,
    IApi,
    ICallControl,
    init,
    RequestedBrowserTransport,
    SignalType,
} from '@gnaudio/jabra-js'

import { initAppSuccess } from '../app/app.reducer'

enum jabraSignalTypes {
    PHONE_MUTE = 'PHONE_MUTE',
    HOOK_SWITCH = 'HOOK_SWITCH',
    LINE_BUSY = 'LINE_BUSY',
    FLASH = 'FLASH',
}

let jabraAPIObj: IApi | undefined
let callControlFactory: CallControlFactory | undefined
let deviceCallControl: ICallControl | undefined

const tooManyDevicesNotification: Notification = {
    header: 'Warning',
    text: 'Multiple headsets have been detected. Using more than 1 device may result in undesired behaviour. Remove a device from your browser settings.',
    type: 'warning',
}

const jabraMiddleware: Middleware<{}, RootState> = (store) => (next) => (action) => {
    const {app, auth, settings} = store.getState()
    const hasFeature = canUserUseFeature(app.features, auth.features ?? [])
    const hasWebHIDControlPermission = hasFeature(AppFeatures.AUDIO_SETTINGS, [AppSubFeatures.SUPPORT_WEBHID_CONTROL])
    const enableWebHID = settings.userHasEnabledWebHID && hasWebHIDControlPermission;

    if (!isAnyAction(action)) return

    switch (action.type) {
        case initAppSuccess.type: {
            // init the new Jabra library and add logging
            init({
                partnerKey: '6f3c-aacb0337-a54f-4ee9-ad84-f7fcbdeeff28',
                appId: 'mission-labs-smartagent-app',
                appName: 'smartagent-app',
                // now only uses webHID which works natively in the latest version of chrome and allows call control without any additional browser plugins
                transport: RequestedBrowserTransport.WEB_HID,
                logger: {
                    write(logEvent) {
                        console.log(
                            'Jabra SDK log: ',
                            logEvent.message,
                            logEvent.layer,
                            logEvent.level,
                        )
                        if (
                            enableWebHID &&
                            logEvent.level === 'error' &&
                            typeof logEvent?.message === 'string'
                        ) {
                            // show a notification with any errors thrown by the Jabra library
                            store.dispatch(
                                createNotification({
                                    header: 'Error',
                                    text: `${logEvent.message}`,
                                    type: 'error',
                                }),
                            )
                        }
                    },
                },
            }).then(async (jabraAPI) => {
                try {
                    jabraAPIObj = jabraAPI
                    callControlFactory = new CallControlFactory(jabraAPIObj)

                    // ----------------------   listen for button presses on the hardware and trigger SmartAgent behaviour based on the button pressed ------------------------ //

                    jabraAPIObj.deviceList.subscribe(async (devices) => {
                        // subscribe to signals sent from the hardware device when buttons are pressed.
                        // These signals are also triggered without a button press, for example if the device is put into a call state using  deviceCallControl?.offHook(true) the HOOK_SWITCH signal will fire.
                        // this means some of these signals need to be ignored and will be ignored if  ignoreHardwareDeviceSignals is set to true in Redux

                        devices?.forEach(async (device) => {
                            deviceCallControl = await callControlFactory?.createCallControl(device)
                            // the call lock needs to be taken for call control actions like mute / unmute to work
                            await deviceCallControl?.takeCallLock()

                            deviceCallControl?.deviceSignals?.subscribe((signal) => {
                                const state = store.getState()
                                const isCallIncoming = !!state?.call?.incoming
                                const isInCall = !!state?.call?.inCall
                                const isMuted = !!state?.call?.muted
                                const call = state?.call as CallState
                                const connection = call?.connections?.find(
                                    (c) => c?.activeConnection,
                                )

                                const isInWebHIDCall = state?.webHIDState?.webHIDinCall
                                const ignoreHardwareDeviceSignals =
                                    state?.webHIDState?.ignoreDeviceSignals
                                const userHasEnabledWebHIDFeatures =
                                    state?.settings?.userHasEnabledWebHID
                                console.log('Jabra hardware Signal detected  ', {
                                    signalType: SignalType[signal.type],
                                })

                                switch (SignalType[signal.type]) {
                                    // change the SmartAgent state based on the hardware buttons that have been pressed
                                    case jabraSignalTypes.PHONE_MUTE:
                                        {
                                            if (userHasEnabledWebHIDFeatures === false) return
                                            if (!isInCall) return

                                            isMuted
                                                ? store.dispatch(CallActions.unmute())
                                                : store.dispatch(CallActions.mute())
                                        }
                                        break
                                    case jabraSignalTypes.FLASH:
                                        {
                                            // the flash is triggered if the call button on the device is pressed and held for a few seconds
                                            if (!call) return
                                            if (!connection) return
                                            if (userHasEnabledWebHIDFeatures === false) return

                                            connection.hold
                                                ? store.dispatch(CallActions.resume(connection.id))
                                                : store.dispatch(CallActions.hold(connection.id))
                                        }
                                        break
                                    case jabraSignalTypes.HOOK_SWITCH:
                                        {
                                            if (userHasEnabledWebHIDFeatures === false) return
                                            if (isCallIncoming) {
                                                //  if a call is inbound the hardware button will answer the call in SmartAgent
                                                store.dispatch(CallActions.acceptCall())
                                                deviceCallControl?.ring(false)
                                                store.dispatch(setWebHIDInCall(true))
                                            }
                                            if (
                                                !isCallIncoming &&
                                                isInCall &&
                                                isInWebHIDCall &&
                                                !ignoreHardwareDeviceSignals
                                            ) {
                                                // if the agent is currently on a call pressing the button on the hardware will end the call in SmartAgent
                                                store.dispatch(CallActions.endCall())
                                            }
                                            if (!isCallIncoming && isInCall && !isInWebHIDCall) {
                                                // if the agent is in a call set the webHID state to be in a call
                                                store.dispatch(setWebHIDInCall(true))
                                            }
                                        }
                                        break
                                }

                                // if ignoreDeviceSignals was set to true set it to false now so the next device signal will be registered correctly
                                store.dispatch(setIgnoreDeviceSignals(false))
                            })
                        })
                    })

                    // decide when to show or hide the WebHID banner to prompt the user to add a headset
                    jabraAPIObj?.deviceList.subscribe(async (devices) => {
                        if (devices?.length > 1) {
                            // display a warning on startup if there are too many devices active. The functionality will not work correctly if 2 headsets are attached and given permissions
                            store.dispatch(createNotification(tooManyDevicesNotification))
                            return
                        } else {
                            devices?.forEach((device) => {
                                if (enableWebHID) {
                                    store.dispatch(hideWebHIDPairButton())
                                }
                            })
                        }
                    })

                    let connectedHeadsetsOnStartup: IHIDHeadset[] | null = null

                    if (enableWebHID) {
                        // only check for WebHID Headsets if the user has webHID enabled in the company features
                        connectedHeadsetsOnStartup = await listConnectedWebHIDHeadsets()
                    }

                    jabraAPIObj?.deviceAdded.subscribe(async (device) => {
                        if (enableWebHID) {
                            const state = store.getState()
                            const deviceName = device?.name ?? ''

                            // this is the last headset connected to SmartAgent  stored in Redux
                            const lastConnectedHeadset = state.webHIDState?.connectedHeadset

                            if (
                                (!connectedHeadsetsOnStartup?.length && !lastConnectedHeadset) ||
                                (lastConnectedHeadset &&
                                    lastConnectedHeadset?.name !== device?.name)
                            ) {
                                // only show the success notification if this is the first device connected to SmartAgent since startup or if the device connected is a different device to the last device added stored in Redux.
                                //This stops the notification firing on Startup or when the headset is unplugged and plugged back in
                                store.dispatch(
                                    createNotification({
                                        header: 'Success',
                                        text: `Enhanced headset control is now active for your ${deviceName}`,
                                        type: 'success',
                                    }),
                                )
                            }

                            // save the latest device added as the connected device in Redux
                            const { id, name, serialNumber, vendorId } = device
                            store.dispatch(
                                setConnectedHeadset({ id, name, serialNumber, vendorId }),
                            )
                        }
                    })
                } catch (error) {
                    console.log('Error initializing the Jabra SDK library: ', error)
                }
            })

            return next(action)
        }

        case userAuthenticated.type: {
            if (jabraAPIObj && enableWebHID) {
                // check if there are any WebHID enabled devices currently connected to the browser. Show the popup banner if the company has webHID enabled and if no devices are found
                // get the list of devices straight from the browser api
                listConnectedWebHIDHeadsets()?.then((devices) => {
                    if (!devices?.length) {
                        store.dispatch(showWebHIDPairButton())
                    }
                    if (devices?.length > 0) {
                        store.dispatch(hideWebHIDPairButton())
                    }
                    if (devices?.length > 1) {
                        store.dispatch(createNotification(tooManyDevicesNotification))
                    }
                })
            }

            return next(action)
        }

        // ----------------------   listen for SmartAgent actions and trigger behaviour on the hardware. Change the colour of the lights etc   ------------------------ //

        case CallReducer.incomingCall.type: {
            const globalState = store.getState()
            const userHasEnabledWebHID = globalState?.settings?.userHasEnabledWebHID
            jabraAPIObj?.deviceList.forEach(async (devices) => {
                devices?.forEach(async (device) => {
                    if (!userHasEnabledWebHID === true) return

                    try {
                        deviceCallControl?.ring(true)
                    } catch (error) {
                        console.log(
                            'Error using call control in the Jabra library at INCOMING_CALL ',
                            error,
                        )
                    }
                })
            })

            return next(action)
        }
        case CallReducer.callStarted.type: {
            const globalState = store.getState()
            const userHasEnabledWebHID = globalState?.settings?.userHasEnabledWebHID

            jabraAPIObj?.deviceList?.forEach(async (devices) => {
                devices?.forEach(async (device) => {
                    if (!deviceCallControl || !userHasEnabledWebHID === true) return

                    try {
                        deviceCallControl?.offHook(true)
                        deviceCallControl?.ring(false)
                    } catch (error) {
                        console.log(
                            'Error using call control in the Jabra library at CALL_STARTED ',
                            error,
                        )
                    }
                })
            })

            return next(action)
        }
        case CallReducer.mute.type: {
            const globalState = store.getState()
            const userHasEnabledWebHID = globalState?.settings?.userHasEnabledWebHID

            jabraAPIObj?.deviceList.forEach(async (devices) => {
                devices?.forEach(async (device) => {
                    if (!deviceCallControl || !userHasEnabledWebHID === true) return

                    try {
                        deviceCallControl?.mute(true)
                    } catch (error) {
                        console.log('Error using call control in the Jabra library at MUTE ', error)
                    }
                })
            })

            return next(action)
        }
        case CallReducer.unmute.type: {
            const globalState = store.getState()
            const userHasEnabledWebHID = globalState?.settings?.userHasEnabledWebHID

            jabraAPIObj?.deviceList?.forEach(async (devices) => {
                if (!deviceCallControl || !devices || !userHasEnabledWebHID === true) return

                devices?.forEach(async (device) => {
                    try {
                        deviceCallControl?.mute(false)
                    } catch (error) {
                        console.log(
                            'Error using call control in the Jabra library at UNMUTE ',
                            error,
                        )
                    }
                })
            })

            return next(action)
        }

        case CallReducer.callEnded.type: {
            const globalState = store.getState()
            const userHasEnabledWebHID = globalState?.settings?.userHasEnabledWebHID

            jabraAPIObj?.deviceList?.forEach(async (devices) => {
                if (!deviceCallControl || !devices || !userHasEnabledWebHID === true) return

                devices?.forEach(async (device) => {
                    try {
                        deviceCallControl?.ring(false)
                        deviceCallControl?.offHook(false)
                        store.dispatch(setWebHIDInCall(false))
                        store.dispatch(setIgnoreDeviceSignals(false))
                    } catch (error) {
                        console.log(
                            'Error using call control in the Jabra library at CALL_ENDED ',
                            error,
                        )
                    }
                })
            })

            return next(action)
        }

        case CallReducer.hold.type: {
            const globalState = store.getState()
            const userHasEnabledWebHID = globalState?.settings?.userHasEnabledWebHID

            jabraAPIObj?.deviceList?.forEach(async (devices) => {
                if (!deviceCallControl || !devices) return

                devices?.forEach(async (device) => {
                    if (!deviceCallControl || !userHasEnabledWebHID === true) return

                    try {
                        deviceCallControl?.ring(false)
                        deviceCallControl?.hold(true)
                        deviceCallControl?.offHook(false)
                    } catch (error) {
                        console.log('Error using call control in the Jabra library at HOLD ', error)
                    }
                })
                store.dispatch(setIgnoreDeviceSignals(true))
            })

            return next(action)
        }
        case CallReducer.resume.type: {
            const globalState = store.getState()
            const userHasEnabledWebHID = globalState?.settings?.userHasEnabledWebHID

            jabraAPIObj?.deviceList?.forEach(async (devices) => {
                if (!deviceCallControl || !devices || !userHasEnabledWebHID === true) return

                devices?.forEach(async (device) => {
                    try {
                        deviceCallControl?.ring(false)
                        deviceCallControl?.hold(false)
                        deviceCallControl?.offHook(true)
                    } catch (error) {
                        console.log(
                            'Error using call control in the Jabra library at RESUME ',
                            error,
                        )
                    }
                })
                store.dispatch(setIgnoreDeviceSignals(true))
            })

            return next(action)
        }

        default:
            return next(action)
    }
}

export default jabraMiddleware
