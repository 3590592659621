import { useRef } from 'react'

export const useFocusElement = <T extends HTMLElement = HTMLElement>() => {
    const focusElementRef = useRef<T>(null)

    const onFocusElement = () => {
        if (!focusElementRef.current) return
        focusElementRef.current.focus()
    }
    return {
        focusElementRef,
        onFocusElement,
    }
}
