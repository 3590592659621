import { AlertBanner, Button, Dropdown } from '@missionlabs/smartagent-app-components'
import { SADropdownOption } from '@missionlabs/smartagent-app-components/dist/Dropdown'
import { H, Level } from 'react-accessible-headings'
import React, { useEffect, useState } from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'

import { AppFeatures, useCompanyHasFeature } from 'hooks/useHasFeature'
import { initApp, selectInstance } from 'store/app/app.actions'
import { Instance } from 'store/app/app.state'
import { setSaIsAlreadyOpen } from 'store/global/global.actions'
import RootState from 'store/state'
import { login } from 'store/user/user.actions'
import { saAlreadyOpen, setOneTabSessionExpirationItem } from 'utils'
import packageJson from '../../../package.json'
import LoginExternal from './LoginExternal'
import LoginSplash from './LoginSplash'
import LoginToken from './LoginToken'
import LoginUsernamePassword from './LoginUsernamePassword'
import ThirdPartyCookieInfo from './ThirdPartyCookieInfo'

import updateDocumentTitle from 'utils/updateDocumentTitle'
import Logger from '../../logger'
import './login.scss'
import Logo from './smartagent.png'

interface Props {}

enum ThirdPartyCookieResponse {
    unsupported = '3PCunsupported',
    supported = '3PCsupported',
}

const thirdPartyCookieResponseValues = new Set(Object.values(ThirdPartyCookieResponse))

const thirdPartyCookieIframeId = 'third-party-cookie-checker'

const Login: React.FC<Props> = () => {
    updateDocumentTitle('Login - SmartAgent')
    const identityManagement = useSelector((state: RootState) => state.app.identityManagement)
    const cognito = useSelector((state: RootState) => state.app.cognito)
    const instances = useSelector((state: RootState) => state.app.instances)
    const appInstance = useSelector((state: RootState) => state.app.instance)
    const companyID = useSelector((state: RootState) => state.app.ID)
    const companyGroups = useSelector((state: RootState) => state.app.companyGroups)
    const loading = useSelector((state: RootState) => state.app.loading)
    const appConfig = useSelector((state: RootState) => state.app.appConfig)
    const saIsAlreadyOpen = useSelector((state: RootState) => state.global.saIsAlreadyOpen)

    const [instance, setInstance] = useState<Instance>(instances[0])
    const [company, setCompany] = useState<any>(undefined)
    const [thirdPartyCookiesCheckLoading, setThirdPartyCookiesCheckLoading] =
        useState<boolean>(false)
    const [thirdPartyCookiesEnabled, setThirdPartyCookiesEnabled] = useState<boolean>(false)
    const [instructionsCompleted, setInstructionsCompleted] = useState<boolean>(false)

    const hasFeature = useCompanyHasFeature()
    const disableCookieCheck = hasFeature(AppFeatures.DISABLE_COOKIE_CHECK)

    let thirdPartyCookieTimeout: NodeJS.Timeout | undefined

    const thirdPartyCookiesAlertBannerType = thirdPartyCookiesEnabled ? 'success' : 'error'
    const thirdPartyCookiesAlertBannerText = thirdPartyCookiesEnabled
        ? 'Your cookies have been set up correctly!'
        : "Sorry, the required cookies can't be detected. Please try again"

    const dispatch = useDispatch()

    const getLoginType = () => {
        return !identityManagement || identityManagement === 'connect' || loading ? (
            <LoginToken />
        ) : identityManagement === 'cognito' && cognito?.type === 'in_app' ? (
            <LoginUsernamePassword />
        ) : (
            <LoginExternal />
        )
    }

    const chooseInstance = (): void => {
        const url = `${window.location.search}&instanceKey=${instance.key}`

        window.history.pushState('', '', url)

        dispatch(selectInstance(instance, appConfig))

        if (!identityManagement || identityManagement === 'connect') {
            dispatch(login())
        }
    }

    const chooseCompany = (): void => {
        const s = window.location.search
        const url = `${s ? s + '&' : '?'}groupCompany=${company.ID}`

        window.history.pushState('', '', url)

        if (saIsAlreadyOpen === false) {
            dispatch(initApp())
        }
    }

    const optioniseInstances = (): SADropdownOption[] => {
        return instances.map((instance) => ({
            label: instance.name,
            data: instance,
        }))
    }

    const optioniseCompanies = (): SADropdownOption[] => {
        return companyGroups!.map((company) => ({
            label: company.ID,
            data: company,
        }))
    }

    const thirdPartyCookieMessageListener = (event: MessageEvent): void => {
        if (!thirdPartyCookieResponseValues.has(event.data)) return

        console.info('Response from third party cookie check:', event.data)

        const thirdPartyCookiesEnabled = event.data === ThirdPartyCookieResponse.supported

        batch(() => {
            setThirdPartyCookiesEnabled(thirdPartyCookiesEnabled)
            setThirdPartyCookiesCheckLoading(false)
        })

        thirdPartyCookieTimeout && clearTimeout(thirdPartyCookieTimeout)

        thirdPartyCookieCleanup()
    }

    const thirdPartyCookieCleanup = (): void => {
        try {
            const iframe = document.getElementById(thirdPartyCookieIframeId)
            if (iframe && iframe.parentNode) {
                document.body.removeChild(iframe)
            }
            window.removeEventListener('message', thirdPartyCookieMessageListener)
        } catch (e) {
            Logger.error('COOKIE-CLEANUP-ERROR', e)
            console.error('Error cleaning up third party cookie check:', e)
        }
    }

    const thirdPartyCookieCheck = (): void => {
        setThirdPartyCookiesCheckLoading(true)

        // cookies in general aren't enabled - no point checking third party cookies, exit early
        if (!navigator.cookieEnabled) {
            batch(() => {
                setThirdPartyCookiesEnabled(false)
                setThirdPartyCookiesCheckLoading(false)
            })
            return
        }

        // check if current browser allows third party cookies via embedding external domain
        window.addEventListener('message', thirdPartyCookieMessageListener)
        const iframe = document.createElement('iframe')
        iframe.style.display = 'none'
        iframe.id = thirdPartyCookieIframeId
        iframe.src = 'https://cookie.smartagent.io/index.html'
        document.body.appendChild(iframe)

        // if still waiting for a response after 5 seconds, assume third party cookies are disabled
        thirdPartyCookieTimeout = setTimeout(() => {
            if (thirdPartyCookiesCheckLoading) {
                console.info('No response received from third party cookie check.')

                batch(() => {
                    setThirdPartyCookiesEnabled(false)
                    setThirdPartyCookiesCheckLoading(false)
                })

                thirdPartyCookieCleanup()
            }
        }, 5000)
    }

    useEffect(() => {
        if (saAlreadyOpen()) {
            dispatch(setSaIsAlreadyOpen(true))
        } else {
            setOneTabSessionExpirationItem()
            dispatch(setSaIsAlreadyOpen(false))
        }

        thirdPartyCookieCheck()

        return () => {
            thirdPartyCookieCleanup()
        }
    }, [])
    return (
        <main className="sa-login">
            <div className="panel sa-login-left">
                <div className="sa-login-logo">
                    <H>
                    <img width="230" src={Logo} alt="Smart Agent" title="Smart Agent" />
                    </H>
                </div>
                <Level>
                    <div className="sa-login-body">
                        <div className="sa-login-body--alerts">
                            {instructionsCompleted && !thirdPartyCookiesCheckLoading && (
                                <AlertBanner type={thirdPartyCookiesAlertBannerType}>
                                    {thirdPartyCookiesAlertBannerText}
                                </AlertBanner>
                            )}
                        </div>
                        {saIsAlreadyOpen ? (
                            <AlertBanner>
                                <H>SmartAgent is already open in another window.</H>
                                <br />
                                Please switch back to that window to continue using SmartAgent.
                                <br />
                                Alternatively, please close the other window and reload to use
                                SmartAgent here.
                            </AlertBanner>
                        ) : !companyID && companyGroups?.length ? (
                            <div className="row column auto">
                                <p>Let's get started</p>
                                <Dropdown
                                    className="md-mar-vertical"
                                    label="Select Company"
                                    options={optioniseCompanies()}
                                    onChange={(option) => setCompany(option.data)}
                                    value={company?.ID}
                                />
                                <Button large round styling="primary" onClick={chooseCompany}>
                                    OK
                                </Button>
                            </div>
                        ) : loading ? (
                            <LoginSplash loading />
                        ) : !disableCookieCheck && !thirdPartyCookiesEnabled ? (
                            <ThirdPartyCookieInfo
                                onVerify={() => {
                                    setInstructionsCompleted(true)
                                    thirdPartyCookieCheck()
                                }}
                                verifying={thirdPartyCookiesCheckLoading}
                                error={instructionsCompleted && !thirdPartyCookiesCheckLoading}
                            />
                        ) : appInstance ? (
                            getLoginType()
                        ) : (
                            instances.length > 1 && (
                                <div className="row column auto">
                                    <p>Let's get started</p>
                                    <Dropdown
                                        // eslint-disable-next-line jsx-a11y/no-autofocus
                                        autoFocus
                                        className="md-mar-vertical"
                                        label="Select Contact Centre"
                                        options={optioniseInstances()}
                                        onChange={(option) => setInstance(option.data)} //this.setState({ instance: option.data })}
                                        value={instance && instance.name}
                                    />
                                    <Button large round styling="primary" onClick={chooseInstance}>
                                        OK
                                    </Button>
                                </div>
                            )
                        )}
                    </div>
                </Level>
            </div>

            <div className="panel sa-login-right">
                <div className="sa-login-slogan">
                    <p>
                        <span>Your contact centre,</span>
                        <span>reimagined.</span>
                    </p>
                </div>
            </div>
            {identityManagement && identityManagement !== 'connect' && (
                <iframe
                    title="SmartAgent Login"
                    src={(process.env.BASE_PATH || '') + '/iframe.html'}
                    id="sa-login-iframe"
                    className="sa-login-iframe"
                />
            )}

            <span className="version">{packageJson.version}</span>
        </main>
    )
}

export default Login
