import './audio-player.scss'

import { Box, Dropdown, formatDateTime } from '@missionlabs/smartagent-app-components'
import axios from 'axios'
import useSize from 'hooks/useSize'
import DownloadIcon from 'images/icon-download.svg'
import React, { useEffect, useRef, useState } from 'react'
import { ClipLoader } from 'react-spinners'
import { IContactContext, withContactConsumerContext } from 'widgets/contexts/contact'
import { Audio, AudioErrorWithStatusCode } from './Audio'
import { Controls } from './Controls'
import { Footer } from './Footer'
import { Seeker } from './Seeker'
import { Track } from './Track'
import './audio-player.scss'
import { H } from 'react-accessible-headings'

export interface AudioPlayerError extends AudioErrorWithStatusCode {}

export interface IWaveformChannel {
    data: number[]
    max: number
}

export interface IWaveformData {
    time: number
    channel1: IWaveformChannel
    channel2: IWaveformChannel
}

export interface ICallRecordingData {
    signedUrl: string
    waveform: IWaveformData | null
}

export interface Props extends ICallRecordingData {
    context: IContactContext
    canDownload: boolean
    onAudioError: (err: any) => void
    contactID?: string
    recordingDate?: number
    widgetTitle?: string
}

interface DateTimeOptions {
    date?: boolean
    time?: boolean
}

const playbackRateOptions = [
    { label: 'Speed: 0.5x', data: '0.5' },
    { label: 'Speed: 1x', data: '1.0' },
    { label: 'Speed: 2x', data: '2.0' },
    { label: 'Speed: 3x', data: '3.0' },
]
// default 1x
const defaultSelectedPlaybackOption = playbackRateOptions[1]

const formatDateTimeWithUnderscore = (date: number | Date, options?: DateTimeOptions): string => {
    return formatDateTime(date, options).replace(' ', '_')
}

export const _AudioPlayer: React.FC<Props> = ({
    signedUrl,
    waveform: _waveform,
    context,
    canDownload,
    onAudioError,
    contactID,
    recordingDate,
    widgetTitle,
}) => {
    const [waveform] = useState(JSON.parse(_waveform as unknown as string))
    const { channel1, channel2 } = waveform ?? {}

    const [playing, setPlaying] = useState(false)
    const [ch1Muted, setCh1Muted] = useState(false)
    const [ch2Muted, setCh2Muted] = useState(false)
    const [elapsed, setElapsed] = useState(0)
    const [downloadingAudioFile, setDownloadingAudioStatus] = useState(false)
    const [downloadProgressAmount, setDownloadProgressAmount] = useState(0)
    const [currentPlaybackOption, setCurrentPlaybackOption] = useState(
        defaultSelectedPlaybackOption,
    )
    // Don't use setSeek directly, use audio.current.seek()
    const [seek, setSeek] = useState(0)

    const { size: trackSize, ref } = useSize<HTMLDivElement>(true)

    const audio = useRef<Audio>(null)

    const time = waveform?.time ?? audio.current?.getDuration() ?? 0

    const onSeek = async (
        event: React.MouseEvent | React.KeyboardEvent,
        shouldSeek: boolean = true,
    ) => {
        let newProgressPercentage = 0
        if ('button' in event) {
            const muteButtonWidth = 50
            const paddingOnAudio = 12

            const selectedXCoord =
                event.clientX - (trackSize.offsetLeft + muteButtonWidth + paddingOnAudio)
            const widthOfAudioBar = trackSize.width - muteButtonWidth - paddingOnAudio * 2

            newProgressPercentage = (selectedXCoord / widthOfAudioBar) * 100
        } else if ('key' in event) {
            switch (event.code) {
                case 'ArrowLeft':
                    if (event.metaKey) newProgressPercentage = 0
                    else newProgressPercentage = seek - 5
                    break
                case 'ArrowRight':
                    if (event.metaKey) newProgressPercentage = 100
                    else newProgressPercentage = seek + 5
                    break
                case 'Home':
                    newProgressPercentage = 0
                    break
                case 'End':
                    newProgressPercentage = 100
                    break
                case 'Enter':
                case 'Space':
                    await audio.current?.playPause()
                    return
            }
        }
        if (newProgressPercentage <= 0) newProgressPercentage = 0
        if (newProgressPercentage > 100) newProgressPercentage = 100

        if (shouldSeek) audio.current?.seek(newProgressPercentage)

        return newProgressPercentage
    }

    const muteChannel = (ch: number) => {
        if (ch === 1) {
            return ch1Muted ? audio.current?.unmuteChannel(1) : audio.current?.muteChannel(1)
        }
        if (ch === 2) {
            return ch2Muted ? audio.current?.unmuteChannel(2) : audio.current?.muteChannel(2)
        }
    }

    const resetDownloadProgress = () => {
        setDownloadingAudioStatus(false)
        setDownloadProgressAmount(0)
    }

    const handleAudioDownload = async (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()

        if (downloadingAudioFile) return

        try {
            setDownloadingAudioStatus(true)
            let progressAmount = 0
            // download the audio file as a blob from the signedUrl
            const audioFileResponse = await axios.get(signedUrl, {
                responseType: 'blob',
                onDownloadProgress: (progressEvent) => {
                    const percent = Math.floor((progressEvent.loaded / progressEvent.total) * 100)
                    // only update state every time progress has increased by 1% to stop unnecessary
                    if (percent >= progressAmount + 1) {
                        progressAmount = percent
                        setDownloadProgressAmount(percent)
                    }
                },
            })

            // create a more readable filename for the recording with the contact id and date
            const dateString = recordingDate
                ? formatDateTimeWithUnderscore(recordingDate, { date: true, time: true })
                : ''
            const fileName = `contact_${contactID}__time_${dateString}`

            // get the browser to download the file for the user with the new filename
            const url = window.URL.createObjectURL(new Blob([audioFileResponse.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `${fileName}.wav`)
            link.click()

            resetDownloadProgress()
        } catch (err) {
            console.log(err)
            window.open(signedUrl, '_blank')
            resetDownloadProgress()
        }
    }

    useEffect(() => {
        const seekToSeconds = (s: number) => audio.current?.seek((s / time!) * 100)
        if (context.seekTo) seekToSeconds(context.seekTo)
    }, [context.seekTo, time])

    //reset settings when navigating to a different recording
    useEffect(() => {
        return () => {
            setCurrentPlaybackOption(defaultSelectedPlaybackOption)
        }
    }, [audio.current?.props?.url]) // urls are unique which allows us to force re-render when recording changes

    const playbackRateOnChange = (option: any) => {
        setCurrentPlaybackOption(option)
        audio.current?.setPlaybackRate(parseFloat(option.data))
    }

    const headerTitle = widgetTitle ? widgetTitle : 'Call recording'

    return (
        <Box
            className="audio-player"
            alt
            collapse
            boxLabel="Call recording"
            header={
                <div className="row middle evenly grow">
                    <H>{headerTitle}</H>
                    <Controls
                        elapsed={elapsed}
                        isPlaying={playing}
                        total={!isNaN(time) ? time : 0}
                        playPause={async () => await audio.current?.playPause()}
                    />
                    <Dropdown
                        options={playbackRateOptions}
                        onChange={playbackRateOnChange}
                        value={currentPlaybackOption?.label || ''}
                    />

                    {canDownload ? (
                        <a
                            className={`sa-audio-download lg-pad-right row middle ${downloadingAudioFile ? 'is-downloading' : ''}`}
                            href={signedUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            download
                            onClick={(e) => handleAudioDownload(e)}
                        >
                            {downloadingAudioFile ? (
                                <>
                                    <span className="sa-audio-download-spinner">
                                        <ClipLoader color="#fff" size={15} />
                                    </span>
                                    <span className="sa-audio-downloading-text">
                                        {downloadProgressAmount}%
                                    </span>
                                </>
                            ) : (
                                <>
                                    <img src={DownloadIcon} alt="Download" title="Download" />
                                    Download
                                </>
                            )}
                        </a>
                    ) : null}
                </div>
            }
        >
            <div ref={ref} className="sa-audioplayer">
                <Seeker to={seek} onSeek={onSeek} />

                <Track
                    channel={channel1}
                    currentTimePercentage={seek}
                    currentTimeSeconds={elapsed}
                    maxTimeSeconds={time}
                    id={1}
                    muted={ch1Muted}
                    muteChannel={muteChannel}
                    onSeek={onSeek}
                    width={trackSize.width}
                    isPlaying={playing}
                />

                <Track
                    channel={channel2}
                    currentTimePercentage={seek}
                    currentTimeSeconds={elapsed}
                    maxTimeSeconds={time}
                    id={2}
                    muted={ch2Muted}
                    muteChannel={muteChannel}
                    onSeek={onSeek}
                    width={trackSize.width}
                    isPlaying={playing}
                />
                <Footer time={time ?? 0} />
            </div>

            <Audio
                time={time}
                url={signedUrl}
                ref={audio}
                context={context}
                stateFns={{ setSeek, setCh1Muted, setCh2Muted, setPlaying, setElapsed }}
                onError={onAudioError}
            />
        </Box>
    )
}

export const AudioPlayer = withContactConsumerContext(_AudioPlayer)
