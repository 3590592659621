import classNames from 'classnames'
import React from 'react'

import { Box, Popup, Table } from '@missionlabs/smartagent-app-components'
import type { IContactAudit } from '@missionlabs/smartagent-service-contact/dist/lib/entities/contact-audit'
import type { IAutomatedActionAudit } from '@missionlabs/smartagent-service-thread/dist/entities/action-audit'
import { H, Level } from 'react-accessible-headings'

import { automatedRulesColumns, queuedTasksColumns } from './columns'

import './task-audit-dialog.scss'

export interface Props {
    automatedRulesAudit: IAutomatedActionAudit[]
    queuedTasksAudit: IContactAudit[]
    hasAutoReply: boolean
    setOpenTasksAuditPopup: (isOpen: boolean) => void
}

const TaskAuditDialog: React.FC<Props> = ({
    automatedRulesAudit,
    queuedTasksAudit,
    hasAutoReply,
    setOpenTasksAuditPopup,
}) => {
    return (
        <Popup
            center
            className="task-audit-dialog"
            close
            closeOnClickOutside
            onClose={() => setOpenTasksAuditPopup(false)}
        >
            <Level value={2}>
                <H className="task-audit-dialog-header">Email audit</H>
                <Level>
                    <Box
                        alt
                        className="task-audit-dialog-box"
                        header={<H>Automated rules ({automatedRulesAudit.length})</H>}
                    >
                        <Table
                            caption="Automated rules audit"
                            cols={automatedRulesColumns}
                            data={automatedRulesAudit}
                            noData="No automated rules audit data available"
                        />
                    </Box>
                    <Box
                        alt
                        className="task-audit-dialog-box"
                        header={<H>Queued tasks ({queuedTasksAudit.length})</H>}
                    >
                        <Table
                            caption="Queued tasks audit"
                            cols={queuedTasksColumns}
                            data={queuedTasksAudit}
                            noData="No queued tasks audit data available"
                        />
                    </Box>
                    <strong>Automatic replies:</strong>{' '}
                    <span
                        data-testid="autoreply-indicator"
                        className={classNames({
                            'task-audit-dialog-autoreply-indicator': true,
                            'task-audit-dialog-autoreply-indicator-sent': hasAutoReply,
                        })}
                    >
                        {hasAutoReply ? 'Sent' : 'N/A'}
                    </span>
                </Level>
            </Level>
        </Popup>
    )
}

export default TaskAuditDialog
