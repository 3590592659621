import QuickReplies from 'components/QuickReplies'
import { ContentState, convertToRaw, EditorState, getDefaultKeyBinding } from 'draft-js'
import useChatConnections from 'hooks/redux/useChatConnections'
import useContact from 'hooks/redux/useContact'
import useHasFeature, { AppFeatures } from 'hooks/useHasFeature'
import { draftToMarkdown } from 'markdown-draft-js'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { SyntheticKeyboardEvent } from 'react-draft-wysiwyg'
import { useDispatch } from 'react-redux'
import { ClipLoader } from 'react-spinners'
import {
    chatSendTypingEvent,
    clearUnread,
    sendChatMessage,
    setChatReplyText,
    updateConnectionEditorContent,
    updateSocialEditorContent
} from 'store/chat/chat.actions'
import { getReplacedQuickReplayText } from 'utils/getReplacedQuickReplayText'

import { Button, WYSIWYG } from '@missionlabs/smartagent-app-components'

import attachmentIcon from './attachment.svg'
import SocialChatReplier from './SocialChatReplier'
import useSendAttachment from './useSendAttachment'
import useSocialChatReplyFunctionality from './useSocialChatReplyFunctionality'

interface Props {}

export const CHAT_ATTACHMENT_REGEX =
    /@SA_IMAGE\[([[\w.\-_#@!£$%|^"':;+={}?\(\)\[\] ]+~[\w\\\/]+~\w+(.*)+\])+/gm

const ChatReplier: React.FC<Props> = () => {
    const [isShowWarningMessage, setIsShowWarningMessage] = useState<boolean>(false)
    const [isQuickReplyOn, setIsQuickReplyOn] = useState<boolean>(false)
    const [messageSent, setMessageSent] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const dispatch = useDispatch()
    const hasFeature = useHasFeature()

    const chat = useChatConnections('selected')

    const content = useRef<string>('')
    const currentChatId = useRef<string>('')

    if (!chat) return null

    const { currentEditorContent, id: chatId, currentSocialEditorContent } = chat

    useEffect(() => {
        const content = currentEditorContent.getCurrentContent()
        if (loading && !content.hasText() && chat?.attachments?.files.length === 0)
            setLoading(false)
    }, [chat?.attachments?.files, loading, currentEditorContent])

    useEffect(() => {
        currentChatId.current = chat.id
    }, [chat])

    const {
        inputRef,
        handleUpload,
        dragEvents,
        uploadStagedAttachments,
        files,
        deleteFile,
        attachmentEnabled,
    } = useSendAttachment(currentEditorContent)
    const contact = useContact()
    const dFile = (i: number, fileName: string, editorState: EditorState) => {
        deleteFile(i, fileName, content.current || '', currentChatId.current)
    }

    useEffect(() => {
        if (contact) {
            const replyText = currentEditorContent.getCurrentContent().getPlainText()
            const { variablesWithBrackets, variablesEqualToAttributes } =
                getReplacedQuickReplayText(replyText, contact)
            const isAllVariablesEqualToAttributes = variablesEqualToAttributes.every((el) => el)
            const isShowWarning =
                isQuickReplyOn &&
                !isShowWarningMessage &&
                variablesWithBrackets.length &&
                !isAllVariablesEqualToAttributes
            isShowWarning && setIsShowWarningMessage(true)
        }
        onEditorStateChange(currentEditorContent)
    }, [currentEditorContent?.getCurrentContent()?.getPlainText()])

    useEffect(() => {
        if (chat?.reply?.text && contact) {
            const replyText = chat?.reply?.text
            const currentContent = currentEditorContent?.getCurrentContent().getPlainText('\u0001')
            const { textReplacedWithVariable } = getReplacedQuickReplayText(replyText, contact)
            const replacedReplyText = textReplacedWithVariable || replyText

            if (
                chat?.socialMediaPlatform &&
                chat.socialMediaPlatform !== 'WHATSAPP'
            ) {
                dispatch(updateSocialEditorContent({ id: chatId, 
                    content: currentSocialEditorContent.length === 0 
                    ? replacedReplyText 
                    : `${currentSocialEditorContent} ${replacedReplyText}` 
                }))
            } else {
                onEditorStateChange(
                    EditorState.createWithContent(
                        currentContent?.length === 0
                            ? ContentState.createFromText(replacedReplyText)
                            : ContentState.createFromText(`${currentContent} ${replacedReplyText}`),
                    ),
                )
            }
        }
    }, [chat?.reply?.text])

    const sendMessage = useCallback(async () => {
        setLoading(true)
        const content = currentEditorContent.getCurrentContent()

        const rawObject = convertToRaw(content)
        const markdown = draftToMarkdown(rawObject, {
            preserveNewlines: true,
        })

        // the following lines are a workaround to get the react markdown component to render empty line breaks as the draftToMarkdown doesn't seem to handle this natively.
        let constructedString = ``
        let lastLineWasList = false

        markdown.split('\n').forEach((x, i) => {
            if (x) {
                constructedString += `${x}\n`
            } else {
                constructedString += `\n${lastLineWasList ? '' : '  '}\n`
            }
            // there appears to be a bug in the react-markdown where lists are not exited. the lastLineWasList variable mitigates for that and ensures correct styling is maintained.
            lastLineWasList =
                x?.startsWith('- ') || (!isNaN(Number(x?.[0])) && x[1] === '.' && x[2] === ' ')
        })
        if (constructedString.length > 1024 && chat.socialMediaPlatform === 'WHATSAPP') return
        
        if (files?.length) {
            inputRef?.current && (inputRef.current.value = '')
            constructedString = constructedString.replaceAll(CHAT_ATTACHMENT_REGEX, '')   
            uploadStagedAttachments(constructedString)
        }
        dispatch(sendChatMessage({ id: chat!.id, msg: constructedString, clearEditor: true }))
        setMessageSent(true)
        dispatch(clearUnread(chat.id))
        setTimeout(() => {
            setReply({ isProbablyJson: false, text: '' }), 50
        })
        setIsShowWarningMessage(false)
    }, [files, currentEditorContent, loading])

    const setReply = useCallback(
        (args: { text: string; isProbablyJson: boolean }) => {
            dispatch(
                setChatReplyText({
                    id: chat!.id,
                    text: args.text,
                    isProbablyJson: args.isProbablyJson,
                }),
            )
        },
        [dispatch, chat],
    )

    const shouldDisableSendButton = useMemo(() => {
        return (
            chat.socialMediaPlatform === 'WHATSAPP' &&
            currentEditorContent?.getCurrentContent()?.getPlainText('\u0001')?.length > 1023
        )
    }, [chat.socialMediaPlatform, chat.currentEditorContent])

    const onEditorStateChange = (editorState: EditorState) => {
        content.current = draftToMarkdown(convertToRaw(editorState.getCurrentContent()))
        dispatch(updateConnectionEditorContent({ id: chatId, content: editorState }))
        setIsShowWarningMessage(false)
    }

    useEffect(() => {
        document.getElementsByClassName('sa-wysiwyg-toolbar')[0]?.setAttribute('tabindex', '0')
        // document.getElementsByClassName('sa-wysiwyg-toolbar__quick-replies')[0].setAttribute('tabindex', '0')
        document.getElementsByClassName('sa-wysiwyg-send')[0]?.setAttribute('tabindex', '0')
        const buttons = document.getElementsByClassName('rdw-option-wrapper')
        for (let i = 0; i < buttons.length; i++) {
            buttons[i]?.setAttribute('tabindex', '0')
        }
    }, [])

    const toolbarButtons = useMemo(() => {
        const buttons: React.ReactElement[] = []

        if (attachmentEnabled) {
            buttons.push(
                <Button
                    className="rdw-option-wrapper"
                    key={0}
                    title="Add attachment"
                    onClick={() => inputRef?.current?.click()}
                    disabled={loading && chat?.attachments?.files.length > 0}
                >
                    <img src={attachmentIcon} alt="" />
                    <input ref={inputRef} multiple type="file" hidden onChange={handleUpload} />
                </Button>,
            )
        }
        return buttons.concat([
            <Button
                onClick={sendMessage}
                disabled={shouldDisableSendButton || loading}
                key={1}
                className="sa-wysiwyg-send"
            >
                {loading ? <ClipLoader size={25} color="#9fabb8" /> : 'Send'}
            </Button>,
            <QuickReplies
                key={2}
                channel="Chat"
                analyticsEnabled={true}
                messageSent={messageSent}
                qrChannel={chat.channel || chat.socialMediaPlatform || 'WEBCHAT'}
                setMessageSent={setMessageSent}
                setIsQuickReplyOn={setIsQuickReplyOn}
            />,
        ])
    }, [attachmentEnabled, sendMessage, loading, shouldDisableSendButton])

    const keyBindingFn = (e: SyntheticKeyboardEvent) => {
        if (e.keyCode === 13 && e.shiftKey) {
            return 'sa-send-msg'
        }
        dispatch(chatSendTypingEvent(chatId))
        ;(document.getElementsByClassName('DraftEditor-root')[0] as HTMLElement)?.click()
        return getDefaultKeyBinding(e)
    }

    const handleKeyCommand = (e: string) => {
        if (e === 'sa-send-msg') {
            sendMessage()
        }
    }

    const { smsIsTooLongToSend } = useSocialChatReplyFunctionality(currentSocialEditorContent)

    const onSocialSubmit = () => {
        if (!currentSocialEditorContent || smsIsTooLongToSend) return
        dispatch(sendChatMessage({ id: chat!.id, msg: currentSocialEditorContent, clearEditor: true }))
        dispatch(updateSocialEditorContent({ id: chatId, content: '' }))
        dispatch(setChatReplyText({ id: chat!.id, text: '', isProbablyJson: false }))
        setMessageSent(true)
        dispatch(clearUnread(chat.id))
    }
    const wysiwygProps = {
        isShowWarningMessage,
        setIsShowWarningMessage,
        currentEditorContent,
        onEditorStateChange,
        toolbarButtons,
        files,
        dFile,
        keyBindingFn,
        handleKeyCommand,
    }

    const setMessage = useCallback(
        (message: string) => {
            dispatch(updateSocialEditorContent({ id: chatId, content: message }))
        },
        [chatId],
    )

    return (
        <div {...dragEvents}>
            {chat.socialMediaPlatform ? (
                <div className="sa-social-media-replier">
                    <SocialChatReplier
                        message={currentSocialEditorContent}
                        setMessage={setMessage}
                        onSubmit={onSocialSubmit}
                        socialMediaPlatform={chat.socialMediaPlatform}
                        messageSent={messageSent}
                        setMessageSent={setMessageSent}
                        setIsQuickReplyOn={setIsQuickReplyOn}
                        className="sa-social-media-replier-input"
                        wysiwygProps={wysiwygProps}
                    />
                </div>
            ) : (
                <WYSIWYG
                    isShowWarningMessage={isShowWarningMessage}
                    setIsShowWarningMessage={setIsShowWarningMessage}
                    editorState={currentEditorContent}
                    onEditorStateChange={onEditorStateChange}
                    placeholder="Enter message..."
                    toolbarButtons={toolbarButtons}
                    files={files}
                    onDeleteFile={dFile}
                    keyBindingFn={keyBindingFn}
                    handleKeyCommand={handleKeyCommand}
                />
            )}
        </div>
    )
}

export default ChatReplier
