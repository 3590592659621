import './contact-history.scss'

import classNames from 'classnames'
import useHasFeature, { AppFeatures } from 'hooks/useHasFeature'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ClipLoader } from 'react-spinners'
import { WidgetType } from 'store/app/app.state'
import {
    deselectContactHistoryInstance,
    fetchContactHistoryByChannel,
} from 'store/contactHistory/contactHistory.actions'
import { selectContactHistoryInstance } from 'store/contactHistory/contactHistory.selectors'
import RootState from 'store/state'

import { Box } from '@missionlabs/smartagent-app-components'
import { H, Level } from 'react-accessible-headings'

import { getChannelType, isChannelType } from '../utils'
import Header from './Header'
import MainView from './MainView'
import SidePanel from './SidePanel'
import { constructDisplayName, contactHasNoHistory, getChannelSubFeature } from './utils'

export interface Props {
    isWidget?: boolean
    type?: WidgetType
    showNone?: boolean
    selectedView?: string
}

const ContactHistory: React.FC<Props> = ({ isWidget = false, type, showNone, selectedView }) => {
    const dispatch = useDispatch()

    const contact = useSelector((state: RootState) => state.contact)
    const selectedContactHistoryInstance = useSelector(selectContactHistoryInstance)
    const { interactions, loaded, attributeSearchName, attributeSearchValue } =
        selectedContactHistoryInstance ?? {}

    // If the selected contact history instance is a channel and endpoint search and there is no longer a contact deselect the instance
    if (
        !contact &&
        attributeSearchName &&
        isChannelType(attributeSearchName) &&
        attributeSearchValue
    ) {
        dispatch(deselectContactHistoryInstance({ attributeSearchName, attributeSearchValue }))
    }

    const channelType = getChannelType(contact)

    const noContactHistory = contactHasNoHistory(interactions, loaded)

    const hasFeature = useHasFeature()
    const channelSubFeature = getChannelSubFeature(channelType)
    // Only check for user permissions if a contact has been selected
    const hasChannelPermissions =
        !contact || hasFeature(AppFeatures.CONTACT_HISTORY, channelSubFeature)

    const isHidden = () => {
        if (showNone) return true
        if (selectedView && type !== selectedView) return true
        return false
    }

    const constructHeader = () => {
        const displayName = constructDisplayName(
            channelType,
            contact?.customerEndpointAddress,
            contact?.attributes,
        )
        return (
            <>
                <H>Contact History</H>
                {noContactHistory && (
                    <span className="contact-history-not-found-message">
                        No history available
                        {displayName &&
                            attributeSearchName &&
                            isChannelType(attributeSearchName) &&
                            ` - This is the first time ${displayName} has reached us through this channel`}
                    </span>
                )}
            </>
        )
    }

    // This is what will load contact history via the endpoint of a contact per channel type
    // Custom attribute searches are handled by the tab switcher component and the plugins.ts middleware
    useEffect(() => {
        if (hasChannelPermissions && contact) {
            dispatch(fetchContactHistoryByChannel(contact, channelType))
        }
    }, [hasChannelPermissions, contact?.ID, contact?.customerEndpointAddress])

    return !isHidden() ? (
        <Box
            className={classNames({
                'contact-history-box': true,
                widget: isWidget,
            })}
            collapse={isWidget && noContactHistory}
            alt
            header={constructHeader()}
            boxLabel="Contact History"
        >
            <Level>
                {hasChannelPermissions ? (
                    <div className="contact-history-main-content">
                        {selectedContactHistoryInstance && (
                            <>
                                {loaded ? (
                                    <>
                                        <Header />
                                        {!noContactHistory && (
                                            <div className="contact-history-main-content-body">
                                                <SidePanel />
                                                <MainView />
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <div
                                        className="contact-history-main-content-loading"
                                        data-testid="contact-history-loading"
                                    >
                                        <ClipLoader />
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                ) : (
                    <div className="contact-history-main-content-no-permissions">
                        You do not have the correct permissions to view Contact History
                    </div>
                )}
            </Level>
        </Box>
    ) : null
}

export default ContactHistory
