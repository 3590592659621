import { Box, CopyClipboard } from '@missionlabs/smartagent-app-components'
import { H } from 'react-accessible-headings'
import classnames from 'classnames'
import useContact from 'hooks/redux/useContact'
import React, { useEffect, useState } from 'react'
import { Plugin } from 'store/app/app.state'
import Stats from 'views/Home/Stats'
import ContactHistory, {
    Props as ContactHistoryProps,
} from 'views/Metrics/HistoricMetrics/ContactHistory'
import './plugins.scss'

export interface Props {
    plugin: Plugin
    type: string
    showNone?: boolean
    selectedView?: string
}

const PluginView: React.FC<Props> = ({ type, plugin, showNone, selectedView }) => {
    const contact = useContact()
    const currentContactId = contact?.ID

    const [rerenderCounter, setRerenderCounter] = useState(0)

    useEffect(() => {
        if (type && type === 'task' && plugin.view === 'Case Management') {
            setRerenderCounter(rerenderCounter + 1)
        }
    }, [contact?.attributes])

    const renderLocalView = (plugin: Plugin) => {
        switch (plugin.src) {
            case 'real-time':
                return <Stats />
            case 'contact-history':
                const chProps: ContactHistoryProps = {
                    isWidget: true,
                    type: 'contactHistory',
                    selectedView: 'contactHistory',
                }
                return <ContactHistory {...chProps} />
            default:
                return null
        }
    }

    const isHidden = (plugin: Plugin, view?: string) => {
        if (showNone) {
            return true
        }
        if (view && plugin.view !== view) {
            return true
        }

        if (contact?.plugins?.[plugin.name] === 'show') {
            return false
        }
        // eslint-disable-next-line
        return plugin.hasOwnProperty('hidden') ? plugin.hidden : plugin.defaultHidden
    }

    const createPluginSrc = (): string => {
        let pluginSrc = plugin.useLocal ? plugin.srcLocal : plugin.src
        if (plugin.name === 'stepbystep')
            pluginSrc = `${pluginSrc}&currentContactId=${currentContactId}`
        if (!contact?.attributes || !plugin.useAttributesToUpdateSrc) return pluginSrc

        const {
            'sa-plugin-url-match': urlMatch,
            'sa-plugin-url-replace': urlReplace,
            'sa-plugin-url-interpret-as-regex': interpretAsRegex,
        } = contact.attributes
        if (urlMatch === undefined || urlReplace === undefined) return pluginSrc

        if (interpretAsRegex === 'true') {
            const regexPattern = urlMatch.match(/\/(.+)\/.*/)[1]
            const regexFlags = urlMatch.match(/\/.+\/(.*)/)[1]
            try {
                return pluginSrc.replace(new RegExp(regexPattern, regexFlags), urlReplace)
            } catch (error) {
                return pluginSrc
            }
        } else {
            return pluginSrc.replace(urlMatch, urlReplace)
        }
    }

    return (
        <div
            cy-ref={plugin.name} /* eslint-disable-line */
            key={`${plugin.name}-${rerenderCounter}`}
            className={classnames({
                'sa-plugin-wrapper': true,
                hidden: isHidden(plugin, selectedView),
            })}
            id="skip-to-content"
        >
            {renderLocalView(plugin) ?? (
                <Box
                    collapse
                    alt
                    boxLabel={plugin.name}
                    header={
                        <div className="row middle">
                            <H className="sa-plugin-title">{plugin.title}</H>
                            {plugin.titleTag ? (
                                <span className="sa-plugin-tag">
                                    <span className="xsm-mar-right">{plugin.titleTag}</span>
                                    {plugin.allowCopyTag ? (
                                        <CopyClipboard copyStr={plugin.titleTag} size={14} />
                                    ) : null}
                                </span>
                            ) : null}
                        </div>
                    }
                >
                    <iframe
                        style={plugin.forceHeight ? { minHeight: plugin.forceHeight } : undefined}
                        allow="camera; microphone; autoplay; clipboard-write"
                        title={plugin.name}
                        src={createPluginSrc()}
                        className="sa-plugin"
                        data-key={plugin.name}
                        data-type={plugin.type}
                    />
                </Box>
            )}
        </div>
    )
}

export default PluginView
