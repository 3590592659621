import { PureComponent } from 'react'

import './header.scss'

interface Props {
    logoURL: string
}

class Header extends PureComponent<Props> {
    render() {
        return (
            <header className="header lg-mar-bottom">
                <img
                    className="header-logo"
                    alt=""
                    src={this.props.logoURL}
                    aria-hidden
                />
            </header>
        )
    }
}

export default Header
