import type { Middleware, MiddlewareAPI } from '@reduxjs/toolkit'
import { hidePlugin, resetPlugin, showPlugin, updateTitleTag } from 'store/app/app.actions'
import { addConnection, endConnection, makeCall } from 'store/call/call.actions'
import {
    removeContactAttributes,
    updateContactAttributes,
    updateCustomer,
} from 'store/contact/contact.actions'
import * as ContactReducer from 'store/contact/contact.reducer'
import {
    deselectContactHistoryInstance,
    fetchContactHistoryByCustomAttribute,
} from 'store/contactHistory/contactHistory.actions'
import {
    contactHistoryAttributeDisplayLabel,
    contactHistoryAttributeKey,
    contactHistoryAttributeValue,
} from 'store/contactHistory/contactHistory.constants'
import { addError, addSuccess } from 'store/global/global.actions'
import { setKendraPluginState } from 'store/kendraPlugin/kendraPlugin.actions'
import { createNotification } from 'store/notification/notification.actions'
import RootState from 'store/state'
import { getQuickConnects, setStatusControl } from 'store/user/user.actions'
import * as UserReducer from 'store/user/user.reducer'

import { initAppSuccess } from '../app/app.reducer'
import { Plugin } from '../app/app.state'
import * as CallReducer from '../call/call.reducer'
import { mergeAttributesIntoContact, parseContactAttributes } from '../contact/contact.utils'

import { isAnyAction } from 'utils';
import Logger from '../../logger';

const ROLLBAR_PLUGINS_ERROR_PREFIX = 'PLUGINS-ERROR'

const pluginMiddleware: Middleware<{}, RootState> = (store) => (next) => async (action) => {
    if (!isAnyAction(action)) return

    switch (action.type) {
        case initAppSuccess.type:
            listen(store)
            return next(action)
        case CallReducer.incomingCall.type: {
            postMessageToIFrames(store, 'incoming_call', {
                ...action.payload,
            })

            const contact = action?.payload?.contact
            if (!contact) return next(action)
            const contactToSend = {
                ...contact,
                ...parseContactAttributes(contact.attributes),
            }
            postMessageToIFrames(store, 'contact_updated', contactToSend) //plugins dont respond to incoming call to refresh customer data so this is needed too.
            return next(action)
        }

        case CallReducer.callStarted.type:
            postMessageToIFrames(store, 'call_started')
            return next(action)
        case CallReducer.hold.type:
            postMessageToIFrames(store, 'on_hold')
            return next(action)
        case CallReducer.resume.type:
            postMessageToIFrames(store, 'off_hold')
            return next(action)
        case CallReducer.callEnded.type:
            postMessageToIFrames(store, 'call_ended')
            return next(action)
        case CallReducer.rejectCall.type:
            postMessageToIFrames(store, 'reject_call')
            return next(action)
        case CallReducer.makeCall.type:
            postMessageToIFrames(store, 'outgoing_call', {
                ...action.payload,
            })
            return next(action)
        case CallReducer.transferCall.type:
            postMessageToIFrames(store, 'transfer_call', {
                ...(action.payload as any),
            })
            return next(action)
        case CallReducer.missedCall.type:
            postMessageToIFrames(store, 'missed_call')
            return next(action)
        case CallReducer.addConnection.type:
            postMessageToIFrames(store, 'add_connection', {
                ...action.payload,
            })
            return next(action)
        case CallReducer.conferenceConnections.type:
            postMessageToIFrames(store, 'conference_connections')
            return next(action)
        case CallReducer.endConnection.type: {
            const connection = store
                .getState()
                .call?.connections.find((c) => c.id === action.payload)
            postMessageToIFrames(store, 'end_connection', connection)
            return next(action)
        }
        case CallReducer.toggleConnections.type:
            postMessageToIFrames(store, 'toggle_connections')
            return next(action)
        case UserReducer.afterCallWork.type:
            postMessageToIFrames(store, 'after_call_work', {
                ...store.getState().contact,
            })
            return next(action)
        case UserReducer.afterCallWorkEnd.type:
            postMessageToIFrames(store, 'after_call_work_end')
            return next(action)
        case UserReducer.userSetState.type:
            postMessageToIFrames(store, 'next_status', action.payload.state.name)
            return next(action)
        case ContactReducer.updateContactAttributes.type: {
            const contact = store.getState().contact
            if (!contact) return next(action)
            const updatedContact = mergeAttributesIntoContact(contact, action.payload.attributes)
            const contactToSend = {
                ...updatedContact,
                ...parseContactAttributes(updatedContact.attributes),
            }
            console.log('contactToSend', contactToSend)
            postMessageToIFrames(store, 'contact_updated', contactToSend)
            return next(action)
        }
        case ContactReducer.customerUpdated.type: {
            const contact = store.getState().contact || { customer: {}, attributes: {} }
            const customer = contact!.customer
            postMessageToIFrames(store, 'contact_updated', {
                ...contact,
                ...parseContactAttributes(contact.attributes),
                customer: {
                    ...customer,
                    ...action.payload,
                },
            })
            return next(action)
        }
        case ContactReducer.setContact.type: {
            const { contact } = store.getState()
            postMessageToIFrames(store, 'contact_set', action.payload ?? contact)
            if (!contact || !action.payload) return next(action)
            if (contact.ID === action.payload.ID) return next(action)
            //Need to refresh call plugins in this case
            const plugins = getPlugins()
            for (let i = 0; i < plugins.length; i++) {
                const iframe = plugins[i] as HTMLIFrameElement
                const plugin = getPluginFromIFrame(store, iframe)
                if (plugin?.type === 'call') {
                    // eslint-disable-next-line
                    iframe.src = iframe.src
                    //Need to refresh call plugins display
                    store.dispatch(resetPlugin(plugin.name))
                }
            }
            return next(action)
        }
        default:
            return next(action)
    }
}

const postMessageToIFrames = (store: MiddlewareAPI, event: string, message?: any) => {
    const plugins = getPlugins()
    for (let i = 0; i < plugins.length; i++) {
        const iframe = plugins[i] as HTMLIFrameElement
        const plugin = getPluginFromIFrame(store, iframe)
        if (!plugin) return
        try {
            iframe.contentWindow!.postMessage(
                {
                    type: 'smartagent',
                    event,
                    config: plugin,
                    message,
                },
                process.env.NODE_ENV === 'production' ? plugin.src : '*',
            )
        } catch (ex) {
            Logger.error(`${ROLLBAR_PLUGINS_ERROR_PREFIX}-POST-IFRAME-MESSAGES`, ex);
            console.log('error posting message', ex);
        }
    }
}

const getPlugins = () => {
    return document.getElementsByClassName('sa-plugin')
}

const findPluginIframe = (source: any): HTMLIFrameElement | undefined => {
    const plugins = getPlugins()
    for (let i = 0; i < plugins.length; i++) {
        if ((plugins[i] as HTMLIFrameElement).contentWindow === source)
            return plugins[i] as HTMLIFrameElement
    }
    return
}

const getPluginFromIFrame = (
    store: MiddlewareAPI,
    iframe: HTMLIFrameElement,
): Plugin | undefined => {
    const { key, type } = iframe.dataset
    return (store.getState() as RootState).app.plugins.find(
        (p) => p.name === key && p.type === type,
    )
}

const listen = (store: MiddlewareAPI<any, RootState>) => {
    window.addEventListener('message', (message: MessageEvent) => {
        const externalIframeConfiguration = (store.getState() as RootState).app.appConfig
            .externalIframeConfiguration
        if (
            externalIframeConfiguration &&
            message.origin === new URL(externalIframeConfiguration.url).origin
        ) {
            console.log('EXTERNAL-IFRAME-MESSAGE: ', message)
            postMessageToIFrames(store, 'iframe_message', message.data)
            return
        }

        const payshieldMessage = message.data?.transaction_type || ''

        if (!message.data || (message.data.type !== 'smartagent' && payshieldMessage === '')) return

        // Process any Payshield messages first
        if (payshieldMessage === 'PURCHASE') {
            console.log('Received Payshield message:', message)

            const messageOrigin = message.origin
            const pluginOriginMatch = (store.getState() as RootState).app.plugins.find(
                (p) => p.origin === messageOrigin,
            )

            if (pluginOriginMatch) {
                const contact = store.getState().contact
                if (!contact) return

                // Capture all Payshield attributes
                const responseCode = message.data.response_code
                const amount = message.data.amount
                const currency = message.data.currency
                const approved = message.data.approved
                const cardholderName = message.data.cardholder_name
                const paymentRef = message.data.crn1
                const expiryDate = message.data.expiry_date
                const responseText = message.data.response_text
                const userID = message.data.user_id

                const payload = {
                    'sa-payshield-response-code': responseCode,
                    'sa-payshield-amount': amount,
                    'sa-payshield-currency': currency,
                    'sa-payshield-approved': approved,
                    'sa-payshield-cardholder-name': cardholderName,
                    'sa-payshield-payment-ref': paymentRef,
                    'sa-payshield-expiry-date': expiryDate,
                    'sa-payshield-response-text': responseText,
                    'sa-payshield-user-id': userID,
                }

                // Update contact with transaction details
                store.dispatch(updateContactAttributes(contact.ID, payload, true))
            } else {
                console.log('Payshield message does not match origin and will be ignored')
            }
        }

        const iframe = findPluginIframe(message.source)
        if (!iframe) return
        const plugin = getPluginFromIFrame(store, iframe)
        if (!plugin) return

        // Process Smartagent messages
        switch (message.data.event) {
            case 'pulse':
                iframe.style.height = message.data.message.height + 'px'
                return
            case 'init': {
                const contact = store.getState().contact;
                iframe.style.height = message.data.message.height + 'px';
                try {
                    //send current data
                    iframe.contentWindow?.postMessage(
                        {
                            type: 'smartagent',
                            event: 'init',
                            config: plugin,
                            message: contact
                                ? {
                                      ...contact,
                                      ...parseContactAttributes(contact.attributes),
                                  }
                                : {
                                      customer: {},
                                  },
                        },
                        process.env.NODE_ENV === 'production' ? plugin.src : '*'
                    );
                } catch(error) {
                    Logger.error(`${ROLLBAR_PLUGINS_ERROR_PREFIX}-INIT`, error);
                    console.log('Error posting init: ', error)
                }
                return;
            }
            case 'hide':
                store.dispatch(hidePlugin(plugin.name))
                return
            case 'show':
                store.dispatch(showPlugin(plugin.name))
                return
            case 'deselect_contact_history': {
                const {
                    'sa-contactHistorySearchKey': attributeName,
                    'sa-contactHistorySearchValue': attributeValue,
                } = message.data.message
                store.dispatch(
                    deselectContactHistoryInstance({
                        attributeSearchName: attributeName,
                        attributeSearchValue: attributeValue,
                    }),
                )

                const contact = store.getState().contact
                if (!contact) return
                store.dispatch(removeContactAttributes(contact.ID, message.data.message))
                return
            }
            case 'update_contact': {
                const attributes = message.data.message

                if (attributes.setContactHistory) {
                    const {
                        [contactHistoryAttributeKey]: attributeName,
                        [contactHistoryAttributeValue]: attributeValue,
                        [contactHistoryAttributeDisplayLabel]: displayLabel,
                    } = attributes
                    store.dispatch(
                        fetchContactHistoryByCustomAttribute(
                            attributeName,
                            attributeValue,
                            displayLabel,
                        ),
                    )
                }
                const contact = store.getState().contact
                if (!contact) return
                store.dispatch(updateContactAttributes(contact.ID, attributes))
                return
            }
            case 'update_customer':
                store.dispatch(updateCustomer(message.data.message))
                return
            case 'update_quick_connects':
                store.dispatch(getQuickConnects())
                return
            case 'get_token':
                try {
                    iframe.contentWindow?.postMessage(
                        {
                            type: 'smartagent',
                            event: message.data.event,
                            message: store.getState().auth.token,
                        },
                        process.env.NODE_ENV === 'production' ? plugin.src : '*'
                    );
                } catch(error) {
                    Logger.error(`${ROLLBAR_PLUGINS_ERROR_PREFIX}-GET-TOKEN`, error);
                    console.log('Error posting get_token: ', error)
                }
                return;
            case 'get_user':
                try {
                    iframe.contentWindow?.postMessage(
                        {
                            type: 'smartagent',
                            event: message.data.event,
                            message: {
                                name: store.getState().user?.name,
                                username: store.getState().user?.username,
                            },
                        },
                        process.env.NODE_ENV === 'production' ? plugin.src : '*'
                    );
                } catch(error) {
                    Logger.error(`${ROLLBAR_PLUGINS_ERROR_PREFIX}-GET-USER`, error);
                    console.log('Error posting get_user: ', error)
                }
                return;
            case 'get_company_id':
                iframe.contentWindow?.postMessage(
                    {
                        type: 'smartagent',
                        event: message.data.event,
                        message: (store.getState() as RootState).app.ID,
                    },
                    process.env.NODE_ENV === 'production' ? plugin.src : '*',
                )
                return
            case 'get_instance_id':
                try {
                    iframe.contentWindow?.postMessage(
                        {
                            type: 'smartagent',
                            event: message.data.event,
                            message: (store.getState() as RootState).app.instance?.ID,
                        },
                        process.env.NODE_ENV === 'production' ? plugin.src : '*'
                    );
                } catch(error) {
                    Logger.error(`${ROLLBAR_PLUGINS_ERROR_PREFIX}-GET-INSTANCE-ID`, error);
                    console.log('Error posting get_instance_id: ', error)
                }
                return;
            case 'get_contact_attributes':
                try {
                    iframe.contentWindow?.postMessage(
                        {
                            type: 'smartagent',
                            event: message.data.event,
                            message: (store.getState() as RootState).contacts[0]?.attributes,
                        },
                        process.env.NODE_ENV === 'production' ? plugin.src : '*'
                    );
                } catch(error) {
                    Logger.error(`${ROLLBAR_PLUGINS_ERROR_PREFIX}-GET-CONTACT-ATTRIBUTES`, error);
                    console.log('Error posting get_contact_attributes: ', error)
                }
                return;
            case 'acqueon_contact':
                postMessageToIFrames(store, 'acqueon_contact_updated', message.data)
                return
            case 'toggle_status_change':
                const { blockStatusChange, statusTooltipMessage } = message.data
                store.dispatch(setStatusControl({ blockStatusChange, statusTooltipMessage }))
                return
            case 'set_title_tag':
                store.dispatch(updateTitleTag({ name: plugin.name, tag: message.data.message }))
                return
            case 'transfer_call': {
                const payload = message.data?.message
                store.dispatch(addConnection(payload?.number, payload?.name, payload?.endpoint))
                return
            }
            case 'end_conference_call': {
                const connectionName = message.data?.message.connectionName
                const activeConnections = store.getState().call?.connections
                if (activeConnections && activeConnections.length > 1) {
                    // if the connection can't be found assume the second connection is the call that needs to be ended
                    const connectionToRemove =
                        activeConnections.find(({ name }) => name && name === connectionName) ??
                        activeConnections[1]
                    store.dispatch(endConnection(connectionToRemove.id))
                }
                return
            }
            case 'quick_connect': {
                const qqName = message.data?.message.name
                const qq = store.getState().user?.connects?.find((c) => c.name === qqName)
                if (!store.getState().call) {
                    return store.dispatch(makeCall('', qqName, qq))
                }
                return store.dispatch(addConnection('', qqName, qq))
            }
            case 'show_notification': {
                const { type, header, text } = message.data.message
                return store.dispatch(createNotification({ type, header, text }))
            }
            case 'show_popup_message': {
                const { type, message: popupMessage } = message.data.message
                if (type === 'ERROR') return store.dispatch(addError(popupMessage))
                store.dispatch(addSuccess(popupMessage))
                return
            }
            case 'get_kendra_plugin_state': {
                try {
                    iframe.contentWindow?.postMessage(
                        {
                            type: 'smartagent',
                            event: message.data.event,
                            message: (store.getState() as RootState).kendraPlugin,
                        },
                        process.env.NODE_ENV === 'production' ? plugin.src : '*'
                    );
                } catch(error) {
                    Logger.error(`${ROLLBAR_PLUGINS_ERROR_PREFIX}-KENDRA-STATE`, error);
                    console.log('Error posting get_kendra_plugin_state: ', error)
                }
                return;
            }
            case 'set_kendra_plugin_state': {
                store.dispatch(setKendraPluginState(message.data?.message))
                return
            }
            case 'post_iframe': {
                if (!externalIframeConfiguration) return
                const iframeWindow = (
                    document.getElementById('external-iframe') as HTMLIFrameElement | null
                )?.contentWindow
                if (!iframeWindow) return
                iframeWindow?.postMessage(message.data, '*')

                return
            }
        }
        ;(window as any).lastMessage = message.source
    })
}

export default pluginMiddleware
