// There is no way to catch an error in this way using hooks.
// https://reactjs.org/docs/hooks-faq.html#do-hooks-cover-all-use-cases-for-classes

import { PropsWithChildren, PureComponent } from 'react'
import updateDocumentTitle from 'utils/updateDocumentTitle'

class ErrorBoundary extends PureComponent<
    PropsWithChildren<{ appError: (message?: string) => void }>
> {
    componentDidCatch(e: Error, info: any) {
        this.props.appError(e.stack + 'BREAK' + info.componentStack)
    }

    render() {
        updateDocumentTitle('Error - SmartAgent')

        return this.props.children
    }
}

export default ErrorBoundary
