import React, { ReactNode } from 'react'
import './toolbar.scss'
import { H } from 'react-accessible-headings'
interface Props {
    children?: ReactNode
    items: ReactNode[]
    text: string
}

const ToolBar: React.FC<Props> = ({ children, items, text }) => {
    return (
        <div className="sa-toolbar row between middle">
            <H className="sa-toolbar-text">{text}</H>
            {children}
            <menu className="items-container">
                {items.map((item, i) => {
                    return (
                        <div className="sm-mar-right" key={`toolbar-menu-items--${i}`}>
                            {item}
                        </div>
                    )
                })}
            </menu>
        </div>
    )
}

export default ToolBar
