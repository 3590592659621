import classnames from 'classnames'
import { useEffect, useRef, useState } from 'react'

import { Button } from '@missionlabs/smartagent-app-components'
import { H, Level } from 'react-accessible-headings'

import type { NotificationTypeAltMapping } from 'components/Notification/types'
import type { NotificationType } from 'store/notification/notification.state'

import CloseIcon from 'images/close-icon.svg'
import ErrorIcon from 'images/error-icon.svg'
import InfoIcon from 'images/info-icon.svg'
import SuccessIcon from 'images/success-icon.svg'
import WarningIcon from 'images/warning-icon.svg'

import styles from './Notification.module.scss'

export type Props = {
    header: string
    text: string
    type?: NotificationType
    onClose?: () => void
    closeAfterMs?: number
}

const getNotificationIcon = (type: NotificationType): string => {
    switch (type) {
        case 'success':
            return SuccessIcon
        case 'info':
            return InfoIcon
        case 'warning':
            return WarningIcon
        case 'error':
            return ErrorIcon
        default:
            return SuccessIcon
    }
}

const notificationTypeAltMapping: NotificationTypeAltMapping = {
    success: 'Success notification icon',
    info: 'Information notification icon',
    warning: 'Warning notification icon',
    error: 'Error notification icon',
}

const Notification = ({ header, text, type = 'success', onClose, closeAfterMs = 3000 }: Props) => {
    // please, keep this value in sync with css animation duration
    const animationDurationMs = 500

    const notificationIcon = getNotificationIcon(type)
    const notificationIconAlt = notificationTypeAltMapping[type]

    const [hideNotification, setHideNotification] = useState(false)

    const closeNotificationTimeoutId = useRef<NodeJS.Timeout | undefined>(undefined)
    const onHideCallbackTimeoutId = useRef<NodeJS.Timeout | undefined>(undefined)
    const isCloseButtonClicked = useRef<boolean>(false)

    const wrapperClassnames = classnames(
        styles.wrapper,
        styles[`wrapper--${type}`],
        { [styles[`wrapper--show`]]: !hideNotification },
        { [styles[`wrapper--hide`]]: hideNotification },
    )

    useEffect(() => {
        setTimeout(() => {
            addClosingTimeout()
        }, animationDurationMs)

        return () => {
            if (closeNotificationTimeoutId.current) {
                clearTimeout(closeNotificationTimeoutId.current)
            }

            if (onHideCallbackTimeoutId.current) {
                clearTimeout(onHideCallbackTimeoutId.current)
            }
        }
    }, [])

    const clearClosingTimeout = () => {
        if (closeNotificationTimeoutId.current) {
            clearTimeout(closeNotificationTimeoutId.current)
        }
    }

    const addClosingTimeout = () => {
        closeNotificationTimeoutId.current = setTimeout(() => {
            handleOnClose()
        }, closeAfterMs)
    }

    const handleOnClose = () => {
        isCloseButtonClicked.current = true

        setHideNotification(true)
        invokeCallbackOnAnimationComplete(onClose)
    }

    const handleOnMouseLeave = () => {
        // Do nothing if the event is triggered when the close button is clicked due to the mouse leaving at the end of the animation
        if (isCloseButtonClicked.current) return
        addClosingTimeout()
    }

    const invokeCallbackOnAnimationComplete = (callback: (() => void) | undefined) => {
        onHideCallbackTimeoutId.current = setTimeout(() => {
            callback?.()
        }, animationDurationMs)
    }

    return (
        <Level value={2}>
            <div
                data-testid="notification"
                className={wrapperClassnames}
                onMouseEnter={clearClosingTimeout}
                onMouseLeave={handleOnMouseLeave}
            >
                <div className={styles['container__top']}>
                    <Button aria-label="Close notification" onClick={handleOnClose}>
                        <img src={CloseIcon} alt="Close notification icon" />
                    </Button>
                </div>
                <div className={styles['container__bottom']}>
                    <img src={notificationIcon} alt={notificationIconAlt} />
                    <div role="alert" className={styles.content}>
                        <H className={styles.title}>{header}</H>
                        <p>{text}</p>
                    </div>
                </div>
            </div>
        </Level>
    )
}

export default Notification
