import type { IAgent } from '@missionlabs/smartagent-lib-shared/build/agent'
import { makeRequest } from 'services/api'
import { IAgentMetricFiltersV2, IAgentMetricDataV2 } from 'store/metrics/metrics.state'
import { IAgentCreate } from 'store/settings/settings.state'
import { IAgentQueueStatsV2 } from 'store/user/user.state'
import { IStrObj, paramsToQueryString } from 'utils'

export async function getAgents(companyID: string, token: string, filters?: IStrObj) {
    const response = await makeRequest({
        url: `/agent-service/companies/${companyID}/agents/status?${paramsToQueryString(filters)}`,
        headers: {
            'X-Amz-Security-Token': token,
        },
    })

    return response.data
}

export async function getStatuses(companyID: string, instanceID: string, token: string) {
    const response = await makeRequest({
        url: `/agent-service/companies/${companyID}/instances/${instanceID}/agents/statuses`,
        method: 'get',
        headers: {
            'X-Amz-Security-Token': token,
        },
    })

    return response.data
}

export async function putAgentStatus(
    companyID: string,
    instanceID: string,
    agentID: string,
    token: string,
    data: {
        agentStatusId: string
    },
): Promise<IAgent> {
    const response = await makeRequest<IAgent>({
        url: `/agent-service/companies/${companyID}/instances/${instanceID}/agents/${agentID}/statuses`,
        method: 'put',
        data,
        headers: {
            'X-Amz-Security-Token': token,
        },
    })

    return response.data
}

export async function getUsers(
    companyID: string,
    instanceID: string,
    token: string,
    fields?: IStrObj,
): Promise<Array<IAgent>> {
    const response = await makeRequest<{ data: IAgent[] }>({
        url: `/agent-service/companies/${companyID}/instances/${instanceID}/agents?${paramsToQueryString(fields)}`,
        method: 'get',
        headers: {
            'X-Amz-Security-Token': token,
        },
    })

    return response.data.data
}

export async function getSingleUser(
    companyID: string,
    instanceID: string,
    token: string,
    agentID: string,
) {
    const response = await makeRequest<IAgent>({
        url: `/agent-service/companies/${companyID}/instances/${instanceID}/agents/${agentID}`,
        method: 'get',
        headers: {
            'X-Amz-Security-Token': token,
        },
    })

    return response.data
}

export async function postUser(
    companyID: string,
    instanceID: string,
    token: string,
    data: IAgentCreate,
): Promise<IAgent> {
    const response = await makeRequest<IAgent>({
        url: `/agent-service/companies/${companyID}/instances/${instanceID}/agents`,
        method: 'post',
        data,
        headers: {
            'X-Amz-Security-Token': token,
        },
    })

    return response.data
}

export async function putUser(
    companyID: string,
    instanceID: string,
    token: string,
    data: Partial<IAgentCreate>,
): Promise<IAgent> {
    const response = await makeRequest<IAgent>({
        url: `/agent-service/companies/${companyID}/instances/${instanceID}/agents/${data.ID}`,
        method: 'put',
        data,
        headers: {
            'X-Amz-Security-Token': token,
        },
    })

    return response.data
}

export async function deleteUser(
    companyID: string,
    instanceID: string,
    agentID: string,
    token: string,
) {
    const response = await makeRequest({
        url: `/agent-service/companies/${companyID}/instances/${instanceID}/agents/${agentID}`,
        method: 'delete',
        headers: {
            'X-Amz-Security-Token': token,
        },
    })

    return response.data
}

export async function getRoutingProfiles(companyID: string, instanceID: string, token: string) {
    const response = await makeRequest({
        url: `/agent-service/companies/${companyID}/instances/${instanceID}/routing-profiles`,
        method: 'get',
        headers: {
            'X-Amz-Security-Token': token,
        },
    })

    return response.data
}

export async function getSecurityGroups(companyID: string, instanceID: string, token: string) {
    const response = await makeRequest({
        url: `/agent-service/companies/${companyID}/instances/${instanceID}/security-groups`,
        method: 'get',
        headers: {
            'X-Amz-Security-Token': token,
        },
    })

    return response.data
}

export async function getUserHierarchy(companyID: string, instanceID: string, token: string) {
    const response = await makeRequest({
        url: `/agent-service/companies/${companyID}/instances/${instanceID}/user-hierarchy`,
        method: 'get',
        headers: {
            'X-Amz-Security-Token': token,
        },
    })

    return response.data
}

export async function getHierarchyGroups(companyID: string, instanceID: string, token: string) {
    const response = await makeRequest({
        url: `/agent-service/companies/${companyID}/instances/${instanceID}/hierarchy-groups`,
        method: 'get',
        headers: {
            'X-Amz-Security-Token': token,
        },
    })

    return response.data
}

export async function monitorAgent(
    companyID: string,
    instanceID: string,
    agentID: string | undefined,
    contactID: string | undefined,
    token: string,
    barge?: boolean,
) {
    const response = await makeRequest<string>({
        url: `/agent-service/companies/${companyID}/instances/${instanceID}/agents/${agentID}/contact/${contactID}/listen?barge=${barge}`,
        headers: {
            'X-Amz-Security-Token': token,
        },
        method: 'post',
    })
    return response
}

export const getAgentMetricsV2 = async ({
    companyID,
    instanceID,
    agentID,
    dataType,
    token,
}: {
    companyID: string
    instanceID: string
    agentID: string
    dataType: string
    token: string
}) => {
    const response = await makeRequest<IAgentQueueStatsV2[]>({
        url: `/agent-service/companies/${companyID}/instances/${instanceID}/agents/${agentID}/metrics`,
        method: 'post',
        headers: {
            'X-Amz-Security-Token': token,
        },
        data: {
            dataType,
        },
    })

    return response.data
}

export async function getAgentsMetricsV2(
    companyID: string,
    instanceID: string,
    token: string,
    filters: IAgentMetricFiltersV2,
) {
    const response = await makeRequest<IAgentMetricDataV2[]>({
        url: `/agent-service/companies/${companyID}/instances/${instanceID}/agents/metrics`,
        method: 'post',
        headers: {
            'X-Amz-Security-Token': token,
        },
        data: filters,
    })

    return response.data
}
