import { ListUserConversations } from 'graphql/queries/listUserConversations'
import Phonebook from 'images/icon-agent-directory.svg'
import React from 'react'
import { useSelector } from 'react-redux'

import { useDispatch } from 'react-redux'

import { Button, TriggeredPopup } from '@missionlabs/smartagent-app-components'

import { openPhonebook } from 'store/agents/agents.actions'
import { selectPhoneBook } from 'store/agents/agents.selctors'
import Agents from './Agents'

interface InnerProps {
    unreadMessages?: number
    userConversations?: ListUserConversations
}

export const AgentsDirectory: React.FC<InnerProps> = () => {
    const isPhoneBookOpen = useSelector(selectPhoneBook)

    const dispatch = useDispatch()

    const onClose = () => {
        dispatch(openPhonebook(false))
    }
    const onOpen = () => {
        dispatch(openPhonebook(true))
    }

    return (
        <div className="phonebook-icon">
            <TriggeredPopup
                active={!!isPhoneBookOpen}
                closeOnClickOutside
                fromCenter
                onClose={onClose}
                onOpen={onOpen}
                className="sa-agents-directory-popup"
                trigger={
                    <div className="notification-icon">
                        <Button>
                            <img src={Phonebook} alt="Agents" title="Agents" width={16} />
                        </Button>
                    </div>
                }
            >
                <Agents />
            </TriggeredPopup>
        </div>
    )
}

export default AgentsDirectory
