import useContact from 'hooks/redux/useContact'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
    pauseContactRecording,
    resumeContactRecording,
    startContactRecording,
} from 'services/api/api.contact'
import { addError } from 'store/global/global.actions'

export enum RecordingTypes {
    NONE = 'None',
    CALL = 'Audio',
    SCREEN = 'Screen',
    CALL_AND_SCREEN = 'Audio & Screen',
}

export default function useRecordingControl() {
    const contact = useContact()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)

    const determineRecordingType = (callRecording: boolean, screenRecording: boolean, isVoiceChannel: boolean) => {
        return callRecording && isVoiceChannel
            ? (screenRecording ? RecordingTypes.CALL_AND_SCREEN : RecordingTypes.CALL)
            : (screenRecording ? RecordingTypes.SCREEN : RecordingTypes.NONE)
    }

    const isVoiceChannel = contact?.channel === 'VOICE';
    const callRecordingBehaviour = contact?.attributes?.['sa-call-recording-behaviour'];
    const screenRecordingBehaviour = contact?.attributes?.['sa-screen-recording-behaviour']
    const recordingToggleStatus = contact?.attributes?.['sa-call-recording-toggle-status']

    const isCallRecordingOnInit = callRecordingBehaviour && callRecordingBehaviour !== 'OFF'
    const isScreenRecordingOnInit = screenRecordingBehaviour && screenRecordingBehaviour !== 'OFF'

    const [recordingState, setRecordingState] = useState<'on' | 'off' | undefined>(
        (isCallRecordingOnInit || isScreenRecordingOnInit) ? 'on' : undefined,
    )
    const [initialRecordingType, setInitialRecordingType] = useState<RecordingTypes>(
        determineRecordingType(isCallRecordingOnInit, isScreenRecordingOnInit, isVoiceChannel)
    )

    const [hideRecordingControl, setHideRecordingControl] = useState<boolean>(true);

    useEffect(() => {
        if (isCallRecordingOnInit || isScreenRecordingOnInit) setRecordingState('on')
        setInitialRecordingType(determineRecordingType(isCallRecordingOnInit, isScreenRecordingOnInit, isVoiceChannel))
    }, [isCallRecordingOnInit, isScreenRecordingOnInit, isVoiceChannel])

    useEffect(() => {
        if (recordingToggleStatus === 'off') setRecordingState('off')
        if (recordingToggleStatus === 'on') setRecordingState('on')
    }, [recordingToggleStatus])

    useEffect(() => {
        // - Edge case: In voice calls with only screen recording, we cannot pause/resume, so hide controls
        // - Call recording has no effect in non-voice calls, so hide controls
        setHideRecordingControl(
            (isVoiceChannel && initialRecordingType === RecordingTypes.SCREEN)
            || (!isVoiceChannel && [RecordingTypes.CALL, RecordingTypes.NONE].includes(initialRecordingType))
        );
    }, [isVoiceChannel, initialRecordingType])

    const toggleRecording = useCallback(async () => {
        if (!contact) {
            console.log('Contact object missing')
            return
        }

        setLoading(true)
        try {
            if (!recordingState) {
                await startContactRecording(contact.ID, contact.initialContactID)
                setRecordingState('on')
            } else if (recordingState === 'on') {
                await pauseContactRecording(contact.ID, contact.initialContactID)
                setRecordingState('off')
            } else {
                await resumeContactRecording(contact.ID, contact.initialContactID)
                setRecordingState('on')
            }
        } catch (error) {
            console.log('toggle recording failed', error)
            dispatch(
                addError(
                    recordingState === 'on'
                        ? 'Could not pause recording.'
                        : 'Could not start recording.',
                ),
            )
        } finally {
            setLoading(false)
        }
    }, [dispatch, contact, recordingState])

    return {
        loading,
        toggleRecording,
        initialRecordingType,
        recordingStatus: recordingState,
        hideRecordingControl,
    }
}
