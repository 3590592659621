import { Button, Input } from '@missionlabs/smartagent-app-components'
import Asterisk from 'images/button-asterisk.svg'
import Hash from 'images/button-hash.svg'
import PlusSign from 'images/button-plussign.svg'
import { ClipboardEvent, FC, KeyboardEvent, useCallback, useRef, useState } from 'react'

import classNames from 'classnames'
import styles from './Keypad.module.scss'

type DialButton = {
    text: string
    label?: string
    title?: string
    iconImg?: string
    labelImg?: string
}

const dialButtons: Array<DialButton> = [
    { text: '1' },
    { text: '2', label: 'ABC' },
    { text: '3', label: 'DEF' },
    { text: '4', label: 'GHI' },
    { text: '5', label: 'JKL' },
    { text: '6', label: 'MNO' },
    { text: '7', label: 'PQRS' },
    { text: '8', label: 'TUV' },
    { text: '9', label: 'WXYZ' },
    { text: '*', title: 'Asterisk', iconImg: Asterisk },
    { text: '0', label: '+', title: 'Plus Sign', labelImg: PlusSign },
    { text: '#', title: 'Hash', iconImg: Hash },
]

export interface KeypadProps {
    recordPresses?: boolean
    onKeypadButton: (text: string) => void
    onKeypadBackspace?: () => void
}

const Keypad: FC<KeypadProps> = ({ onKeypadButton, onKeypadBackspace = ()=>{}, recordPresses }) => {
    const [presses, setPresses] = useState('')
    const [inputValue, setInputValue] = useState('')
    const [isInputDisabled, setIsInputDisabled] = useState(false)
    const disabledTimeout = useRef<ReturnType<typeof setTimeout>>()
    const inputRef = useRef<HTMLInputElement>(null)

    const focusInput = useCallback(() => {
        inputRef.current?.focus()
    }, [])

    const handleOnKeypadButton = useCallback(
        (text: string) => {
            if (recordPresses) {
                setPresses((prev) => prev + text)
            }

            setInputValue((prev) => prev + text)

            const inputElement = inputRef.current

            inputElement?.scroll?.(inputElement.scrollWidth, inputElement.scrollHeight)
            if (inputElement?.value?.length) {
                inputElement?.setSelectionRange(
                    inputElement?.value.length,
                    inputElement?.value.length,
                )
            }

            onKeypadButton(text)
        },
        [recordPresses, onKeypadButton],
    )

    const handleBackSpace = useCallback(() => {
        setInputValue((prevValue) => prevValue.slice(0, -1))
        onKeypadBackspace();
    }, [handleOnKeypadButton])

    const onInputChange = useCallback(
        (event: KeyboardEvent<HTMLDivElement>) => {
            const value = event.key
            const isValid = isValidCharacter(value)

            if (value === 'Backspace') {
                handleBackSpace()
            }

            if (!isValid) return

            handleOnKeypadButton(value)
        },
        [handleOnKeypadButton, handleBackSpace],
    )

    const disableInput = useCallback((time: number) => {
        if (disabledTimeout.current) clearTimeout(disabledTimeout.current)

        setIsInputDisabled(true)

        disabledTimeout.current = setTimeout(() => {
            setIsInputDisabled(false)
        }, time)
    }, [])

    const onPaste = useCallback(
        (e: ClipboardEvent) => {
            const text = e.clipboardData.getData('text/plain')?.trim()
            const safeString = text.split('').reduce((prev, cur) => {
                return isValidCharacter(cur) ? prev + cur : prev
            }, '')

            if (!safeString) return
            if (recordPresses && safeString.length > 1) {
                disableInput(2000)
            }
            handleOnKeypadButton(safeString)
        },
        [disableInput, handleOnKeypadButton],
    )
    return (
        <div
            data-testid="keypad"
            id="keypad"
            className={styles.keypad}
            onClick={focusInput}
            onKeyDown={onInputChange}
            aria-label="input keypad numbers"
            role="presentation"
        >
            <span data-testid="keypad-history" className={styles['keypad-presses']}>
                {presses}
            </span>
            {
                <Input
                    title="Keypad Input"
                    onPaste={onPaste}
                    id="keypad-input"
                    className={classNames(
                        styles.keypadInput,
                        isInputDisabled ? styles.disabled : '',
                    )}
                    value={inputValue}
                    draggable={false}
                    onChange={() => {}}
                    disabled={isInputDisabled}
                    ref={inputRef}
                />
            }

            <div className={styles['keypad-buttons']} role="presentation">
                {dialButtons.map((button) => {
                    return (
                        <Button
                            key={button.text}
                            title={button.text}
                            className={classNames(
                                styles['keypad-button'],
                                isInputDisabled ? styles.disabled : '',
                            )}
                            aria-disabled={isInputDisabled}
                            onClick={(e) => {
                                e.stopPropagation()
                                handleOnKeypadButton(button.text)
                            }}
                        >
                            <span className={styles['keypad-text']}>
                                {button.iconImg ? (
                                    <img
                                        src={button.iconImg}
                                        alt={getButtonIconTitle(button)}
                                        title={getButtonIconTitle(button)}
                                        width="30px"
                                        height="30px"
                                    />
                                ) : (
                                    button.text
                                )}
                            </span>

                            {button.labelImg ? (
                                <img
                                    src={button.labelImg}
                                    alt={getButtonIconTitle(button)}
                                    title={getButtonIconTitle(button)}
                                    width="30px"
                                    height="30px"
                                />
                            ) : (
                                <span className={styles['keypad-label']}>{button.label}</span>
                            )}
                        </Button>
                    )
                })}
            </div>
        </div>
    )
}

const isValidCharacter = (text: string) => {
    const validChars = new Set(['+', '#', '*', '0'])
    return validChars.has(text) || /^\d+$/.test(text)
}

const getButtonIconTitle = (btn: DialButton) => {
    if (btn.title) return btn.title

    return getButtonTitle(btn)
}

const getButtonTitle = (btn: DialButton) => {
    const title = [btn.text]
    if (btn.label) {
        title.push('-', btn.label)
    }

    return title.join(' ')
}

export default Keypad
