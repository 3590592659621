import { createSelector } from 'reselect'

import {
    contactHistoryAttributeKey,
    contactHistoryAttributeValue,
} from 'store/contactHistory/contactHistory.constants'
import RootState from 'store/state'

export const selectContact = (state: RootState) => state.contact

export const selectContactId = createSelector(selectContact, (contact) => contact?.ID)

export const selectOriginalContactId = createSelector(
    selectContact,
    (contact) => contact?.originalContactID,
)

export const selectContactSubType = createSelector(selectContact, (contact) => contact?.subType)

export const selectContactAttributes = createSelector(
    selectContact,
    (contact) => contact?.attributes,
)

export const selectContactHistoryAttributeKey = createSelector(
    selectContactAttributes,
    (attributes) => attributes?.[contactHistoryAttributeKey],
)

export const selectContactHistoryAttributeValue = createSelector(
    selectContactAttributes,
    (attributes) => attributes?.[contactHistoryAttributeValue],
)
