import { SocialChatJsonMessage } from '@missionlabs/smartagent-service-chat/dist/types/socialChatJsonMessage'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EditorState } from 'draft-js'
import { DPAResult } from 'store/contact/contact.state'
import {
    ChatContactTypingAction,
    ChatState,
    DeleteAttachmentAction,
    DownloadAttachmentAction,
    DownloadConnectAttachmentAction,
    GetQuickRepliesAction,
    IChat,
    ISocialChatMessage,
    ReceiveChatMessageAction,
    SendAttachmentAction,
    SendChatMessageAction,
    SetAttachmentAction,
    SetAttachmentLoadingAction,
    SetMessagesAction,
    SetNextTranscriptTokenAction,
    SetReplyTextAction,
    SetTimestampAction,
    SetUnreadChatsAction,
    TransferChatAction,
    UpdateConnectionEditorAction,
    UpdateDPAChatAction,
    UpdateDPAResultChatAction,
    UpdateNoteAction,
    UpdateSocialEditorAction,
} from './chat.state'
import { isSystemMessage } from './chat.utils'

const initialState: ChatState = {
    connections: [],
    quickReplies: undefined,
    quickRepliesLoading: false,
}

export const chatSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        setSelectedChat(state, action: PayloadAction<string | null>) {
            return {
                ...state,
                selectedChatId: action.payload!,
            }
        },
        updateConnectionEditorContent(state, action: PayloadAction<UpdateConnectionEditorAction>) {
            return {
                ...state,
                connections: state.connections.map((c) =>
                    c.id === action.payload.id
                        ? { ...c, currentEditorContent: action.payload.content }
                        : c,
                ),
            }
        },
        updateSocialEditorContent(state, action: PayloadAction<UpdateSocialEditorAction>) {
            return {
                ...state,
                connections: state.connections.map((c) =>
                    c.id === action.payload.id
                        ? { ...c, currentSocialEditorContent: action.payload.content }
                        : c,
                ),
            }
        },
        sendChatMessage(state, action: PayloadAction<SendChatMessageAction>) {
            return {
                ...state,
                connections: state.connections.map((c) =>
                    c.id === action.payload.id
                        ? {
                              ...c,
                              currentEditorContent: action.payload.clearEditor
                                  ? EditorState.createEmpty()
                                  : c.currentEditorContent,
                          }
                        : c,
                ),
            }
        },
        clearUnread(state, action: PayloadAction<string>) {
            return {
                ...state,
                connections: state.connections.map((c) =>
                    c.id === action.payload ? { ...c, unread: 0 } : c,
                ),
            }
        },
        addChatConnection(state, action: PayloadAction<IChat>) {
            return {
                ...state,
                connections: [...state.connections, action.payload],
            }
        },
        chatACW(state, action: PayloadAction<string>) {
            return {
                ...state,
                connections: state.connections.map((c) =>
                    c.id === action.payload
                        ? {
                              ...c,
                              acw: true,
                              acwStartedAt: Date.now(),
                              status: connect.ContactStateType.ENDED,
                          }
                        : c,
                ),
            }
        },
        extendChatACW(state, action: PayloadAction<string>) {
            return {
                ...state,
                connections: state.connections.map((c) =>
                    c.id === action.payload ? { ...c, acwExtendedAt: Date.now() } : c,
                ),
            }
        },
        endChat(state, action: PayloadAction<string>) {
            return {
                ...state,
                connections: state.connections.map((c) =>
                    c.id === action.payload ? { ...c, status: connect.ContactStateType.ENDED } : c,
                ),
            }
        },
        chatConnected(state, action: PayloadAction<string>) {
            return {
                ...state,
                connections: state.connections.map((c) =>
                    c.id === action.payload
                        ? { ...c, status: connect.ContactStateType.CONNECTED }
                        : c,
                ),
            }
        },
        chatDisconnected(state, action: PayloadAction<string>) {
            return {
                ...state,
                connections: state.connections.map((c) =>
                    c.id === action.payload ? { ...c, status: connect.ContactStateType.ENDED } : c,
                ),
            }
        },
        removeChatConnection(state, action: PayloadAction<string>) {
            return {
                ...state,
                connections: state.connections.filter((c) => c.id !== action.payload),
            }
        },
        // This reducer need for removing chat connection from store without following .destroy()
        // api call which present in listener on REMOVE_CHAT_CONNECTION action
        clearChatConnection(state, action: PayloadAction<string>) {
            return {
                ...state,
                connections: state.connections.filter((c) => c.id !== action.payload),
            }
        },
        receiveChatMessage(state, action: PayloadAction<ReceiveChatMessageAction>) {
            const newMessages = [action.payload.msg].flat()
            const lastMesssage = newMessages.slice(-1)[0]
            return {
                ...state,
                connections: state.connections.map((c) =>
                    c.id === action.payload.id
                        ? {
                              ...c,
                              unread:
                                  lastMesssage.ParticipantRole === 'AGENT' ||
                                  isSystemMessage(
                                      (
                                          (lastMesssage as ISocialChatMessage)
                                              .content as SocialChatJsonMessage
                                      )?.type || lastMesssage.Type,
                                  )
                                      ? c.unread
                                      : c.unread + 1,
                              isTyping:
                                  lastMesssage.ParticipantRole === 'CUSTOMER' ? false : c.isTyping,
                              messages: [...c.messages, ...newMessages],
                          }
                        : c,
                ),
            }
        },
        chatMissed(state, action: PayloadAction<string>) {
            return {
                ...state,
                connections: state.connections.map((c) =>
                    c.id === action.payload
                        ? { ...c, status: connect.ContactStateType.MISSED, unread: 0 }
                        : c,
                ),
            }
        },
        completeChatACW(state, action: PayloadAction<string>) {
            return {
                ...state,
                connections: state.connections.filter((c) => c.id !== action.payload),
            }
        },
        recoverChats(state, action: PayloadAction<IChat[]>) {
            return {
                ...state,
                connections: action.payload,
            }
        },
        setUnreadChats(state, action: PayloadAction<SetUnreadChatsAction>) {
            return {
                ...state,
                connections: state.connections.map((c) =>
                    c.id === action.payload.id
                        ? {
                              ...c,
                              unread: action.payload.unread,
                          }
                        : c,
                ),
            }
        },
        chatContactIsTyping(state, action: PayloadAction<ChatContactTypingAction>) {
            return {
                ...state,
                connections: state.connections.map((c) =>
                    c.id === action.payload.id
                        ? {
                              ...c,
                              isTyping: action.payload.typing,
                          }
                        : c,
                ),
            }
        },
        markChatMessageReceiptsAsRead(state) {
            return {
                ...state,
            }
        },
        getQuickRepliesLoading(state) {
            return {
                ...state,
                quickRepliesLoading: true,
            }
        },
        getQuickReplies(state, action: PayloadAction<GetQuickRepliesAction>) {
            return {
                ...state,
                quickReplies: { ...state.quickReplies, ...action.payload },
                quickRepliesLoading: false,
            }
        },
        downloadAttachment(_state, _action: PayloadAction<DownloadAttachmentAction>) {},
        downloadConnectAttachmentFromTranscript(
            _state,
            _action: PayloadAction<DownloadConnectAttachmentAction>,
        ) {},
        transferChat(_state, _action: PayloadAction<TransferChatAction>) {},
        acceptChat(_state, _action: PayloadAction<string>) {},
        declineChat(_state, _action: PayloadAction<string>) {},
        chatSendTypingEvent(_state, _action: PayloadAction<string>) {},
        updateDPAChat(state, action: PayloadAction<UpdateDPAChatAction>) {
            return {
                ...state,
                connections: state.connections.map<IChat>((c) => {
                    if (c.id !== action.payload.id) return c
                    if (!c.DPA) return c

                    return {
                        ...c,
                        DPA: {
                            ...c.DPA,
                            checks: c.DPA?.checks.map((check: any) => {
                                if (check.label === action.payload.check.label)
                                    return action.payload.check
                                else return check
                            }),
                        },
                    }
                }),
            }
        },
        updateDPAResultChat(state, action: PayloadAction<UpdateDPAResultChatAction>) {
            return {
                ...state,
                connections: state.connections.map((c) => {
                    if (c.id !== action.payload.id) return c
                    if (!c.DPA) return c

                    return {
                        ...c,
                        DPA: {
                            ...c.DPA,
                            result: action.payload.result,
                        },
                    }
                }),
            }
        },
        updateAgentDPAResultChat(state, action: PayloadAction<UpdateDPAChatAction>) {
            return {
                ...state,
                connections: state.connections.map((c) => {
                    if (c.id !== action.payload.id) return c
                    if (!c.DPA) return c

                    return {
                        ...c,
                        DPA: {
                            ...c.DPA,
                            agentCheck: action.payload.check,
                            result: action.payload.check.passed
                                ? DPAResult['agent-overall-passed']
                                : DPAResult['failed'],
                        },
                    }
                }),
            }
        },
        setChatReplyText(state, action: PayloadAction<SetReplyTextAction>) {
            return {
                ...state,
                connections: state.connections.map((c) => {
                    if (c.id !== action.payload.id) return c
                    return {
                        ...c,
                        reply: action.payload,
                    }
                }),
            }
        },
        updateNote(state, action: PayloadAction<UpdateNoteAction>) {
            return {
                ...state,
                connections: state.connections.map((c) =>
                    c.id === action.payload.id
                        ? {
                              ...c,
                              'sa-acw-notes': action.payload.note,
                              'sa-notes': action.payload.note,
                          }
                        : c,
                ),
            }
        },
        sendAttachment(state, action: PayloadAction<SendAttachmentAction>) {
            return {
                ...state,
                connections: state.connections.map((c) =>
                    c.id === action.payload.id
                        ? {
                              ...c,
                              currentEditorContent: EditorState.createEmpty(),
                              attachments: {
                                  ...c.attachments,
                                  files: [],
                              },
                          }
                        : c,
                ),
            }
        },
        setCustomerInitiallyActive(state, action: PayloadAction<string>) {
            return {
                ...state,
                connections: state.connections.map((c) =>
                    c.id === action.payload
                        ? {
                              ...c,
                              customerActive: true,
                          }
                        : c,
                ),
            }
        },
        deleteAttachment(state, action: PayloadAction<DeleteAttachmentAction>) {
            return {
                ...state,
                connections: state.connections.map((c) =>
                    c.id === action.payload.id
                        ? {
                              ...c,
                              attachments: {
                                  ...c.attachments,
                                  files: c.attachments.files.filter(
                                      (_, i) => action.payload.index !== i,
                                  ),
                              },
                          }
                        : c,
                ),
            }
        },
        setAttachment(state, action: PayloadAction<SetAttachmentAction>) {
            return {
                ...state,
                connections: state.connections.map((c) =>
                    c.id === action.payload.id
                        ? {
                              ...c,
                              attachments: {
                                  ...c.attachments,
                                  files: action.payload.files.map((f) => ({
                                      file: f,
                                      loadingState: 'waiting',
                                  })),
                              },
                          }
                        : c,
                ),
            }
        },
        setAttachmentLoading(state, action: PayloadAction<SetAttachmentLoadingAction>) {
            return {
                ...state,
                connections: state.connections.map((c) =>
                    c.id === action.payload.id
                        ? {
                              ...c,
                              attachments: {
                                  ...c.attachments,
                                  files: c.attachments.files.map((file, i) =>
                                      action.payload.index === i ||
                                      action.payload.index === undefined
                                          ? { ...file, loadingState: action.payload.state }
                                          : file,
                                  ),
                              },
                          }
                        : c,
                ),
            }
        },
        setDeliveredTimestamp(state, action: PayloadAction<SetTimestampAction>) {
            return {
                ...state,
                connections: state.connections.map((c) =>
                    c.id === action.payload.chatId
                        ? {
                              ...c,
                              messages: c.messages.map((message) => ({
                                  ...message,
                                  deliveredTimestamp: message.deliveredTimestamp
                                      ? message.deliveredTimestamp
                                      : action.payload.timestamp,
                              })),
                          }
                        : c,
                ),
            }
        },
        setReadTimestamp(state, action: PayloadAction<SetTimestampAction>) {
            return {
                ...state,
                connections: state.connections.map((c) =>
                    c.id === action.payload.chatId
                        ? {
                              ...c,
                              messages: c.messages.map((message) => ({
                                  ...message,
                                  readTimestamp: message.readTimestamp
                                      ? message.readTimestamp
                                      : action.payload.timestamp,
                              })),
                          }
                        : c,
                ),
            }
        },
        setChatMessages(state, action: PayloadAction<SetMessagesAction>) {
            return {
                ...state,
                connections: state.connections.map((c) =>
                    c.id === action.payload.id
                        ? {
                              ...c,
                              messages: [...action.payload.messages, ...c.messages],
                              isLoadingTranscripts: false,
                          }
                        : c,
                ),
            }
        },
        setLoadingTranscripts(state, action: PayloadAction<string>) {
            return {
                ...state,
                connections: state.connections.map((c) =>
                    c.id === action.payload ? { ...c, isLoadingTranscripts: true } : c,
                ),
            }
        },
        setNextTranscriptToken(state, action: PayloadAction<SetNextTranscriptTokenAction>) {
            return {
                ...state,
                connections: state.connections.map(c =>
                    c.id === action.payload.id
                        ? { ...c, nextTranscriptToken: action.payload.token }
                        : c
                )
            }
        },
    },
})

export const {
    addChatConnection,
    chatACW,
    transferChat,
    extendChatACW,
    chatConnected,
    chatContactIsTyping,
    chatSendTypingEvent,
    acceptChat,
    declineChat,
    chatDisconnected,
    chatMissed,
    clearChatConnection,
    clearUnread,
    completeChatACW,
    deleteAttachment,
    endChat,
    getQuickReplies,
    getQuickRepliesLoading,
    markChatMessageReceiptsAsRead,
    receiveChatMessage,
    recoverChats,
    removeChatConnection,
    sendAttachment,
    sendChatMessage,
    setAttachment,
    setAttachmentLoading,
    setChatReplyText,
    setCustomerInitiallyActive,
    setDeliveredTimestamp,
    downloadAttachment,
    downloadConnectAttachmentFromTranscript,
    setReadTimestamp,
    setSelectedChat,
    setUnreadChats,
    updateAgentDPAResultChat,
    updateConnectionEditorContent,
    updateSocialEditorContent,
    updateDPAChat,
    updateDPAResultChat,
    updateNote,
    setChatMessages,
    setLoadingTranscripts,
    setNextTranscriptToken
} = chatSlice.actions

export type ChatAction = ReturnType<(typeof chatSlice.actions)[keyof typeof chatSlice.actions]>

export default chatSlice.reducer
