import { ChatMessage, MessageUserType } from '@missionlabs/smartagent-chat-components-lib'
import ArrowDownRight from 'images/arrow-down-right.svg'
import React from 'react'
import './source-message.scss'

interface Props {
    messageContent?: string
    messageLink?: string
    timestamp: number
}

const SourceMessage: React.FC<Props> = ({ messageContent, messageLink, timestamp }) => (
    <>
        <div className="source-message-title row middle between">
            <p>Original post:</p>
            {messageLink && (
                <a href={messageLink} target="_blank" rel="noreferrer">
                    Link to original post
                </a>
            )}
        </div>
        {messageContent && (
            <>
                <ChatMessage
                    sendMessage={() => {}}
                    clientType={MessageUserType.AGENT}
                    senderType={MessageUserType.CUSTOMER}
                    time={new Date(timestamp ?? 0).toISOString()}
                    body={{
                        type: 'TEXT',
                        text: messageContent,
                    }}
                />
                <div className="source-message-customer row start">
                    <img src={ArrowDownRight} alt="arrows" />
                    <p>Customer comment:</p>
                </div>
            </>
        )}
    </>
)

export default SourceMessage
