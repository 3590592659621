import useContact from 'hooks/redux/useContact'
import useGetDirectoryContact from 'hooks/useGetDirectoryContact'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectContactSubType } from 'store/contact/contact.selectors'
import { formatPhoneNumber } from 'utils'

interface Props {}

const Incoming: React.FC<Props> = () => {
    const contactSubType = useSelector(selectContactSubType)

    const contact = useContact()
    const [, name] = useGetDirectoryContact(contact?.customerEndpointAddress)
    const customer = contact?.customer

    const isWebCall = contactSubType === 'connect:WebRTC'

    return (
        <div className="dialler-incoming row between">
            <div className="call-details row column">
                <span className="remote-number">
                    {isWebCall ? (
                        'Web call'
                    ) : contact?.customerEndpointAddress ? (
                        formatPhoneNumber(contact.customerEndpointAddress)
                    ) : (
                        'UNKNOWN'
                    )}
                </span>
                {customer?.name || name ? (
                    <span className="remote-name xsm-mar-top">{customer?.name ?? name}</span>
                ) : null}
            </div>
        </div>
    )
}

export default Incoming
