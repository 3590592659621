import type { SADropdownOption } from '@missionlabs/smartagent-app-components/dist/Dropdown'
import type { AllThreadsAndMessages } from '@missionlabs/smartagent-service-thread'
import type { ThunkDispatch } from '@reduxjs/toolkit'
import { IEmailAttachment } from 'components/EmailMessage/types'
import { getActionAudit } from 'services/api/api.automated-rules'
import * as ContactAPI from 'services/api/api.contact'
import { getDownloadURL } from 'services/api/api.thread'
import ContactState from 'store/contact/contact.state'
import RootState from 'store/state'
import { IInteraction } from 'views/Metrics/HistoricMetrics/types'
import {
    getThreadMessageGroups,
    transformContacts,
    transformSMSCampaignETRToInteraction,
    transformTaskContacts,
} from 'views/Metrics/HistoricMetrics/utils'

import * as ContactSearchActions from './contactSearch.reducer'
import { getChannelGroup } from './contactSearch.utils'

import type { IETR } from '@missionlabs/smartagent-lib-shared'

export const {
    loadingInteractions,
    resetFormParams,
    setAgent,
    setAppContactId,
    setBargedOnly,
    setCallDurationEndTime,
    setCallDurationStartTime,
    setChatEmail,
    setChatOrderNumber,
    setContactID,
    setCustomerPhone,
    setEmail,
    setEmailSearchType,
    setEndDate,
    setEndTime,
    setExternalID,
    setFormParams,
    setHoldDurationEndTime,
    setHoldDurationStartTime,
    setInitiationMethod,
    setInteractions,
    setKeyword,
    setQueue,
    setSelectedInteraction,
    setSocialHandle,
    setStartDate,
    setStartTime,
    setUniqueSocialID,
} = ContactSearchActions

export const setChannel = (channel: SADropdownOption<any>) => {
    return ContactSearchActions.setChannel({
        ...channel,
        group: getChannelGroup(channel.data),
    })
}

export const fetchInteractions = (queryString: string) => {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, ContactSearchActions.ContactSearchAction>,
        getState: () => RootState,
    ) => {
        try {
            const { app, auth } = getState()

            const parseQuerystring = (qs: string) => {
                return qs.split('&').reduce((acc, val) => {
                    return { ...acc, [val.split('=')[0]]: val.split('=')[1] }
                }, {})
            }

            const { ID: companyID, instance: { ID: instanceID } = {} } = app
            const { token } = auth

            if (!instanceID) throw new Error('No instance ID found')
            if (!token) throw new Error('No token found')

            dispatch(loadingInteractions(true))

            const { data: contacts } = await ContactAPI.getContacts(
                companyID,
                instanceID,
                token,
                queryString,
            )

            const taskContacts: ContactState[] = []
            const otherContacts: ContactState[] = []

            // Split contacts into two arrays with one array containing task contacts and the other array containing the remaining contacts
            contacts.forEach((contact) =>
                contact.channel === 'TASK'
                    ? taskContacts.push(contact)
                    : otherContacts.push(contact),
            )

            const params: Record<string, string> = parseQuerystring(queryString)

            const eventTraceRecords: IETR[] = []
            if (params['channel'] === 'SMS+DM') {
                eventTraceRecords.push(...(await ContactAPI.getEventTraceRecords(queryString)))
            }
            const etrInteractions = eventTraceRecords.map(
                transformSMSCampaignETRToInteraction,
            ) as IInteraction[]

            const threadMessageGroups = await getThreadMessageGroups(taskContacts)

            // If this is a keyword search then provide the keyword so that we can filter the threads as well as the taskContacts
            const filteredThreadMessageGroups = params['keyword']
                ? (threadMessageGroups.map((thread) => {
                      return {
                          ...thread,
                          messages: thread.messages.filter((message) => {     
                            const keywordDecoded = decodeURIComponent(
                                params['keyword']?.replace(/\+/g, ' '),
                            )?.toLowerCase()
                              return (
                                  message.content.toLowerCase().includes(keywordDecoded) ||
                                  message.subject?.toLowerCase().includes(keywordDecoded)
                              )
                          }),
                      }
                  }) as AllThreadsAndMessages[])
                : [...threadMessageGroups]

            const taskInteractions = transformTaskContacts(
                taskContacts,
                filteredThreadMessageGroups,
            )
            const otherInteractions = transformContacts(otherContacts)
            const allInteractions = taskInteractions.concat(otherInteractions, etrInteractions)
            dispatch(setInteractions(allInteractions))
        } catch (error) {
            console.error('Error fetching interactions: ', error)

            dispatch(setInteractions([]))
        }
    }
}

export function fetchAutomatedRulesAudit(interactionID: string, actionAuditID: string | undefined) {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, ContactSearchActions.ContactSearchAction>,
        getState: () => RootState,
    ) => {
        if (!actionAuditID) {
            dispatch(
                ContactSearchActions.setAutomatedRulesAudit({
                    interactionID,
                    automatedRulesAudit: [],
                }),
            )
            return
        }

        try {
            const { app, auth } = getState()

            const { ID: companyID, instance: { ID: instanceID } = {} } = app
            const { token } = auth

            if (!companyID) throw new Error('No company ID found')
            if (!instanceID) throw new Error('No instance ID found')
            if (!token) throw new Error('No token found')

            const response = await getActionAudit(companyID, instanceID, token, actionAuditID)

            dispatch(
                ContactSearchActions.setAutomatedRulesAudit({
                    interactionID,
                    automatedRulesAudit: response.data,
                }),
            )
        } catch (error) {
            console.error('Error fetching automated rules audit data: ', error)
            dispatch(
                ContactSearchActions.setAutomatedRulesAudit({
                    interactionID,
                    automatedRulesAudit: [],
                }),
            )
        }
    }
}

export function fetchQueuedTasksAudit(interactionID: string) {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, ContactSearchActions.ContactSearchAction>,
        getState: () => RootState,
    ) => {
        try {
            const { app, auth } = getState()

            const { ID: companyID, instance: { ID: instanceID } = {} } = app
            const { token } = auth

            if (!companyID) throw new Error('No company ID found')
            if (!instanceID) throw new Error('No instance ID found')
            if (!token) throw new Error('No token found')

            const response = await ContactAPI.getContactAudit(
                companyID,
                instanceID,
                token,
                interactionID,
            )

            dispatch(
                ContactSearchActions.setQueuedTasksAudit({
                    interactionID,
                    queuedTasksAudit: response.data,
                }),
            )
        } catch (error) {
            console.error('Error fetching queued tasks audit data: ', error)
            dispatch(
                ContactSearchActions.setQueuedTasksAudit({
                    interactionID,
                    queuedTasksAudit: [],
                }),
            )
        }
    }
}

export function fetchAttachmentUrls(
    attachments: IEmailAttachment[],
    messageId: string,
    threadId: string,
) {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, ContactSearchActions.ContactSearchAction>,
        getState: () => RootState,
    ) => {
        try {
            const { app, auth } = getState()

            const { ID: instanceID } = app.instance ?? {}
            const { token } = auth

            if (!instanceID) throw new Error('No instance ID found')
            if (!token) throw new Error('No token found')

            const newAttachments: IEmailAttachment[] = await Promise.all(
                attachments.map(async (attachment) => {
                    const { s3Key, filename } = attachment
                    let url = ''
                    try {
                        url = (await getDownloadURL(threadId, messageId, encodeURI(s3Key))).url
                    } catch (error) {
                        console.error(`Error retrieving URL for ${s3Key}/${filename}: ${error}`)
                    }

                    return { ...attachment, s3URL: url }
                }),
            )
            dispatch(
                ContactSearchActions.addTransformedAttachments({
                    interactionID: messageId,
                    transformedAttachments: newAttachments,
                }),
            )
        } catch (error) {
            console.error('Error fetching attachment S3 urls:', error)

            dispatch(
                ContactSearchActions.addTransformedAttachments({
                    interactionID: messageId,
                    transformedAttachments: attachments,
                }),
            )
        }
    }
}

export const fetchInteractionAttributes = (interaction: IInteraction) => {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, ContactSearchActions.ContactSearchAction>,
    ) => {
        const { ID: interactionID, attributes: originalAttributes } = interaction

        try {
            const { attributes } = await ContactAPI.getContact(interactionID)

            dispatch(
                ContactSearchActions.setInteractionAttributes({
                    interactionID,
                    attributes,
                }),
            )
        } catch (error) {
            console.error('Error fetching interaction attributes:', error)

            dispatch(
                ContactSearchActions.setInteractionAttributes({
                    interactionID,
                    attributes: originalAttributes,
                }),
            )
        }
    }
}
