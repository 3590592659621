import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectAppConfig } from 'store/app/app.selectors'
import { selectAppSettingsConfigurations } from 'store/settings/settings.selectors'
import AfterCallWorkView from './afterCallWork'
import './afterCallWork.scss'

type Props = {
    completeCallACW: () => void
}

const AfterCallWork: React.FC<Props> = ({ completeCallACW }) => {
    const [start] = useState<Date>(new Date())

    const appConfig = useSelector(selectAppConfig)
    const appSettingsConfigurations = useSelector(selectAppSettingsConfigurations)

    const handleTimeout = useCallback(() => {
        completeCallACW()
    }, [completeCallACW])

    return (
        <AfterCallWorkView
            start={start}
            appConfig={appConfig}
            appConfigurations={appSettingsConfigurations ?? {}}
            onTimeout={handleTimeout}
        />
    )
}

export default AfterCallWork
