import { Button, Form, Input } from '@missionlabs/smartagent-app-components'
import { H } from 'react-accessible-headings'
import InfoErrorIcon from 'images/info-error.svg'
import { PureComponent } from 'react'
import { connect } from 'react-redux'
import { ClipLoader } from 'react-spinners'
import RootState from 'store/state'
import { submitMFA } from 'store/user/user.actions'

interface Props extends ReturnType<typeof mapStateToProps> {
    submitMFA: typeof submitMFA
}

class MFARequired extends PureComponent<Props> {
    readonly state = {
        code: '',
    }

    onSubmit = () => {
        this.props.submitMFA(this.state.code)
    }

    render() {
        const { authError, authenticating, mfa } = this.props
        return (
            <Form onSubmit={this.onSubmit} className="sa-login-form">
                <div className="row between middle">
                    <H className="title">Authentication Code</H>

                    {authError && (
                        <div className="sa-login-error" aria-live="polite">
                            <img alt="Error" src={InfoErrorIcon} /> {authError}
                        </div>
                    )}
                </div>
                <div className="sa-login-text">
                    Please enter the code sent to the{' '}
                    {mfa.type === 'SMS' ? 'phone number' : 'email address'} {mfa.destination}
                </div>
                <Input
                    className="md-mar-bottom"
                    onChange={(code: any) => this.setState({ code })}
                    value={this.state.code}
                    required
                    type="text"
                    label="Authentication Code"
                />
                <Button round large styling="primary">
                    {authenticating ? <ClipLoader color="#fff" size={20} /> : 'Login'}
                </Button>
            </Form>
        )
    }
}

function mapStateToProps(state: RootState) {
    return {
        authenticating: state.auth.authenticating,
        authError: state.auth.authError,
        mfa: state.auth.mfa!,
    }
}

export default connect(mapStateToProps, { submitMFA })(MFARequired)
