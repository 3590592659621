import React, { useEffect, useState } from 'react'
import { IWaveformChannel } from './AudioPlayer'
import { Blip } from './Blip'

interface Props {
    channel?: IWaveformChannel
    currentTimePercentage: number
    currentTimeSeconds: number
    maxTimeSeconds: number
    onSeek(e: React.MouseEvent | React.KeyboardEvent, seek?: boolean): Promise<number | undefined>
    width: number
    id: number
    isPlaying: boolean
}

export const Waveform: React.FC<Props> = ({
    channel,
    currentTimePercentage,
    currentTimeSeconds,
    maxTimeSeconds,
    onSeek,
    width,
    id,
    isPlaying
}) => {
    if (!channel) {
        return (
            <div className="track-player-container">
                <div
                    className="track-player no-waveform"
                    onClick={(e) => onSeek(e, true)}
                    role="presentation"
                >
                    <div className="line-container">
                        <div className="line" style={{ width: `${currentTimePercentage}%` }} />
                    </div>
                </div>
            </div>
        )
    }

    const formattedSeconds = () =>
        new Date(Math.round(currentTimeSeconds) * 1000).toISOString().substring(11, 19)
    const [ariaSeekerTextValue, setAriaSeekerTextValue] = useState<string>(formattedSeconds())

    useEffect(() => {
        if (!isPlaying) {
            setAriaSeekerTextValue(formattedSeconds())
        }
    }, [isPlaying, currentTimePercentage])

    const blipWidth = width >= 1600 ? 2 : 1.5
    const blipMargin = width / channel.data.length - blipWidth

    return (
        <div
            className="waveform"
            role="slider"
            onClick={(e) => onSeek(e, true)}
            onKeyDown={async (e) => await onSeek(e, true)}
            tabIndex={0}
            aria-valuenow={Math.round(currentTimeSeconds)}
            aria-valuemin={0}
            aria-valuemax={Math.round(maxTimeSeconds)}
            aria-valuetext={ariaSeekerTextValue}
        >
            {channel.data.map((value, i) => (
                <Blip
                    id={id}
                    width={blipWidth}
                    marginRight={blipMargin}
                    key={i}
                    value={value}
                    max={channel.max}
                />
            ))}
        </div>
    )
}
