import { AudioPlayer, VideoPlayer, Button } from '@missionlabs/smartagent-app-components'
import {
    ChatMessage,
    MessageUserType,
    TypingIndicator,
} from '@missionlabs/smartagent-chat-components-lib'
import {
    SAMessageType,
    SendMessageFunction,
} from '@missionlabs/smartagent-chat-components-lib/dist/ChatMessage/chat-message.types'
import {
    AttachmentS3,
    SocialChatJsonMessage,
    SocialChatJsonMessageType,
} from '@missionlabs/smartagent-service-chat/dist/types/socialChatJsonMessage'
import classnames from 'classnames'
import SourceMessage from 'components/SourceMessage/SourceMessage'
import useChatConnections from 'hooks/redux/useChatConnections'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getS3AttachmentUrl } from 'services/api/api.chat'
import { IChatMessage, ISocialChatMessage } from 'store/chat/chat.state'
import RootState from 'store/state'
import { removeBannerMessages } from 'utils'
import DownloadAttachment from 'views/Tasks/Task/Thread/components/DownloadAttachment'
import AttachmentChatMessage from '../ChatMessages/ChatMessage/AttachmentChatMessage'
import MetaChatMessage from '../ChatMessages/ChatMessage/MetaChatMessage'
import { useUnreadMessages } from '../ChatMessages/useUnreadMessages'
import MessageDateGrouped from './MessageDateGrouped'
import SenderNameGrouped from './SenderNameGrouped'
import './social-chat-messages.scss'
import {
    doMessagesContainNonSystemMessage,
    getFileLabelFromContentType,
    getPreviousNonSystemMessage,
    isInteractiveMessage,
    removeDuplicateParticipantJoinedMessages,
    sortMessagesWithOriginalTimestamp,
} from './utils'
import { setChatMessages, setNextTranscriptToken } from "store/chat/chat.reducer"
import { getChatTranscript } from "store/middleware/ccp/ccp.utils"
import arrowUp from '../../../../images/icon-load-older-messages.svg'
import { Loader } from 'components'
import { createNotification } from 'store/notification/notification.actions'
import { SourceMessage as ISourceMessage } from '@missionlabs/smartagent-service-chat/dist/types/socialChatMessage'

interface Props {
    chatMessagesProp?: ISocialChatMessage[]
    className?: string
}

const getCustomerReadReceiptForMessage = (
    message: IChatMessage,
    chatMessages: (ISocialChatMessage | IChatMessage)[],
) => {
    const allJsonMessages = chatMessages.filter(
        (msg) => msg.content && typeof msg.content !== 'string',
    )
    const customerReadReceipt = allJsonMessages.find(
        (msg) =>
            (msg.content as SocialChatJsonMessage).type ===
                SocialChatJsonMessageType.MESSAGE_READ &&
            new Date(msg.AbsoluteTime).getTime() > new Date(message.AbsoluteTime).getTime(),
    )
    return customerReadReceipt
}

export const renderConnectChatMessage = (
    chat: IChatMessage & { contactID?: string },
    sendMessage: (message: string) => void,
    chatMessages: (ISocialChatMessage | IChatMessage)[],
) => {
    const customerReadReceipt = getCustomerReadReceiptForMessage(chat, chatMessages)

    if (!chat.ContentType && chat.Type === 'ATTACHMENT') {
        return (
            <ChatMessage
                sendMessage={sendMessage}
                clientType={MessageUserType.AGENT}
                senderType={chat.ParticipantRole as MessageUserType}
                time={chat.AbsoluteTime}
                isMarkdownEnabled={true}
                receiptsActive
                deliveredTimestamp={chat.AbsoluteTime}
                readTimestamp={customerReadReceipt?.AbsoluteTime}
            >
                <AttachmentChatMessage
                    attachmentId={chat.Attachments![0].AttachmentId}
                    attachmentName={chat.Attachments![0].AttachmentName}
                    contactID={chat.contactID}
                />
            </ChatMessage>
        )
    }

    switch (chat.ContentType) {
        case 'text/markdown':
        case 'text/plain':
            return (
                <ChatMessage
                    sendMessage={sendMessage}
                    clientType={MessageUserType.AGENT}
                    senderType={chat.ParticipantRole as MessageUserType}
                    time={chat.AbsoluteTime}
                    body={{
                        type: 'TEXT',
                        text: chat.content as string,
                    }}
                    isMarkdownEnabled={true}
                    receiptsActive
                    deliveredTimestamp={chat.AbsoluteTime}
                    readTimestamp={customerReadReceipt?.AbsoluteTime}
                />
            )
        case 'application/vnd.amazonaws.connect.event.participant.joined':
        case 'application/vnd.amazonaws.connect.event.participant.left':
        case 'application/vnd.amazonaws.connect.event.chat.ended':
        case 'application/vnd.amazonaws.connect.event.transfer.failed':
        case 'application/vnd.amazonaws.connect.event.transfer.succeeded':
            return (
                <ChatMessage
                    sendMessage={sendMessage}
                    senderType={chat.ParticipantId as MessageUserType}
                    clientType={MessageUserType.AGENT}
                    time={chat.AbsoluteTime}
                    isMarkdownEnabled={true}
                >
                    <MetaChatMessage {...chat} />
                </ChatMessage>
            )

        default:
            return chat.ContentType
    }
}

interface SocialChatMessageProps {
    chat: ISocialChatMessage
    chatMessages: ISocialChatMessage[]
    companyID: string
}
export const SocialChatMessage: React.FC<SocialChatMessageProps> = ({
    chat,
    chatMessages,
    companyID,
}) => {
    const [s3AttachmentUrl, setS3AttachmentUrl] = useState<string | undefined>()
    const [isS3AttachmentUrlLoading, setIsS3AttachmentUrlLoading] = useState(false)

    useEffect(() => {
        const message = chat.content as SocialChatJsonMessage
        if (!s3AttachmentUrl && (message?.attachment as AttachmentS3)?.s3Key) {
            const fetchS3AttachmentUrl = async () => {
                setIsS3AttachmentUrlLoading(true)
                const attachmentUrl = await getS3AttachmentUrl(
                    encodeURIComponent((message.attachment as AttachmentS3).s3Key),
                    companyID,
                ).catch((e) => {
                    console.error('Failed to getS3AttachmentUrl', e)
                    return undefined
                })
                setS3AttachmentUrl(attachmentUrl)
                setIsS3AttachmentUrlLoading(false)
            }

            fetchS3AttachmentUrl()
        }
    }, [chat.content])

    const jsonMessage = chat.content as SocialChatJsonMessage
    const allJsonMessages = chatMessages.filter(
        (msg) => msg.content && typeof msg.content !== 'string',
    )
    const isMessageUnsent = !!allJsonMessages.find(
        (msg) =>
            (msg.content as SocialChatJsonMessage).type === SocialChatJsonMessageType.UNSEND &&
            (msg.content as SocialChatJsonMessage).externalMessageID ===
                jsonMessage.externalMessageID,
    )
    const content = isMessageUnsent ? 'User has deleted this message' : jsonMessage.content

    switch (jsonMessage.type) {
        case SocialChatJsonMessageType.MESSAGE:
            return (
                <ChatMessage
                    sendMessage={() => {}}
                    clientType={MessageUserType.AGENT}
                    senderType={chat.ParticipantRole as MessageUserType}
                    time={chat.AbsoluteTime}
                    body={{
                        type: 'TEXT',
                        text: content!,
                    }}
                />
            )
        case SocialChatJsonMessageType.ATTACHMENT:
        case SocialChatJsonMessageType.ATTACHMENT_S3:
            const { attachment, type } = jsonMessage
            if (!attachment) return null

            const { contentType } = attachment
            const isAudio = contentType.startsWith('audio')
            const isVideo = contentType.startsWith('video')
            const getUrl = () =>
                'url' in attachment
                    ? Promise.resolve(attachment.url)
                    : getS3AttachmentUrl(encodeURIComponent(attachment.s3Key), companyID)

            if (isAudio) {
                return <AudioPlayer getUrl={getUrl} timestamp={chat.AbsoluteTime} />
            }

            if (isVideo) {
                return (
                    <VideoPlayer
                        getUrl={getUrl}
                        timestamp={chat.AbsoluteTime}
                        reactPlayerClassName="sa-chat-message-video-player"
                    />
                )
            }

            if (type === SocialChatJsonMessageType.ATTACHMENT_S3) {
                const fileLabel = getFileLabelFromContentType(contentType)
                return (
                    <ChatMessage
                        sendMessage={() => {}}
                        clientType={MessageUserType.AGENT}
                        senderType={chat.ParticipantRole as MessageUserType}
                        time={chat.AbsoluteTime}
                        body={{
                            type: 'FILE_LINK',
                            src: s3AttachmentUrl || '',
                            label: fileLabel,
                            fileType: attachment.contentType,
                            isImage: attachment.contentType.includes('image'),
                            isLoading: isS3AttachmentUrlLoading,
                        }}
                    />
                )
            }

            if (type === SocialChatJsonMessageType.ATTACHMENT) {
                return (
                    <div className="sa-chat-message-attachment">
                        <DownloadAttachment attachment={attachment} isSocialAttachment={true} />
                    </div>
                )
            }
            break

        default:
            return null
    }

    return null
}

export const renderChatMessage = (
    chatMessage: IChatMessage,
    chatMessages: (ISocialChatMessage | IChatMessage)[],
    sendMessage?: SendMessageFunction,
) => {
    const sendMessageFn = sendMessage ?? (() => {})

    const customerReadReceipt = getCustomerReadReceiptForMessage(chatMessage, chatMessages)

    return (
        <ChatMessage
            sendMessage={sendMessageFn}
            clientType={MessageUserType.AGENT}
            time={chatMessage.AbsoluteTime}
            senderType={chatMessage.ParticipantRole as MessageUserType}
            body={chatMessage.content as unknown as SAMessageType}
            isMarkdownEnabled={true}
            key={chatMessage.Id}
            receiptsActive
            deliveredTimestamp={chatMessage.deliveredTimestamp || chatMessage.AbsoluteTime}
            readTimestamp={chatMessage.readTimestamp || customerReadReceipt?.AbsoluteTime}
        />
    )
}

const renderMessage = (
    message: ISocialChatMessage | IChatMessage,
    sendMessage: (message: string) => void,
    chatMessages: ISocialChatMessage[],
) => {
    if (typeof message.content === 'string' || !message.content) {
        return renderConnectChatMessage(message as IChatMessage, sendMessage, chatMessages)
    }

    if (
        (typeof message.content === 'object' &&
        (message.content as SAMessageType).type === 'FILE_LINK') || isInteractiveMessage(message)
        ) {
        return renderChatMessage(message as IChatMessage, chatMessages)
    }

    const companyID = useSelector<RootState>((state) => state.app.ID) as string
    return (
        <SocialChatMessage
            chat={message as ISocialChatMessage}
            chatMessages={chatMessages}
            companyID={companyID}
        />
    )
}

const SocialChatMessages: React.FC<Props> = ({ chatMessagesProp, className }) => {
    const dispatch = useDispatch()
    const selectedChat = useChatConnections("selected")
    const messagesContainerRef = useUnreadMessages(
        selectedChat?.id,
        selectedChat?.messages,
        selectedChat?.isTyping,
    )?.messagesContainer
    const [isLoadingMoreTranscript, setIsLoadingMoreTranscript] = useState<boolean>(false)
    const [localMessages, setLocalMessages] = useState<ISocialChatMessage[]>([])
    const [localUnread, setLocalUnread] = useState<number>(0)
    const [localIsTyping, setLocalIsTyping] = useState<boolean>(false)
    const [localSourceMessage, setLocalSourceMessage] = useState<ISourceMessage | undefined>(undefined)

    // When we pass in chatMessages as a prop e.g. when rendering this in ContactSearch,
    // there might not be an active chat connection, hence the check and assignments below.
    useEffect(() => {
        if (chatMessagesProp) {
            setLocalMessages(chatMessagesProp)
            setLocalUnread(0)
            setLocalIsTyping(false)
            setLocalSourceMessage(undefined)
        } else if(selectedChat){
            const { messages, unread, isTyping, sourceMessage} = selectedChat
            setLocalMessages(messages as ISocialChatMessage[])
            setLocalUnread(unread)
            setLocalIsTyping(isTyping)
            setLocalSourceMessage(sourceMessage)
        } 
    }, [chatMessagesProp, selectedChat?.id, selectedChat?.messages])

    const messagesTransformed = useMemo(() => {
        let messages
        messages = removeDuplicateParticipantJoinedMessages(localMessages)
        messages = removeBannerMessages(messages) as ISocialChatMessage[]
        sortMessagesWithOriginalTimestamp(messages)
        return messages
    }, [localMessages])

    const sendMessage = (message: string) => {
        dispatch(sendMessage(message))
    }

    const getSelectectedContact = (agent: connect.Agent) => {
        const contacts = agent.getContacts()
        return contacts.find((c) => c.contactId === selectedChat?.id)
    }

    const loadMoreChatTranscripts = async () => {
        try {
            await new Promise<void>((resolve, reject) => {
                connect.agent(async (agent) => {
                    try {
                        const selectedContact = getSelectectedContact(agent)
                        if (selectedContact) {
                            const { messages, nextToken } = await getChatTranscript(
                                selectedContact,
                                selectedChat?.messages,
                                selectedChat?.nextTranscriptToken,
                                true,
                            )
                            dispatch(setChatMessages({ id: selectedContact.contactId, messages }))
                            dispatch(
                                setNextTranscriptToken({
                                    id: selectedContact.contactId,
                                    token: nextToken,
                                }),
                            )
                        }
                        resolve()
                    } catch (error) {
                        reject(error)
                    }
                })
            })
        } catch (e) {
            console.error(e)
            dispatch(
                createNotification({
                    type: 'error',
                    header: 'Error',
                    text: 'Something went wrong while loading more transcripts.',
                }),
            )
        }
    }

    const handleLoadMoreTranscriptClick = async () => {
        if (!selectedChat) {
            return
        }
        setIsLoadingMoreTranscript(true)
        await loadMoreChatTranscripts()
        setIsLoadingMoreTranscript(false)
    }

    const [oldMessages, newMessages] = useMemo(() => {
        if (!localUnread) return [messagesTransformed, []]

        return [messagesTransformed.slice(0, -localUnread), messagesTransformed.slice(-localUnread)]
    }, [messagesTransformed, localUnread])

    return (
        <div ref={messagesContainerRef} className={className || 'sa-chat-messages'}>
            {!chatMessagesProp && selectedChat?.nextTranscriptToken ? <Button
                onClick={handleLoadMoreTranscriptClick}
                disabled={isLoadingMoreTranscript}
                className="sa-load-old-messages-button"
                type={'button'}
            >
                {isLoadingMoreTranscript ? (
                    <>
                        <Loader
                            className="sa-load-older-messages-loader"
                            loaderType={'clip'}
                            color={'white'}
                            size={16}
                        />
                        <span>Loading messages</span>
                    </>
                ) : (
                    <>
                        <img src={arrowUp} alt={'arrow-up-icon'}></img>
                        <span>Load older messages</span>
                    </>
                )}
            </Button> : null }
            {localSourceMessage && (
                <SourceMessage
                    messageContent={localSourceMessage.content}
                    timestamp={localSourceMessage.timestamp}
                    messageLink={localSourceMessage.link}
                />
            )}

            {oldMessages.map((msg, index) => (
                <MessageWithNameAndDate
                    key={msg.Id}
                    message={msg}
                    messages={[...oldMessages, ...newMessages]}
                    index={index}
                    sendMessage={sendMessage}
                />
            ))}

            {!!newMessages.length && doMessagesContainNonSystemMessage(newMessages) && (
                <>
                    {!!oldMessages.length && <div className="sa-new-messages-divider">NEW</div>}
                    {newMessages.map((msg, index) => (
                        <MessageWithNameAndDate
                            key={msg.Id}
                            message={msg}
                            messages={[...oldMessages, ...newMessages]}
                            index={index}
                            sendMessage={sendMessage}
                        />
                    ))}
                </>
            )}

            {localIsTyping && (
                <div className="sa-chat-message-wrapper">
                    <TypingIndicator senderType={MessageUserType.CUSTOMER} />
                </div>
            )}
        </div>
    )
}

interface MessageWithNameAndDateProps {
    message: ISocialChatMessage
    messages: ISocialChatMessage[]
    index: number
    sendMessage: (message: string) => void
}

const MessageWithNameAndDate: React.FC<MessageWithNameAndDateProps> = ({
    message,
    messages,
    index,
    sendMessage,
}) => (
    <>
        <MessageDateGrouped
            messageTimestamp={new Date(message.AbsoluteTime).getTime()}
            messages={messages}
            className="sa-chat-message-date-grouped"
        />
        <SenderNameGrouped
            customClassName={classnames({
                'sa-chat-message-sender-name-grouped': true,
                'sa-chat-message-sender-name-grouped-right': message.ParticipantRole !== 'CUSTOMER',
            })}
            chatMessage={message}
            previousChatMessage={getPreviousNonSystemMessage(messages, index)}
        />
        {renderMessage(message, sendMessage, messages)}
    </>
)

export default SocialChatMessages
