import { Box } from '@missionlabs/smartagent-app-components'
import useSmartAgentAPI from 'hooks/useSmartAgentAPI'
import Search from 'images/search.svg'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { BeatLoader } from 'react-spinners'
import { getComprehension } from 'services/api/api.contact'
import RootState from 'store/state'
import ComprehensionInfo from './ComprehensionInfo'
import { H } from 'react-accessible-headings'

import useInterval from 'hooks/useInterval'
import './call-comprehension.scss'

interface Props {
    contactID?: string
    disconnectTimestamp?: number
    connectedToAgentTimestamp?: number
    widgetTitle?: string
}

interface ISentiment {
    Sentiment: string
    SentimentScore: ISentimentScore
}
interface ISentimentScore {
    Positive: number
    Negative: number
    Neutral: number
    Mixed: number
}

interface IComprehension {
    keywords: string[]
    sentiment: ISentiment
}

interface ICallState {
    contactID: string
    disconnectTimestamp?: number
    connectedToAgentTimestamp?: number
}

export const CallComprehension: React.FC<Props> = (props) => {
    const { contactID, disconnectTimestamp, connectedToAgentTimestamp } = useSelector<
        RootState,
        ICallState
    >((state) => ({
        contactID: props.contactID ?? state.contact?.ID ?? '',
        disconnectTimestamp: props.disconnectTimestamp || state.contact?.disconnectTimestamp,
        connectedToAgentTimestamp:
            props.connectedToAgentTimestamp || state.contact?.connectedToAgentTimestamp,
    }))

    const [error, loading, response, request] = useSmartAgentAPI<IComprehension, RootState>(
        async (state) =>
            getComprehension(
                state.app.ID,
                state.app.instance!.ID,
                state.auth.token!,
                contactID,
                disconnectTimestamp,
                connectedToAgentTimestamp,
            ),
    )

    const accepted = response?.status === 202

    useInterval(() => {
        if (response?.status === 200 || loading) return
        if (response?.status === 202) return request()
    }, 15_000)

    useEffect(() => {
        request()
        // eslint-disable-next-line
    }, [contactID])

    const headerTitle = props.widgetTitle ? props.widgetTitle : 'Voice analytics'

    return (
        <Box
            aria-busy={loading || accepted}
            className="comprehension"
            hidden={!!error}
            alt
            collapse
            boxLabel={headerTitle}
            header={<H>{headerTitle}</H>}
        >
            {loading ? (
                <div className="sa-loading">
                    <BeatLoader color="#000" />
                </div>
            ) : !!error ? (
                <div className="sa-loading no-data">
                    <img src={Search} alt="Search" width="24px" height="24px" />
                    <p>Unable to retrieve analytics data</p>
                </div>
            ) : accepted ? (
                <div className="sa-transcript-no-data">
                    <BeatLoader color="#000" />
                    <p>We are currently running voice analytics on this conversation</p>
                </div>
            ) : (
                response?.status === 200 && <ComprehensionInfo {...response!.data} />
            )}
        </Box>
    )
}
