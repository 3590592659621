import type { MiddlewareAPI } from '@reduxjs/toolkit'
import { differenceInSeconds } from 'date-fns'
import { EditorState } from 'draft-js'
import { AppFeatures, FeatureConfig, IFeature } from 'store/app/app.features'
import * as callActions from 'store/call/call.actions'
import * as chatActions from 'store/chat/chat.actions'
import { IChat, IChatMessage } from 'store/chat/chat.state'
import { MessageSources, SocialMediaPlatform } from 'store/chat/chat.utils'
import ContactState, { ICallContactAttributes } from 'store/contact/contact.state'
import {
    getCustomerInputs,
    getDPAState,
    getTagsFromContactAttributes,
} from 'store/contact/contact.utils'
import { MAX_VOICE_CONNECTIONS } from 'store/middleware/ccp/ccp.constants'
import RootState from 'store/state'
import { ITask, TaskChannel } from 'store/tasks/tasks.state'
import { setAvailable, setState } from 'store/user/user.actions'
import UserState from 'store/user/user.state'

import type { Channel } from '@missionlabs/smartagent-lib-shared/build/form-templates/form-template.schema'
import type { CallConnection } from 'store/callV2/callV2.state'

export const transformChatMessage = async (
    data: connect.ChatTranscriptItem,
    controller?: connect.ChatSession,
    language?: string | undefined,
    isLoadOldMessages: boolean | undefined = false,
): Promise<IChatMessage> => {
    let content
    try {
        const parsed = JSON.parse((data.Content || '{"key":"value"}').replace('\n', ''))

        if (language && parsed[language]) content = parsed[language]
        else if (parsed.en) content = parsed.en
        // "*"" is added here to catch the case where a SILENT_STATE_UPDATE message is sent from connect but the language does not need to be changed and the app won't render this message.
        else if (parsed['*']) content = parsed['*']
        else if (parsed.type) content = parsed
        else content = data.Content
    } catch (error) {
        content = data.Content
    }

    if (data.Attachments) {
        if (controller) {
            try {
                const file = await controller.downloadAttachment({
                    attachmentId: data.Attachments[0]?.AttachmentId,
                })
                const url = window.URL.createObjectURL(file)
                content = {
                    type: 'FILE_LINK',
                    src: url,
                    label: data.Attachments[0].AttachmentName,
                    isImage: file.type.split('/')[0] === 'image',
                    fileType: file.type,
                    onDownload: () => {
                        const downloadLink = document.createElement('a')
                        downloadLink.setAttribute(
                            'download',
                            data.Attachments?.[0]?.AttachmentName ?? 'Download',
                        )
                        downloadLink.setAttribute('href', url)
                        document.body.appendChild(downloadLink)
                        downloadLink.click()
                        downloadLink.remove()
                    },
                }
            } catch (e) {
                console.error(`Failed to handle Connect attachment for message ID: ${data.Id}`, e)
            }
        } else {
            content = 'ATTACHMENT'
        }
    }

    //@ts-ignore
    const readTimestamp = data.MessageMetadata?.Receipts[0]?.ReadTimestamp
    //@ts-ignore
    const deliveredTimestamp = data.MessageMetadata?.Receipts[0]?.DeliveredTimestamp

    return {
        ...data,
        content,
        readTimestamp,
        deliveredTimestamp,
        isOldMessage: isLoadOldMessages
    }
}

export const getChatTranscript = async (
    contact: connect.Contact,
    existingMessages: IChatMessage[] = [],
    nextToken: string | undefined = undefined,
    isLoadOldMessages: boolean | undefined = false,
) => {
    const connection = contact.getAgentConnection() as connect.ChatConnection
    try {
        const controller = await connection.getMediaController()
        const initialContactId = contact.getInitialContactId()
        const language = contact?.getAttributes()['sa-language']?.value
        const maxGetTranscriptCalls: number = 3
        const maxGetTranscriptCallsToFindPopulatedTranscript = 5
        let transcript: connect.ChatTranscriptItem[] = []
        let getTranscriptCalls: number = 0

        do {
            const { data } = (await controller.getTranscript({
                maxResults: 10,
                nextToken,
            })) as { data: { Transcript: connect.ChatTranscriptItem[]; NextToken?: string } }

            getTranscriptCalls += 1

            if (data?.Transcript?.length) {
                //SMAR-9590 Caching behaviour sometimes causes getTranscript to fetch transcripts it has already loaded, so we filter out duplicates.
                const transcriptWithDuplicatesRemoved = data.Transcript.filter(
                    (currentTranscript) =>
                        ![...transcript, ...existingMessages].some(
                            (existingTranscriptItem) =>
                                existingTranscriptItem.Id === currentTranscript.Id,
                        ),
                )
                transcript = transcriptWithDuplicatesRemoved.concat(transcript)
                nextToken = data.NextToken
            } else {
                nextToken = undefined
            }
        } while (
            nextToken &&
            ((transcript.length < 1 &&
                getTranscriptCalls < maxGetTranscriptCallsToFindPopulatedTranscript) ||
                getTranscriptCalls < maxGetTranscriptCalls)
        )

        let translationActive: string | undefined
        const transformedMessages = await Promise.all(
            transcript.map(async (transcriptItem) => {
                if (transcriptItem.Content?.includes('"TRANSLATION"')) {
                    translationActive = JSON.parse(transcriptItem.Content)?.['*'].active
                    return transformChatMessage(transcriptItem, controller, language, isLoadOldMessages)
                }

                if (
                    translationActive &&
                    transcriptItem.ParticipantRole === 'CUSTOMER' &&
                    transcriptItem.ContentType === 'text/plain'
                ) {
                    try {
                        const res = await window.bchat.putChatMessage({
                            contactId: initialContactId ?? connection.contactId,
                            message: transcriptItem.Content!,
                        })

                        const translatedItem: connect.ChatTranscriptItem = {
                            ...transcriptItem,
                            Content: res.translatedMessage || transcriptItem.Content,
                        }
                        return transformChatMessage(translatedItem, controller, language, isLoadOldMessages)
                    } catch (err) {
                        console.log('error translating transcript message: ', err)
                        return transformChatMessage(transcriptItem, controller, language, isLoadOldMessages)
                    }
                }

                return transformChatMessage(transcriptItem, controller, language, isLoadOldMessages)
            }),
        )

        let currentReceipt: Pick<IChatMessage, 'deliveredTimestamp' | 'readTimestamp'> = {
            deliveredTimestamp: undefined,
            readTimestamp: undefined,
        }

        const finalTranscript: IChatMessage[] = []

        for (let message of [...transformedMessages].reverse()) {
            const { deliveredTimestamp, readTimestamp } = message

            if (deliveredTimestamp || readTimestamp) {
                currentReceipt = { deliveredTimestamp, readTimestamp }
            }

            if (typeof message.content !== 'string' && message.content?.type === 'BATCH') {
                for (const batchMessage of message.content.messages) {
                    finalTranscript.unshift({
                        ...message,
                        ...currentReceipt,
                        content: batchMessage,
                    })
                }

                continue
            }

            message = {
                ...message,
                ...currentReceipt,
            }

            finalTranscript.unshift(message)
        }

        return {
            messages: finalTranscript,
            nextToken
        }
    } catch (e) {
        console.log('error getting transcript for chat ', e, connection.getContactId())
        return {
            messages: [],
            nextToken: undefined
        }
    }
}

const handleChatInactive = (
    store: MiddlewareAPI<any, RootState>,
    contactID: string,
    connection: connect.ChatConnection,
) => {
    let customerInactiveTimeouts: NodeJS.Timeout[] = []
    let agentInactiveTimeouts: NodeJS.Timeout[] = []

    const chatFeature = store
        .getState()
        .app.features.find((f) => f.ID === AppFeatures.CHAT) as IFeature<AppFeatures.CHAT>

    const resetCustomerInactiveTimeout = () => {
        const relatedChat = store.getState().chat.connections.find((c) => c.id === contactID)

        if (relatedChat && !relatedChat.socialMediaPlatform) {
            customerInactiveTimeouts.forEach((timeout) => clearTimeout(timeout))
            const customerActive = store
                .getState()
                .chat?.connections?.find((c) => c.id === contactID)?.customerActive

            if (
                chatFeature.config?.onCustomerInactive ||
                chatFeature.config?.onCustomerInitiallyInactive
            ) {
                let { onCustomerInactive, onCustomerInitiallyInactive } = chatFeature.config
                const onCustomerInactiveContactAttributeString =
                    store.getState().contact?.attributes['onCustomerInactive']
                const onCustomerInitiallyInactiveContactAttributeString =
                    store.getState().contact?.attributes['onCustomerInitiallyInactive']

                onCustomerInactive = isValidJson(onCustomerInactiveContactAttributeString)
                    ? JSON.parse(onCustomerInactiveContactAttributeString)
                    : onCustomerInactive

                onCustomerInitiallyInactive = isValidJson(
                    onCustomerInitiallyInactiveContactAttributeString,
                )
                    ? JSON.parse(onCustomerInitiallyInactiveContactAttributeString)
                    : onCustomerInitiallyInactive
                const inactivityConfig = customerActive
                    ? onCustomerInactive
                    : onCustomerInitiallyInactive || onCustomerInactive

                customerInactiveTimeouts = inactivityConfig!.map((inactiveEvent) => {
                    const { disconnect, seconds, message } = inactiveEvent
                    let transformedMessage = message

                    return setTimeout(async () => {
                        try {
                            if (!connection.isConnected()) return resetCustomerInactiveTimeout()
                        } catch (error) {
                            console.error('error getting connection ', error)
                            customerInactiveTimeouts.forEach((timeout) => clearTimeout(timeout))
                            return
                        }

                        if (relatedChat?.channel !== MessageSources.EMAIL.toString()) {
                            if (typeof message === 'object') {
                                const language =
                                    store.getState().contact?.attributes['sa-language'] ?? 'en'
                                transformedMessage = message[language]
                            }
                            if (transformedMessage)
                                store.dispatch(
                                    chatActions.sendChatMessage({
                                        id: contactID,
                                        msg: transformedMessage as string,
                                        clearEditor: false,
                                    }),
                                )
                            if (disconnect) {
                                await window.bchat?.updateContact({
                                    contactId: contactID,
                                    attributes: {
                                        'sa-system-ended': 'true',
                                    },
                                })
                                store.dispatch(chatActions.endChat(contactID))
                            }
                        }
                    }, seconds * 1000)
                })
            }
        }
    }

    const resetAgentInactiveTimeout = () => {
        agentInactiveTimeouts.forEach((timeout) => clearTimeout(timeout))

        if (chatFeature?.config?.onAgentInactive) {
            const onAgentInactive = chatFeature.config?.onAgentInactive

            agentInactiveTimeouts = onAgentInactive.map((inactiveEvent: any) => {
                const { disconnect, seconds, message } = inactiveEvent

                return setTimeout(() => {
                    if (!connection.isConnected()) return resetAgentInactiveTimeout()

                    if (message) {
                        const language = store.getState().contact?.attributes['sa-language'] ?? 'en'
                        const transformedMessage = message[language]
                        store.dispatch(
                            chatActions.sendChatMessage({
                                id: contactID,
                                msg: transformedMessage,
                                clearEditor: true,
                            }),
                        )
                    }
                    if (disconnect) store.dispatch(chatActions.endChat(contactID))
                }, seconds * 1000)
            })
        }
    }

    resetCustomerInactiveTimeout()
    resetAgentInactiveTimeout()

    return [resetCustomerInactiveTimeout, resetAgentInactiveTimeout]
}

export const monitorChatConnection = async (
    contactID: string,
    store: MiddlewareAPI<any, RootState>,
    connection: connect.ChatConnection,
) => {
    const controller = (await connection.getMediaController()) as connect.ChatSession

    connect.ChatSession.setGlobalConfig({
        // @ts-ignore
        features: {
            messageReceipts: {
                shouldSendMessageReceipts: true,
                throttleTime: 5000,
            },
        },
    })

    let typingTimeout: NodeJS.Timeout

    // sleep to avoid a race condition where contact attributes cannot override inactivity config in time
    await new Promise((resolve) => setTimeout(resolve, 500))

    const [resetCustomerInactiveTimeout, resetAgentInactiveTimeout] = handleChatInactive(
        store,
        contactID,
        connection,
    )

    controller.onMessage(async ({ data, chatDetails }) => {
        const contactLanguage = store.getState().contact?.attributes['sa-language']
        const customerActive = store
            .getState()
            .chat.connections.find((c) => c.id === contactID)?.customerActive
        !customerActive && store.dispatch(chatActions.setCustomerInitiallyActive(contactID))

        store.dispatch(
            chatActions.receiveChatMessage(
                contactID,
                await transformChatMessage(data, controller, contactLanguage),
            ),
        )

        if (data.ParticipantRole === 'AGENT') {
            resetAgentInactiveTimeout()
        }

        if (data.ParticipantRole === 'CUSTOMER') {
            resetCustomerInactiveTimeout()
        }
    })

    controller.onTyping(({ data }) => {
        if (data.ParticipantRole === 'AGENT') return

        clearTimeout(typingTimeout)

        store.dispatch(chatActions.chatContactIsTyping({ id: contactID, typing: true }))

        typingTimeout = setTimeout(() => {
            store.dispatch(chatActions.chatContactIsTyping({ id: contactID, typing: false }))
        }, 12_000)
    })

    // @ts-ignore
    controller.onDeliveredReceipt(async (event) => {
        console.log('Received delivered receipt', event)

        const chatFeatureConfig = store
            .getState()
            .app.features.find((f) => f.ID === AppFeatures.CHAT)
            ?.config as FeatureConfig<AppFeatures.CHAT>
        if (!chatFeatureConfig?.messageReceiptsActive) return

        const messageId = event.data.MessageMetadata.MessageId
        const deliveredTimestamp: string | undefined =
            event.data?.MessageMetadata?.Receipts[0].DeliveredTimestamp
        if (!deliveredTimestamp) return
        store.dispatch(
            chatActions.setDeliveredTimestamp({
                chatId: contactID,
                messageId: messageId,
                timestamp: deliveredTimestamp,
            }),
        )
    })

    // @ts-ignore
    controller.onReadReceipt(async (event) => {
        console.log('Received read receipt', event)
        const messageId = event.data.MessageMetadata.MessageId
        const readTimestamp: string | undefined =
            event.data?.MessageMetadata?.Receipts[0].ReadTimestamp
        if (!readTimestamp) return
        store.dispatch(
            chatActions.setReadTimestamp({
                chatId: contactID,
                messageId: messageId,
                timestamp: readTimestamp,
            }),
        )
    })
}

export const monitorConnection = (
    store: MiddlewareAPI<any, RootState>,
    connection: connect.VoiceConnection,
) => {
    let lastState: string | null = null
    if (!connection) return
    const connectionId = connection.getConnectionId()
    const interval = setInterval(() => {
        try {
            const call = store.getState().call
            if (!call || !call.connections.find((c) => c.id === connectionId)) {
                clearInterval(interval)
            }
            //For some reason on the third party its getStatus not get state
            const type = connection.getStatus().type
            if (type === lastState) return

            if (lastState === connect.ConnectionStateType.HOLD) {
                store.dispatch(callActions.setHold(connectionId, false))
            }

            switch (type) {
                case connect.ConnectionStateType.CONNECTING:
                    store.dispatch(callActions.connectionConnecting(connectionId))
                    break
                case connect.ConnectionStateType.CONNECTED:
                    //We use contact.onConnected for initial connection
                    if (connection.isInitialConnection()) {
                        lastState = type
                        return
                    }
                    store.dispatch(callActions.connectionStarted(connectionId))
                    break
                case connect.ConnectionStateType.HOLD:
                    store.dispatch(callActions.setHold(connectionId, true))
                    break
                case connect.ConnectionStateType.DISCONNECTED:
                    clearInterval(interval)
                    store.dispatch(callActions.endConnection(connectionId))
                    break
                default:
                    break
            }
            lastState = type
        } catch (ex) {
            console.log('error from monitoring connection', ex)
            clearInterval(interval)
        }
    }, 1000)
}

export function getContactState(
    state: RootState,
    contact: connect.Contact,
    agent?: connect.Agent,
): ContactState {
    //sa-DPA attribute is present IFF we are doing some DPA, if it's absent we won't show DPA at all.
    const attributes = contact.getAttributes()
    const connections = contact.getConnections().filter((c) => (c.getType() as any) !== 'agent')
    let phoneNumber = connections[0]?.getEndpoint().phoneNumber
    const { user, app, auth, contacts } = state

    const subType = contact.getContactSubtype()

    //Remove any connect +'s from phonenumber
    const matches = /(\d+)/.exec(phoneNumber)
    if (matches) {
        phoneNumber = matches[0]
    }

    const acwAttributes: ICallContactAttributes =
        contacts.find((c) => c.ID === contact?.contactId)?.acwAttributes || {}

    const showHidePlugins = Object.values(attributes).reduce(
        (showHide: Record<string, 'show' | 'hide'>, curr) => {
            if (curr.name.startsWith('sa-show-plugins')) {
                curr.value.split(',').map((show) => (showHide[show] = 'show'))
            }
            if (curr.name.startsWith('sa-hide-plugins')) {
                curr.value.split(',').map((hide) => (showHide[hide] = 'hide'))
            }
            return showHide
        },
        {},
    )

    const { name, queueId } = contact.getQueue() ?? {}

    const originalContactID =
        contact.getOriginalContactId() !== contact.contactId
            ? contact.getOriginalContactId()
            : undefined

    const contactObject: ContactState = {
        queueName: name,
        queueID: queueId,
        inQueueDuration: differenceInSeconds(new Date(), contact.getQueueTimestamp()?.getTime()),
        ID: contact.contactId,
        channel: contact.getType().toUpperCase() as Channel,
        initiationTimestamp: Date.now(),
        initiationMethod: contact.isInbound() ? 'INBOUND' : 'OUTBOUND',
        customerEndpointAddress: phoneNumber,
        answered: true,
        systemEndpointAddress: attributes['sa-system-endpoint-address']?.value ?? '',
        //Map to attributes without the embedded { value: "VALUE" }
        attributes: Object.keys(attributes).reduce((newattrs, key) => {
            newattrs[key] = attributes[key].value
            return newattrs
        }, {} as ICallContactAttributes),
        customer: {
            phoneNumber,
        },
        DPA: getDPAState(attributes),
        customerInputs: getCustomerInputs(attributes),
        tags: getTagsFromContactAttributes(attributes),
        agentID: auth.agentID,
        agentName: user?.name ?? agent?.getName(),
        originalContactID,
        routingProfileID: user?.routingProfile?.ID ?? agent?.getRoutingProfile().routingProfileId,
        companyID: app.ID,
        instanceID: app.instance!.ID,
        created: 0,
        updated: 0,
        plugins: showHidePlugins,
        acwAttributes,
        subType: subType,
    }

    return contactObject
}

export const makeChatFromContact = (contact: connect.Contact): IChat => {
    const initialConnection = contact.getInitialConnection() as connect.ChatConnection
    const contactAttributes = contact.getAttributes()

    return {
        id: contact.contactId,
        initialContactId: contact.getInitialContactId(),
        isTyping: false,
        queueName: contact.getQueue().name,
        status: contact.getStatus().type,
        started: contact.getQueueTimestamp()?.getTime() ?? new Date().getTime(),
        messages: [] as Array<IChatMessage>,
        customerName:
            contactAttributes['sa-customer-name']?.value ??
            initialConnection.getMediaInfo().originalInfo.customerName ??
            'Customer',
        customerEmail: contactAttributes['sa-customer-email']?.value,
        acw: contact.getStatus().type === connect.ContactStateType.ENDED && !contact.isConnected(),
        acwStartedAt: null,
        acwExtendedAt: null,
        unread: 0,
        channel: contactAttributes['sa-chat-channel']?.value as MessageSources,
        DPA: getDPAState(contactAttributes),
        reply: {
            text: '',
            isProbablyJson: false,
        },
        attachments: {
            files: [],
        },
        socialMediaPlatform: contactAttributes['sa-social-media-platform']
            ?.value as SocialMediaPlatform,
        sourceMessage:
            contactAttributes['sa-source-message-content']?.value ||
            contactAttributes['sa-source-message-link']?.value
                ? {
                      content: contactAttributes['sa-source-message-content']?.value,
                      link: contactAttributes['sa-source-message-link']?.value,
                      timestamp:
                          Number(contactAttributes['sa-source-message-timestamp']?.value) || 0,
                  }
                : undefined,
        currentEditorContent: EditorState.createEmpty(),
        currentSocialEditorContent: ''
    }
}

export const makeTaskFromContact = (contact: connect.Contact): ITask => {
    return {
        id: contact.contactId,
        status: contact.getStatus().type,
        acw: contact.getStatus().type === connect.ContactStateType.ENDED && !contact.isConnected(),
        channel: contact.getAttributes()['sa-channel']?.value as TaskChannel,
        started: new Date(Date.now() - contact.getStatusDuration()).getTime(),
        queueName: contact.getQueue().name,
        name: contact.getName(),
        description: contact.getDescription(),
        threadID: contact.getAttributes()['sa-threadID']?.value,
        currentEditorContent: EditorState.createEmpty(),
        attributes: contact.getAttributes(),
        attachments: [],
    }
}

export const shouldIncludeCCInReplyWhenCreatingTask = (
    store: MiddlewareAPI<any, RootState>,
): boolean => {
    const emailCCReplyFeatureConfig = store
        .getState()
        .app.features.find((f) => f.ID === AppFeatures.EMAIL_CC_REPLY)
        ?.config as FeatureConfig<AppFeatures.EMAIL_CC_REPLY>

    return emailCCReplyFeatureConfig?.ccReplyChecked || false
}

export const setUserToPreviousState = (store: MiddlewareAPI<any, RootState>) => {
    const previousStatus = store.getState().user?.afterCallStatus
    if (previousStatus) {
        store.dispatch(setState(previousStatus))
    } else {
        store.dispatch(setAvailable())
    }
}

export const hasAgentChangedState = (
    agent: connect.Agent,
    user: UserState,
    to?: string,
    from?: string,
) => {
    if (user.status.name === from && agent.getState().name === to) return true
    if (!from && agent.getState().name === to && user.status.name !== to) return true
    if (!to && user.status.name === from && agent.getState().name !== from) return true
    if (!to && !from && agent.getState().name !== user.status.name) return true
    return false
}

const isValidJson = (str: string): boolean => {
    if (!str?.trim()) {
        return false
    }
    try {
        JSON.parse(str)
    } catch {
        return false
    }
    return true
}

export function getIsEnhancedMonitoringEnabled(store: MiddlewareAPI<any, RootState>): boolean {
    const { app } = store.getState()

    return app.instance?.settings?.isEnhancedMonitoringEnabled ?? false
}

export const hasCustomerDisconnected = (
    callV2Connections: CallConnection[],
    contact: connect.Contact,
): boolean => {
    const previousCustomerConnection = callV2Connections.find(({ type }) => type === 'CUSTOMER')

    if (previousCustomerConnection?.status.type === connect.ConnectionStateType.DISCONNECTED)
        return false

    const newCustomerConnection = contact.getActiveInitialConnection()

    if (newCustomerConnection) return false

    return true
}

const isCallV2NonYouBargeConnection = ({ type, isBarge, isConnected }: CallConnection): boolean =>
    type !== 'YOU' && !!isBarge && !!isConnected

export const hasCallV2NonYouBargerConnection = (connections: CallConnection[]): boolean =>
    connections.some(isCallV2NonYouBargeConnection)

export const hasContactNonYouBargerConnection = (connections: connect.VoiceConnection[]): boolean =>
    connections.some(
        (connection) =>
            connection.isBarge() &&
            connection.isActive() &&
            connection.getType() !== connect.ConnectionType.AGENT,
    )

export const areYouMonitoringOrBarging = (connections: connect.VoiceConnection[]): boolean =>
    connections.some(
        (connection) =>
            connection.isActive() &&
            connection.getType() === connect.ConnectionType.AGENT &&
            connection.getMonitorStatus(),
    )

export const isBargerJoining = (
    callV2Connections: CallConnection[],
    contactConnections: connect.VoiceConnection[],
): boolean => {
    return (
        !hasCallV2NonYouBargerConnection(callV2Connections) &&
        hasContactNonYouBargerConnection(contactConnections)
    )
}

export const isBargerLeaving = (
    callV2Connections: CallConnection[],
    contactConnections: connect.VoiceConnection[],
): boolean => {
    return (
        hasCallV2NonYouBargerConnection(callV2Connections) &&
        !hasContactNonYouBargerConnection(contactConnections)
    )
}

export const hasReachedConnectionLimit = (
    callV2Connections: CallConnection[],
    contactConnections: connect.VoiceConnection[],
): boolean => {
    const activeNonMonitoringCallV2Connections = callV2Connections.filter(
        ({ status }) =>
            status.type !== connect.ConnectionStateType.DISCONNECTED &&
            status.type !== connect.ConnectionStateType.SILENT_MONITOR,
    )

    if (activeNonMonitoringCallV2Connections.length >= MAX_VOICE_CONNECTIONS) return false

    const activeNonMonitoringContactConnections = contactConnections.filter(
        (connection) => connection.isActive() && !connection.isSilentMonitor(),
    )

    return activeNonMonitoringContactConnections.length >= MAX_VOICE_CONNECTIONS
}
