import { jsxs, jsx } from "react/jsx-runtime";
import classNames from "classnames";
import { useState, useMemo } from "react";
import { M as MessageUserType } from "./chat-message.types-V2YsTtfG.mjs";
import PlainTextChatMessage from "./PlainTextChatMessage.mjs";
const StarRating = (props) => {
  const [starsSelected, setSelectedStars] = useState(0);
  const [currentHover, setCurrentHover] = useState();
  const [cancelled, setCancelled] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const numberOfStars = useMemo(() => {
    return props.numberOfStars ?? 5;
  }, [props.numberOfStars]);
  const onNumberKeyDown = (e) => {
    try {
      const parsed = parseInt(e.key);
      if (parsed <= numberOfStars) {
        handleClick(parsed - 1);
      }
    } catch {
    }
  };
  const handleClick = (idx) => {
    if (cancelled || submitted) {
      return;
    }
    let starsClicked = idx + 1;
    if (starsSelected === starsClicked) {
      starsClicked = 0;
    }
    setSelectedStars(starsClicked);
    if (props.updateContact) {
      props.updateContact({
        "sa-star-rating": starsClicked.toString()
      });
    }
  };
  const handleKeyDown = (e, idx) => {
    if (e.key === " " || e.key === "Spacebar") {
      handleClick(idx);
    }
  };
  return /* @__PURE__ */ jsxs(
    "div",
    {
      id: props.ariaId,
      className: classNames({
        "star-rating-messages-container": true
      }),
      children: [
        (props.messages || []).map((x, index) => /* @__PURE__ */ jsx(
          PlainTextChatMessage,
          {
            ...props,
            clientType: MessageUserType.CUSTOMER,
            text: x.text,
            type: x.type,
            senderType: MessageUserType.SYSTEM,
            time: (/* @__PURE__ */ new Date()).toISOString(),
            translations: {
              newMessageNotificationText: props.translations.newMessageNotificationText,
              quickRepliesAriaLabel: props.translations.quickRepliesAriaLabel,
              statusLabels: props.translations.statusLabels,
              hideText: props.translations.hideText,
              messageText: props.translations.messageText,
              seeText: props.translations.seeText,
              receivedAtText: props.translations.receivedAtText,
              sentAtText: props.translations.sentAtText,
              getSelectXOptionsFromY: props.translations.getSelectXOptionsFromY
            }
          },
          `message-${index}`
        )),
        /* @__PURE__ */ jsxs(
          "div",
          {
            className: classNames({
              "star-rating-outer-container": true
            }),
            children: [
              /* @__PURE__ */ jsx(
                "div",
                {
                  className: classNames({
                    "star-rating-container": true
                  }),
                  onMouseLeave: () => setCurrentHover(void 0),
                  onKeyDown: onNumberKeyDown,
                  role: "radiogroup",
                  "aria-label": props.translations.starRatingAriaLabel,
                  tabIndex: 0,
                  children: Array(numberOfStars).fill("").map((_, idx) => /* @__PURE__ */ jsx(
                    "div",
                    {
                      className: classNames({
                        star: !cancelled && !submitted,
                        "star-inactive": cancelled || submitted,
                        "star-highlight": currentHover !== void 0 && currentHover >= idx
                      }),
                      tabIndex: 0,
                      onClick: () => handleClick(idx),
                      onKeyDown: (e) => handleKeyDown(e, idx),
                      onMouseOver: () => {
                        !cancelled && !submitted && setCurrentHover(idx);
                      },
                      "aria-label": `${props.translations.starAriaLabel} ${idx + 1}`,
                      "aria-checked": idx + 1 === starsSelected ? "true" : "false",
                      role: "radio",
                      children: idx < starsSelected || currentHover != void 0 && currentHover >= idx ? /* @__PURE__ */ jsx(
                        "svg",
                        {
                          width: "37",
                          height: "35",
                          viewBox: "0 0 37 35",
                          fill: "none",
                          xmlns: "http://www.w3.org/2000/svg",
                          children: /* @__PURE__ */ jsx(
                            "path",
                            {
                              d: "M7.35714 34.2198L10.3242 21.5604L0.5 13.0549L13.4231 11.9341L18.5 0L23.5769 11.9341L36.5 13.0549L26.6758 21.5604L29.6429 34.2198L18.5 27.4945L7.35714 34.2198Z",
                              fill: getComputedStyle(document.body).getPropertyValue(
                                "--sa-star-rating-colour"
                              ) || getComputedStyle(document.body).getPropertyValue(
                                "--sa-global-dark-colour"
                              ) || "blue"
                            }
                          )
                        }
                      ) : /* @__PURE__ */ jsx(
                        "svg",
                        {
                          className: classNames({ "star-svg": true }),
                          width: "37",
                          height: "35",
                          viewBox: "0 0 37 35",
                          fill: "none",
                          xmlns: "http://www.w3.org/2000/svg",
                          children: /* @__PURE__ */ jsx(
                            "path",
                            {
                              d: "M9.59962 31.9393L18.5014 26.5323L27.4032 32.0052L25.0954 21.7846L32.9421 14.9929L22.5897 14.0697L18.5014 4.50854L14.4132 14.0038L4.06072 14.927L11.9075 21.7846L9.59962 31.9393ZM7.35768 34.9725L10.325 22.3122L0.5 13.806L13.4241 12.685L18.5014 0.75L23.5787 12.685L36.5028 13.806L26.6779 22.3122L29.6452 34.9725L18.5014 28.2467L7.35768 34.9725Z",
                              fill: getComputedStyle(document.body).getPropertyValue(
                                "--sa-star-rating-colour"
                              ) || getComputedStyle(document.body).getPropertyValue(
                                "--sa-global-dark-colour"
                              ) || "blue"
                            }
                          )
                        }
                      )
                    },
                    idx
                  ))
                }
              ),
              /* @__PURE__ */ jsx("div", { "aria-live": "polite", children: function() {
                if (cancelled || submitted)
                  return null;
                if (!cancelled && starsSelected <= 0) {
                  return /* @__PURE__ */ jsx(
                    "button",
                    {
                      className: classNames({ "star-button": true }),
                      onClick: () => {
                        props.sendMessage(props.translations.noRatingAriaLabel);
                        if (!cancelled) {
                          setCancelled(true);
                        }
                      },
                      children: props.translations.cancel
                    }
                  );
                }
                return /* @__PURE__ */ jsx(
                  "button",
                  {
                    className: classNames({ "star-button": true }),
                    onClick: () => {
                      setSubmitted(true);
                      props.sendMessage(`${starsSelected ? `${starsSelected} ${props.translations.starsAriaLabel}` : props.translations.noRatingAriaLabel} `);
                    },
                    children: props.translations.submit
                  }
                );
              }() })
            ]
          }
        )
      ]
    }
  );
};
export {
  StarRating as default
};
